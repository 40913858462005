import React, { useState } from 'react';
import TemplateHome from '../../components/templates/templateHome';
import classnames from 'classnames';
import {
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Label,
} from 'reactstrap';

// routning
import { useHistory } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import {
  createSurvey,
  createSurveyPage,
  getSurveyCategories,
} from '../../core/services/surveyService';
import { getValue } from '../../core/lodash';
import Cookies from 'js-cookie';
import {
  createSurveyTemplate,
  deleteSurveyTemplate,
  getSurveyTemplate,
} from '../../core/services/surveyTemplateService';
import LeftNav from '../../components/menu/left-nav';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import LoaderCommon from '../../components/common/loader-common';
import Select from 'react-select';


interface ITemplateMainProps { }

const TemplateMain: React.FunctionComponent<ITemplateMainProps> = (props) => {
  // validation
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [, forceUpdate] = React.useState(0);
  const [search, openSearch] = useState(false);
  //   state
  const [categoryOption, setCategoryOption] = React.useState();
  const [surveyCategories, setSurveyCategories] = React.useState([]);
  const [templateList, setTemplateList] = React.useState([]);
  const [request, setRequest] = React.useState({
    name: '',
    category: '',
  });
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  // delete
  const [isOpen, setIsOPen] = useState(false);
  const [removeValue, setRemoveValue] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [surveyType] = useState([
    { value: 'SURVEY', label: 'Standard Survey' },
    { value: 'SURVEY360', label: '360 Survey' },
    { value: 'Assessment', label: 'Assessment' },
  ]);
  const [typeOption, setTypeOption] = useState<any>([]);

  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleDeleteSurvey = async (id, value) => {
    setDeleteId(id);
    setRemoveValue(value);
    handleModal();
  };

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  const history = useHistory();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };

  React.useEffect(() => {
    getSurveyCategoriesInfo();
    getTemplateList();
    // Cookies.remove('templateEdit');
    // Cookies.remove('template')
  }, []);

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  // =============================================================================
  // Search section
  // =============================================================================

  const [searchInput, setSearchInput] = useState('');

  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput('');
    globalSearch('');
  };

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const globalSearch = async (value) => {
    try {
      let response = await getSurveyTemplate(skip, limit, value);
      if (response) {
        setTemplateList(getValue(response, 'data.templates', []));
        setTotalCount(getValue(response, 'data.count', []));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // ===========================================================================
  // get Apis section
  // ===========================================================================

  const getSurveyCategoriesInfo = async () => {
    try {
      let resp = await getSurveyCategories();
      if (resp) {
        setSurveyCategories(
          getValue(resp, 'data', []).map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getTemplateList = async () => {
    try {
      setIsLoading(true);
      let response = await getSurveyTemplate(skip, limit, '');
      if (response) {
        setTemplateList(getValue(response, 'data.templates', []));
        setTotalCount(getValue(response, 'data.count', []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDeleteFun = async () => {
    try {
      let resp = await deleteSurveyTemplate(deleteId);
      if (resp) {
        toast.success('Deleted successfully'
        ,{className:'green-circle'}
        );
        handleModal();
        getTemplateList();
      } else {
        handleModal();
        getTemplateList();
      }
    } catch (error) {
      handleModal();
    }
  };

  // =============================================================================
  // Handle change section
  // =============================================================================

  //   change state
  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  const handleChange = (value) => {
    setCategoryOption(value);
    setRequest({
      ...request,
      category: value.value,
    });
  };

  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getTemplateList();
  };

  // ===========================================================================
  // Submit section
  // ===========================================================================
  const handleSubmitTemplate = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let data = {
        name: request.name,
        category: request.category,
        type: typeOption.value,
      };
      let resp = await createSurveyTemplate(data);
      if (resp) {
        let req = {
          title: 'Untitled',
          description: 'Untitled',
          uniqueOrder: 0,
        };
        Cookies.set('surveyId', getValue(resp, 'data.id', ''));
        let response = await createSurveyPage(
          getValue(resp, 'data.id', ''),
          req
        );
        if (response) {
          Cookies.set('pageId', getValue(response, 'data.id', ''));
          Cookies.set('template', true);
          history.push(
            `/create-new-survey-form/${getValue(resp, 'data.type', '')}/${getValue(resp, 'data.id', '')}/templates`
          );
        }
      }
    }
  };


  const handleSubmitExistingTemplate = async () => {
    history.push('/');
  };

  const createTemplateModal = () => {
    toggleModal()
  }

  const handleChangeCategoryType = (value) => {
    setTypeOption(value);
    let surveyType = '';
    if (value.value === 'Standard Survey') {
      surveyType = 'SURVEY';
    } else if (value.value === '360 Survey') {
      surveyType = 'SURVEY360';
    } else {
      surveyType = 'ASSESSMENT';
    }
  };


  return (
    <div>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs create-survey-tabs-search">
            <Nav pills className="basic-tabs pl-0">
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Templates
                </NavLink1>
              </NavItem>
              {/* <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Drafts
                </NavLink1>
              </NavItem> */}
            </Nav>
            {/* <div className="hover-circle">
              <Button color="nothing" onClick={toggleSearch}>
                <SearchSVG />
              </Button>
              <div
                className={'table-search-overlay ' + (search ? 'show' : '')}
                onClick={toggleSearch}
              ></div>
              <div className={'table-search ' + (search ? 'show' : '')}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search"
                />
                <Button
                  color="nothing"
                  onClick={toggleSearch}
                  className="table-search-close"
                >
                  <CloseSVG />
                </Button>
              </div>
            </div> */}
          </div>
          <div className="page-padding">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {!isLoading ?
                  getValue(templateList, 'length', 0) !== 0 ? (
                    <Row>
                      <Col md={{ size: '10', offset: '1' }}>
                        <TemplateHome
                          handleSubmitTemplate={handleSubmitTemplate}
                          handleChangeForm={handleChangeForm}
                          simpleValidator={simpleValidator}
                          request={request}
                          templateList={templateList}
                          handleChange={handleChange}
                          surveyCategories={surveyCategories}
                          categoryOption={categoryOption}
                          handleSubmitExistingTemplate={
                            handleSubmitExistingTemplate
                          }
                          toggleSearch={toggleSearch}
                          handleDeleteSurvey={handleDeleteSurvey}
                          handleChangeSearch={handleChangeSearch}
                          searchInput={searchInput}
                          search={search}
                          toggleModal={toggleModal}
                          modal={modal}
                        />
                      </Col>
                    </Row>) :
                    (<div className="text-center mt-5">
                      <div className="mt-4 mb-2">
                        <img src="/images/empty.PNG" alt="" />
                      </div>
                      <h4 className="mb-2">No Templates Found</h4>
                      <Button color="blue" onClick={createTemplateModal}>New Template</Button>
                    </div>)
                  :
                  <div className="text-center "><LoaderCommon /></div>
                }
                {totalCount > 10 && (
                  <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    onChange={onProductPageChangeHandler}
                    prevPageText="Prev"
                    nextPageText="Next"
                    hideFirstLastPages={true}
                  />
                )}
              </TabPane>
              <TabPane tabId="2">Lorem ipsum</TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      {/* Create template modal */}


      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalBody>
          <h3 className="text-center mb-1">Submit a template</h3>
          <p className="text-center">
            Any one in your organisation will be able to find and use it.
          </p>
          <div className="form-group">
            <Label>
              Template Name<span className="imp">*</span>
            </Label>
            <input
              type="text"
              name="name"
              placeholder="Enter Survey Name"
              className="form-control"
              value={request.name}
              onChange={handleChangeForm}
            />
            <div className="form-error">
              {simpleValidator.current.message(
                'name',
                request.name,
                'required'
              )}
            </div>
          </div>
          <div className="form-group form-react-select">
            <Label>
              Category Name <span className="imp">*</span>
            </Label>
            <Select
              value={categoryOption ? categoryOption : ''}
              onChange={handleChange}
              options={surveyCategories}
            />
            <div className="form-error">
              {simpleValidator.current.message(
                'name',
                request.category,
                'required'
              )}
            </div>
          </div>
          <div className="form-group form-react-select">
            <Label>
              Template Type <span className="imp">*</span>
            </Label>
            <Select
              // SURVEY360
              onChange={handleChangeCategoryType}
              options={surveyType}
              name="category"
            />
            <div className="form-error">
              {simpleValidator.current.message(
                'Survey Type',
                typeOption.label,
                'required'
              )}
            </div>
          </div>
          {/* <div className="form-group mt-4">
            <Button
              type="submit"
              color="border"
              onClick={handleSubmitTemplate}
              block
            >
              Submit by creating a new survey
            </Button>
          </div> */}
          <div className="btn-group-margin text-md-right">
            <Button color="border" onClick={() => toggleModal()}>
              Cancel
            </Button>
            <Button
              type="submit"
              color="blue"
              // onClick={handleSubmitExistingTemplate}
              onClick={handleSubmitTemplate}
            >
              Submit Template
            </Button>

          </div>
        </ModalBody>
      </Modal>


      <DeleteSurveyModal
        isOpen={isOpen}
        handleDeleteFun={handleDeleteFun}
        removeValue={removeValue}
        handleModal={handleModal}
      />
    </div>
  );
};

export default TemplateMain;
