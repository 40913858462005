import { getValue } from 'core/lodash';
import * as React from 'react';
import { Button, Col, Row, Label, Input } from 'reactstrap';
import SmileySVG from '../svg/smiley-svg';
import AdvancedOptions from './AdvancedOptions';
import Select from 'react-select';
import { commonCharatersLength } from 'components/common/commonJSON';

interface IRatingScaleProps {
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  handleQuestionTypeInputBox: (
    e: any,
    pageIndex: number,
    questionIndex: number
  ) => void;

  handleRatingScaleDropdownString: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    param: any
  ) => void;
  handleQuestionTypeDropdown: (
    e: object,
    pageIndex: number,
    questionIndex: number,
    maxLength: string
  ) => void;
  surveyType: string;
}

const RatingScale: React.FunctionComponent<IRatingScaleProps> = (props) => {
  const [ratingScaleMin] = React.useState(commonCharatersLength.ratingScaleMin);
  const [ratingScaleMax] = React.useState(commonCharatersLength.ratingScaleMax);
  const [ratingScaleType] = React.useState(
    commonCharatersLength.ratingScaleRepresentation
  );
  const [ratingScaleRepresentation] = React.useState(
    commonCharatersLength.ratingScaleRepresentation
  );

  let RatingMin = ratingScaleMin.filter(
    (item) =>
      item.value ===
      parseFloat(
        getValue(
          props.questionInfo[props.pageIndex],
          `questions[${props.questionIndex}].validations.scaleFrom`,
          ''
        )
      )
  );

  let RatingMax = ratingScaleMax.filter(
    (item) =>
      item.value ===
      parseFloat(
        getValue(
          props.questionInfo[props.pageIndex],
          `questions[${props.questionIndex}].validations.scaleTo`,
          ''
        )
      )
  );
  let RatingType = ratingScaleType.filter(
    (item) =>
      item.value ===
      getValue(
        props.questionInfo[props.pageIndex],
        `questions[${props.questionIndex}].validations.type`,
        ''
      )
  );

  return (
    <div className="rating-scale-choice">
      <Row className="left-right-icons">
        <Col md="12">
          <h4 className="d-inline-block mb-3">Answer</h4>
        </Col>
      </Row>
      {/* <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Type</h6>
        </Col>
        <Col md="6">
          <div className="form-group">
            <Select options={ratingScaleType} />
          </div>
        </Col>
      </Row> */}
      <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Representation</h6>
        </Col>
        <Col md="6">
          <div className="form-group">
            <Select
              options={ratingScaleRepresentation}
              value={RatingType}
              onChange={(e) => {
                props.handleRatingScaleDropdownString(
                  e,
                  props.pageIndex,
                  props.questionIndex,
                  'type'
                );
              }}
            />
          </div>
        </Col>
        {getValue(
          props.questionInfo[props.pageIndex],
          `questions[${props.questionIndex}].validations.type`,
          ''
        ) === 'smiley' ? (
          <Col xs="1" className="d-flex align-items-center pl-0">
            <h6>
              <Button color="nothing" className="smiley-yellow">
                <SmileySVG />
              </Button>
            </h6>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Scale</h6>
        </Col>
        <Col md="3">
          <div className="form-group">
            <Select
              options={ratingScaleMin}
              value={RatingMin}
              onChange={(e) =>
                props.handleQuestionTypeDropdown(
                  e,
                  props.pageIndex,
                  props.questionIndex,
                  'scaleFrom'
                )
              }
            />
          </div>
        </Col>
        <Col md="3">
          <div className="form-group">
            <Select
              options={ratingScaleMax}
              value={RatingMax}
              onChange={(e) =>
                props.handleQuestionTypeDropdown(
                  e,
                  props.pageIndex,
                  props.questionIndex,
                  'scaleTo'
                )
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Labels</h6>
        </Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input
              type="text"
              placeholder="Start label"
              name="startLabel"
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.startLabel`,
                ''
              )}
              onChange={(e) =>
                props.handleQuestionTypeInputBox(
                  e,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
            <span className="labels-left-text">
              {getValue(RatingMin[0], 'value', 0)}
            </span>
            {getValue(
              props.questionInfo[props.pageIndex],
              `questions[${props.questionIndex}].validations.type`,
              ''
            ) === 'smiley' ? (
              <span className="smiley-left-text">
                <SmileySVG />
              </span>
            ) : (
              ''
            )}
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">2</span>
            <span className="smiley-left-text">
              <SmileySVG />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">3</span>
            <span className="smiley-left-text">
              <SmileySVG />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input type="text" placeholder="Enter text" />
            <span className="labels-left-text">4</span>
            <span className="smiley-left-text">
              <SmileySVG />
            </span>
          </div>
        </Col>
      </Row>
       */}
      <Row>
        <Col md="2"></Col>
        <Col md="6">
          <div className="form-group labels-left">
            <Input
              type="text"
              placeholder="End label"
              name="endLabel"
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].validations.endLabel`,
                ''
              )}
              onChange={(e) =>
                props.handleQuestionTypeInputBox(
                  e,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
            <span className="labels-left-text">
              {getValue(RatingMax[0], 'value', 0)}
            </span>
            {getValue(
              props.questionInfo[props.pageIndex],
              `questions[${props.questionIndex}].validations.type`,
              ''
            ) === 'smiley' ? (
              <span className="smiley-left-text">
                <SmileySVG />
              </span>
            ) : (
              ''
            )}
          </div>
        </Col>
      </Row>
      <h5>Advance Options:</h5>
      <AdvancedOptions
            surveyType={props.surveyType}
        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
      {/* <div className="mb-2">
        <Label className="checkbox-container d-inline-block">
          Score this response
          <input type="checkbox" />
          <span className="checkmark"></span>
        </Label>
      </div>
      <div className="mb-2">
        <Label className="checkbox-container d-inline-block">
          Add 'Not Applicable' option
          <input type="checkbox" />
          <span className="checkmark"></span>
        </Label>
      </div>
      <div className="form-group pl-2 ml-4">
        <Label>Option label</Label>
        <Input type="text" placeholder="N/A" />
      </div>
      <div className="mb-2">
        <Label className="checkbox-container d-inline-block">
          Add 'Comments' field
          <input type="checkbox" />
          <span className="checkmark"></span>
        </Label>
      </div> */}
    </div>
  );
};

export default RatingScale;
