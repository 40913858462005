import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { config } from '../../config';
import { getValue } from '../../core/lodash';
interface IProfileDropdownProps {
  userInfo: any;
}

const ProfileDropdown: React.FunctionComponent<IProfileDropdownProps> = (
  props
) => {
  const history = useHistory();
  const [enableProfileDropdown, enableDropdown] = useState(false);
  const enableLeftMenu = () => {
    enableDropdown(!enableProfileDropdown);
  };
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    // history.push('/')
    // window.location.reload()
    window.location.href = `${
      config.API_URL
    }auth/sso/stohrm/logout?tenant_id=${localStorage.getItem('tenant_id')}`;
  };
  return (
    <li>
      <Button color="nothing" onClick={() => enableLeftMenu()}>
        {/* if no profile name */}
        <span className="profile-circle">
          {getValue(props, 'userInfo.email', 'J').charAt(0)}
        </span>
        {/* <span className="profile-circle" style={{ backgroundImage: "url('/images/profile.png')" }}>&nbsp;</span> */}
      </Button>
      <div
        onClick={() => enableLeftMenu()}
        className={'profile-overlay ' + (enableProfileDropdown ? 'show' : '')}
      ></div>
      <div
        className={'profile-dropdown ' + (enableProfileDropdown ? 'show' : '')}
      >
        <h4>Account</h4>
        <ul>
          <li>
            <NavLink to="/home">
              <span className="profile-left">
                {/* if no profile name */}
                <span className="profile-circle">
                  {getValue(props, 'userInfo.email', 'J').charAt(0)}
                </span>
                {/* <span className="profile-circle" style={{ backgroundImage: "url('/images/profile.png')" }}>&nbsp;</span> */}
              </span>
              <div className="profile-text">
                {/* <h5>{getValue(props,'userInfo.email','')}</h5> */}
                <p className="email-txt">
                  {getValue(props, 'userInfo.email', '')}
                </p>
              </div>
            </NavLink>
          </li>
        </ul>
        <div className="logout">
          {/* <NavLink to="/home"> */}
          <Button onClick={handleLogout} color="blue">
            Sign out
          </Button>
          {/* </NavLink> */}
        </div>
      </div>
    </li>
  );
};

export default ProfileDropdown;
