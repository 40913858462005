import React, { useEffect, useState } from 'react';
import SettingsComponent from './SettingsComponent';
import { getValue } from '../../core/lodash';
import FormHeader from './common/FormHeader';
import {
  settingsIntroduction,
  settingsTermsCondition,
  uploadFile,
  uploadFileHeader,
  SurveyPreferencePublish,
  getSurveyQuestions,
  getSurvey,
  getAssessmentTotalScore,
  updateAssessmentPassScore,
} from '../../core/services/surveyService';
import BackSVG from '../../components/svg/back-svg';
import {
  createEmailTemplate,
  getAssesmentEmailTemplate,
  getAssessmentLearningStatus,
  getEmailTemplate,
  getSurvey360EmailTemplate,
  getSurveyEmailTemplate,
  updateEmailTemplate,
} from '../../core/services/surveyTemplateService';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import Cookies from 'js-cookie';
import { NavLink, useHistory } from "react-router-dom";
import { ToastHandler } from '../../common/toast';

interface ISurveySettingsComponentProps { }

const SurveySettingsComponent: React.FunctionComponent<ISurveySettingsComponentProps> = (
  props
) => {
  const [emailStatus, setEmailStatus] = useState(0);
  const [fileUploadingStatus, setFileUploadingStatus] = useState(false);
  const [surveyDetails, updateSurvey] = useState({});
  const [visibility, setVisibility] = useState({
    publicEnabled: false,
    privateEnabled: false,
  });
  const surveyId = getValue(props, 'match.params.id', '');
  const surveyQuestionType = getValue(props, 'match.params.type', '');
  const surveyTemplate = getValue(props, 'match.params.templates', '');


  const [emailTemplateText, setEmailTemplateText] = useState('');
  const [emailTemplateSubject, setEmailTemplateSubject] = useState('');
  const [emailTemplateId, setEmailTemplateId] = useState('');

  const [assessmentScore,setAssessmentScore] = useState({
    passingScore: '',
    totalScore:0
  })

  const [assessmentLoading,setAssessmentLoading] = useState(false)


  useEffect(() => {
    getSurveyQuestion();
    getSurveyList();
    if(surveyQuestionType === 'ASSESSMENT'){
    getAssementScore();
    }
  }, []);


  const getAssementScore = async() => {
    try {
      let resp = await getAssessmentTotalScore(surveyId);
      if(resp){
        console.log(resp);
        setAssessmentScore({
          ...assessmentScore,
          passingScore:getValue(resp,'data.passingScore',0),
          totalScore:getValue(resp,'data.totalScore',0)
        })
      }
    } catch (error) {
      
    }
  }

  const handleAssessmentScore = (e) => {
    const {name,value} = e.target
    setAssessmentScore({
      ...assessmentScore,
      [name]:value
    })
  }

  const handleSubmitAssessmentScore = async() => {
try {
  setAssessmentLoading(true);
  let payload = {
    passingScore:parseFloat(assessmentScore.passingScore)
  }
  let resp = await updateAssessmentPassScore(surveyId,payload);
  if(resp){
    setAssessmentLoading(false);
    toast.success(getValue(resp,'message','Submitted successfully')
    ,{className:'green-circle'}
    );  
  }else{
    setAssessmentLoading(false);
  }
} catch (error) {
  setAssessmentLoading(false);

}
  }
console.log(assessmentScore)
  useEffect(() => {
    if (getValue(surveyDetails, 'type', '')) {
      getEmailTemplates();
    }
  }, [getValue(surveyDetails, 'type', '')]);

  const handleChangeVisibility = (status, string) => {
    setVisibility({
      ...visibility,
      [string]: status,
    });
  };
  const history = useHistory();

  const getSurveyList = async () => {
    try {
      if (surveyId) {
        let resp = await getSurvey(surveyId);
        if (resp) {
          updateSurvey(getValue(resp, 'data', {}));
          // if(getValue(surveyDetails,`pages[0].questions.length`,0) < 1){
          //   setTimeout(() => {
          //     window.location.replace(`${window.location.origin}/create-new-survey-form/${surveyId}`)
          //   }, 1000);
          //   toast.error("Please save atleast one questoin to continue")

          // }
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };
const[visibleLoading,setVisibleLoading] = useState(false)
  const handleSubmitVisibility = async () => {
    try {
      setVisibleLoading(true)
    let resp = await SurveyPreferencePublish(surveyId, visibility);
    if (resp) {
      setVisibleLoading(false)
      toast.success('Submitted successfully'
      ,{className:'green-circle'}
      );
    }else{
      setVisibleLoading(false)
    }
  } catch (error) {
    setVisibleLoading(false)
  }
  };

  const getSurveyQuestion = async () => {
    try {
      let resp = await getSurveyQuestions(surveyId);
      if (resp) {
        setIntroductionPage({
          ...introductionPage,
          introductionPageDescription: getValue(
            resp,
            'data.settings.introductionPageDescription',
            ''
          ),
          introductionPageEnabled: getValue(
            resp,
            'data.settings.introductionPageEnabled',
            ''
          ),
        });

        setVisibility({
          ...visibility,
          privateEnabled: getValue(
            resp,
            'data.settings.privateEnabled',
            false
          ),
          publicEnabled: getValue(
            resp,
            'data.settings.publicEnabled',
            false
          ),
        });
        setAssessmentLearningStatus(getValue(
          resp,
          'data.settings.isLearning',
          false
        ))

        setTermsConditions({
          ...termsConditions,
          termsConditionsDescription: getValue(
            resp,
            'data.settings.termsConditionsDescription',
            ''
          ),
          termsConditionsEnabled: getValue(
            resp,
            'data.settings.termsConditionsEnabled',
            ''
          ),
        });

        setHeaderSettings({
          ...headerSettings,
          headerLogoUrl: getValue(resp, 'data.settings.headerLogoUrl', ''),
        });

        // setQuestionsInfo(getValue(resp, 'data', []).map((item)=>{
        //   return {...item,questions:""
        // }));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleChangeFile = async (e) => {
    if (getValue(surveyDetails, `pages[${0}].questions.length`, 0) === 0) {
      toast.error("Survey questions should not be empty"
      ,{className:'red-circle'}
      )
    } else {
      let file = e[0];
      let formData = new FormData();
      formData.append('image', file);
      try {
        setFileUploadingStatus(true);
        let resp = await uploadFile(formData);
        if (resp) {
          let url = getValue(resp, 'data.url', '');
          setHeaderSettings({
            ...headerSettings,
            headerLogoUrl: url,
          });
          let response = await uploadFileHeader(surveyId, {
            headerEnabled: true,
            headerLogoUrl: url,
          });
          if (response) {
            toast.success('Updated successfully'
            ,{className:'green-circle'}
            );
          }
        } else {
          setFileUploadingStatus(false);
        }
      } catch (error) {
        setFileUploadingStatus(false);
      }
    }
    // this.setState({
    //   [name]: name === "file" ? e.target.files[0] : value,
    // })
  };

  const handleRemoveFile = async () => {
    try {
      setFileUploadingStatus(true);
      setHeaderSettings({
        ...headerSettings,
        headerLogoUrl: '',
      });
      let response = await uploadFileHeader(surveyId, {
        headerEnabled: true,
        headerLogoUrl: '',
      });
      if (response) {
        toast.success('Deleted successfully'
        ,{className:'green-circle'}
        );
      } else {
        setFileUploadingStatus(false);
      }
    } catch (error) {
      setFileUploadingStatus(false);
    }
  };
  const [introLoading,setIntroLoading] = useState(false)
  const handleIntroduction = async () => {
    try {
      setIntroLoading(true)
    let resp = await settingsIntroduction(surveyId, introductionPage);
    if (resp) {
      setIntroLoading(false)
      toast.success('Updated successfully'
      ,{className:'green-circle'}
      );
    }else{
      setIntroLoading(false)
    }
  } catch (error) {
    setIntroLoading(false)
  }
  };
  // **** Common functions **** //

  const handleEditorData = (evt, path, state, value) => {
    var newContent = evt.editor.getData();
    path({
      ...state,
      [value]: newContent,
    });
  };

  const handleStateData = (evt, path, state, _tes) => {
    const { name, value } = evt.target;
    path({
      ...state,
      [name]: value,
    });
  };

  const handlChangeSingleState = (evt, path) => {
    const { name, value } = evt.target;
    path(value);
  };
  const [introductionPage, setIntroductionPage] = useState({
    introductionPageEnabled: false,
    introductionPageDescription: '',
  });
  const [termsConditions, setTermsConditions] = useState({
    termsConditionsEnabled: false,
    termsConditionsDescription: '',
  });

  const [emailTemplate, setEmailTemplate] = useState({
    text: '',
    subject: '',
  });
  const [headerSettings, setHeaderSettings] = useState({
    headerEnabled: false,
    headerLogoUrl: '',
  });
  // "360 Survey"?'SURVEY360':''
  const handleChangeBooleanFourParams = (name, value, path, state) => {
    path({
      ...state,
      [name]: value,
    });
  };

  const [termsLoading,setTermsLoading] = useState(false)
  const handleTermsAndConditions = async () => {
    try {
      setTermsLoading(true)
    let resp = await settingsTermsCondition(surveyId, termsConditions);
    if (resp) {
      setTermsLoading(false)
      toast.success('Updated successfully'
      ,{className:'green-circle'}
      );
    }else{
      setTermsLoading(false)
    }
  } catch (error) {
    setTermsLoading(false) 
  }
  };
  const handleEmailTemplate = async () => {
    try {
      let req = {
        text: emailTemplate.text,
        subject: emailTemplateSubject,
        surveyId: surveyId,
      };
      let resp = await createEmailTemplate(req);
      if (resp) {
        toast.success('Added successfully'
        ,{className:'green-circle'}
        );
        getEmailTemplates();
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleUpdateEmailTemplate = async (id) => {
    try {
      let req = {
        text: emailTemplate.text,
        subject: emailTemplateSubject,
      };
      let resp = await updateEmailTemplate(id, req);
      if (resp) {
        toast.success('Updated successfully'
        ,{className:'green-circle'}
        );
        getEmailTemplates();
      }
    } catch (error) {
      //console.log(error);
    }
  };
  // participant,setParticipant
  let getEmailTemplates = async () => {
    try {
      let resp = await getEmailTemplate(surveyId);
      if (resp) {
        let value: any = Object.values(getValue(resp, 'data', {}));
        setEmailStatus(value);
        if (value.length > 0) {
          setEmailTemplate({
            ...emailTemplate,
            text: getValue(resp, 'data.text', ''),
          });
          setEmailTemplateSubject(getValue(resp, 'data.subject', ''));
          setEmailTemplateId(getValue(resp, 'data.id', ''));
        } else {
          getDefaultEmailTemplate();
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getDefaultEmailTemplate = async () => {
    // let payload = {
    //   surveyId: surveyId,
    // };
    if (surveyQuestionType === 'SURVEY') {
      let resp = await getSurveyEmailTemplate();
      if (resp) {
        setEmailTemplate({
          ...emailTemplate,
          text: getValue(resp, 'data.text', ''),
        });
        setEmailTemplateSubject(getValue(resp, 'data.subject', ''));
        setEmailTemplateId(getValue(resp, 'data.id', ''));
      }
    } else if (surveyQuestionType === 'SURVEY360') {
      let resp = await getSurvey360EmailTemplate();
      if (resp) {
        setEmailTemplate({
          ...emailTemplate,
          text: getValue(resp, 'data.text', ''),
        });
        setEmailTemplateSubject(getValue(resp, 'data.subject', ''));
        setEmailTemplateId(getValue(resp, 'data.id', ''));
      }
    } else if (surveyQuestionType === 'ASSESSMENT') {
      // let resp = await getAssesmentEmailTemplate();
      // if (resp) {
      //   setEmailTemplate({
      //     ...emailTemplate,
      //     text: getValue(resp, 'data.text', ''),
      //   });
      //   setEmailTemplateSubject(getValue(resp, 'data.subject', ''));
      //   setEmailTemplateId(getValue(resp, 'data.id', ''));
      // }
    }
  };

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  const [assessmentLearningStatus,setAssessmentLearningStatus] = useState(false)
  const handleAssessmentLearningStatus = async(status) => {
    setAssessmentLearningStatus(status);
    let payload = {
      isLearning:status
    }
    let resp = await getAssessmentLearningStatus(surveyId,payload);
    if(resp){
      ToastHandler(getValue(resp, 'message', 'Sent successfully'), 'green-circle')
    }

  }


  const [isLeftMenu, manageMenu] = useState(false);
  return (
    <div className="page-layout page-layout-full">
      <div className="create-survey-tabs">
        <FormHeader
          surveyDetails={surveyDetails} 
          surveyId={surveyId} 
          surveyQuestionType={surveyQuestionType}
          surveyTemplate={surveyTemplate}
        />
        <div className="back-btn">
          {!Cookies.get('template') ? (
            <NavLink to="/home">
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          ) : (
            <NavLink to="/templates">
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          )}
        </div>
      </div>
      <div className="page-height">
        <SettingsComponent
          emailStatus={emailStatus}
          fileUploadingStatus={fileUploadingStatus}
          surveyType={getValue(surveyDetails, 'type', '')}
          handleChangeVisibility={handleChangeVisibility}
          visibility={visibility}
          handleChangeFile={handleChangeFile}
          handleRemoveFile={handleRemoveFile}
          handleIntroduction={handleIntroduction}
          introLoading={introLoading}
          handleEditorData={handleEditorData}
          handleStateData={handleStateData}
          setIntroductionPage={setIntroductionPage}
          handleChangeBooleanFourParams={handleChangeBooleanFourParams}
          introductionPage={introductionPage}
          termsConditions={termsConditions}
          emailTemplate={emailTemplate}
          setEmailTemplate={setEmailTemplate}
          setTermsConditions={setTermsConditions}
          handleTermsAndConditions={handleTermsAndConditions}
          termsLoading={termsLoading}
          handleEmailTemplate={handleEmailTemplate}
          headerSettings={headerSettings}
          handleSubmitVisibility={handleSubmitVisibility}
          visibleLoading={visibleLoading}
          handleUpdateEmailTemplate={handleUpdateEmailTemplate}
          // emailtemplate
          emailTemplateId={emailTemplateId}
          emailTemplateText={emailTemplateText}
          emailTemplateSubject={emailTemplateSubject}
          setEmailTemplateText={setEmailTemplateText}
          setEmailTemplateSubject={setEmailTemplateSubject}
          handleChange={handlChangeSingleState}
          surveyDetails={surveyDetails}
          surveyQuestionType={surveyQuestionType}
          handleAssessmentLearningStatus={handleAssessmentLearningStatus}
          assessmentLearningStatus={assessmentLearningStatus}

          // assessment score
          assessmentScore={assessmentScore}
          handleAssessmentScore={handleAssessmentScore}
          handleSubmitAssessmentScore={handleSubmitAssessmentScore}
          assessmentLoading={assessmentLoading}
        />
      </div>
    </div>
  );
};

export default SurveySettingsComponent;
