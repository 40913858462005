import * as React from 'react';
import { NavLink } from "react-router-dom";
import LeftNavLinks from "./left-nav-links";
interface ToggleProps {
    enableLeftMenu,
    isLeftMenu
}
const LeftMenu = (Props: ToggleProps) => {
    const { enableLeftMenu, isLeftMenu } = Props;
    return (
        <>
            <div onClick={enableLeftMenu} className={"left-menu-overlay " + (isLeftMenu ? "show" : "")}></div>
            <div className={"left-menu " + (isLeftMenu ? "show" : "")}>
                <div className="logo">
                    <NavLink to=""><img src="/images/logo.png" alt="" className="img-fluid" /></NavLink>
                </div>
                <LeftNavLinks />
            </div>
        </>
    );
}
export default LeftMenu
