import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './assets/main.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import LeftMenu from './components/menu/left-menu';
import routes from './routes/router';

const App = () => {
  const [isLeftMenu, manageMenu] = useState(false);
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  // const [isLeftNav, manageLeftMenu] = useState(true);

  return (
    <div className="App">
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // className="green-circle"
        // className="red-circle"
      />
      <Router>
        <Switch>
          <>
            <Header enableLeftMenu={() => enableLeftMenu()} />
            <LeftMenu
              isLeftMenu={isLeftMenu}
              enableLeftMenu={() => enableLeftMenu()}
            />
            {routes.map((routeObj, index) => {
              return (
                <Route
                  key={index}
                  path={routeObj.path}
                  exact
                  component={routeObj.component}
                />
              );
            })}
            {/* <Route path="/404" component={PageNotFound} /> */}
            <Footer />
          </>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
