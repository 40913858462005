import React, { useState } from 'react';
import {
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import classnames from 'classnames';
import SearchSVG from '../../components/svg/search-svg';
import { getValue } from '../../core/lodash';
import Pagination from 'react-js-pagination';
import { NavLink, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

interface IChooseTemplateLayoutProps {
  surveyDetails: never[];
  totalCount: number;
  onProductPageChangeHandler: (pageNumber: number) => void;
  pageNumber: number;
  limit: number;
  specificSurvey: object;
  getSpecificSurveyInfo: (id: number) => void;
  name: string;
  handleChange: (e: any) => void;
  handleCreate: () => void;
  handleChangeSearch: (e: any) => void;
  searchInput: string;
  loading3: boolean
}

const ChooseTemplateForExisting: React.FunctionComponent<IChooseTemplateLayoutProps> = (
  props
) => {
  const [activeTab2, setActiveTab2] = useState('1');
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };
  const history = useHistory();

  const handlenavigate = () => {
    toggleModal();
  };
  const handleEdit = () => {
    Cookies.set('existingSurvey', true);
    history.push(
      `/create-new-survey-form/${getValue(props, 'specificSurvey.id', '')}`
    );
  };
  const handleChangeSurvey = (id) => {
    props.getSpecificSurveyInfo(id);
  };
  return (
    <div className="choose-template-layout">
      <div className="choose-template-list">
        <div className="choose-template-list-top">
          <SearchSVG />
          <Input
            type="text"
            placeholder="Search"
            value={props.searchInput}
            onChange={props.handleChangeSearch}
          />
        </div>
        <Nav pills className="menu-ul">
          {/* <li>
            <h6>Customer Satisfaction survey</h6>
          </li> */}
          {getValue(props, 'surveyDetails', []).map((survey) => {
            return (
              <NavItem>
                <NavLink1
                  className={classnames({
                    active:
                      survey.id === getValue(props, 'specificSurvey.id', ''),
                  })}
                  onClick={() => handleChangeSurvey(survey.id)}
                >
                  {survey.name}
                </NavLink1>
              </NavItem>
            );
          })}
        </Nav>
        {props.totalCount > 10 && (
          <Pagination
            activePage={props.pageNumber}
            itemsCountPerPage={props.limit}
            totalItemsCount={props.totalCount}
            pageRangeDisplayed={4}
            onChange={props.onProductPageChangeHandler}
            prevPageText="Prev"
            nextPageText="Next"
            hideFirstLastPages={true}
          />
        )}
      </div>
      <div className="choose-template-height">
        <TabContent activeTab={activeTab2}>
          <TabPane tabId="1">
            <div className="page-padding">
              <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                  {getValue(props, 'surveyDetails.length', []) !== 0 ? (
                    <Card>
                      <CardBody>
                        <div className="left-img-name">
                          <img
                            src={'/images/icon.svg'}
                            alt=""
                            width="36"
                            height="36"
                          />
                          <h4 className="detail-heading">
                            {getValue(props, 'specificSurvey.name', '')}
                          </h4>
                          <p>
                            {getValue(props, 'specificSurvey.pages.length', '')}{' '}
                            page(s) <span className="dot-grey mx-1"></span>{' '}
                            {getValue(
                              props,
                              'specificSurvey.totalQuestions',
                              ''
                            )}{' '}
                            Questions
                          </p>
                          <div className="btn-group-margin">
                            {/* <Button color="border" onClick={handleEdit}>
                              Edit
                            </Button> */}
                            <Button color="blue" onClick={props.handleCreate}>
                              Use this
                            </Button>
                          </div>
                        </div>
                        <hr className="mt-2" />
                        <h3 className="text-center">Sample Questions</h3>
                        <h5 className="text-red mb-0">
                          {' '}
                          {getValue(props, 'specificSurvey.name', '')}
                        </h5>
                        {/* <p>
                          This section contains questions about your personal
                          development
                        </p> */}
                        {getValue(props, 'specificSurvey.pages', []).map(
                          (page, key) => {
                            return (
                              <div key={key}>
                                <p className="mb-0">Page {key + 1}:</p>
                                {getValue(page, 'questions', []).map(
                                  (question, _qIndex) => {
                                    return (
                                      <>
                                        <p className="mb-0">
                                          Question
                                        </p>
                                        <h5>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: question.text,
                                            }}
                                          ></p>
                                        </h5>
                                      </>
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        )}
                      </CardBody>
                    </Card>
                  ) : (
                    'No Surveys Found'
                  )}
                </Col>
              </Row>
            </div>
          </TabPane>
        </TabContent>
      </div>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalBody>
          <h3 className="text-center">Name your Survey</h3>
          <div className="form-group">
            <Label>
              Survey Name<span className="imp">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter Survey Name"
              value={props.name}
              name="name"
              onChange={(e) => {
                props.handleChange(e);
              }}
            />
          </div>
          <div className="btn-group-margin text-md-right">
            {props.loading3 ?
              <Button color="blue" block >
                Please wait...
              </Button> :
              <Button color="blue" block onClick={props.handleCreate}>
                Create Survey
              </Button>}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default ChooseTemplateForExisting;
