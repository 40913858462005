import * as React from 'react';
import { getValue } from '../../core/lodash';
import { Label, Input } from 'reactstrap';
interface IAdvancedOptionsProps {
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  questionIndex: number;
  pageIndex: number;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  surveyType: string;
}
const AdvancedOptions: React.FunctionComponent<IAdvancedOptionsProps> = (
  props
) => {
  return (
    <div>
      {/* // ************* score **************** // */}
      {(props.questionInfo[props.pageIndex].questions[props.questionIndex]
        .type === 'SINGLE_CHOICE' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'CHECK_BOX' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'DROPDOWN' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'MATRIX_RADIO_BOX' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'MATRIX_CHECK_BOX') && (
        //   ||
        // props.questionInfo[props.pageIndex].questions[props.questionIndex]
        //   .type === 'STAR_RATING' ||
        // props.questionInfo[props.pageIndex].questions[props.questionIndex]
        //   .type === 'RATING_SCALE'
        <div className="mb-2">
          <Label className="checkbox-container fs-16 d-inline-block">
            Score this response
            <input
              type="checkbox"
              checked={getValue(
                props,
                `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
                ''
              )}
              onChange={() =>
                props.handleChangeQuestionAdvancedOptions(
                  'scoreEnabled',
                  !getValue(
                    props,
                    `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
                    ''
                  ),
                  props.setQuestionsInfo,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
            <span className="checkmark"></span>
          </Label>
        </div>
      )}

      {/* // *************  others **************** // */}

      {(props.questionInfo[props.pageIndex].questions[props.questionIndex]
        .type === 'SINGLE_CHOICE' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'CHECK_BOX' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'DROPDOWN') &&
        (props.surveyType !== "ASSESSMENT")&&
          (
        <div className="mb-2">
          <Label className="checkbox-container fs-16 d-inline-block">
            Add 'Other' field
            <input
              type="checkbox"
              checked={getValue(
                props,
                `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].otherOptionEnabled`,
                ''
              )}
              onChange={() =>
                props.handleChangeQuestionAdvancedOptions(
                  'otherOptionEnabled',
                  !getValue(
                    props,
                    `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].otherOptionEnabled`,
                    ''
                  ),
                  props.setQuestionsInfo,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
            <span className="checkmark"></span>
          </Label>
          {getValue(
            props,
            `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].otherOptionEnabled`,
            ''
          ) ? (
            <div className="form-group ml-4 pl-2 mt-2">
              <Input
                type="text"
                value={getValue(
                  props,
                  `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].otherOptionMsg`,
                  ''
                )}
                name="otherOptionMsg"
                onChange={(evt) =>
                  props.handleChange(evt, props.pageIndex, props.questionIndex)
                }
              />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {/* // *************  comments **************** // */}

      {(props.questionInfo[props.pageIndex].questions[props.questionIndex]
        .type === 'SINGLE_CHOICE' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'CHECK_BOX' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'DROPDOWN' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'MATRIX_RADIO_BOX' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'MATRIX_CHECK_BOX' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'STAR_RATING' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'RATING_SCALE') && (
        <div className="mb-2">
          <Label className="checkbox-container  fs-16 d-inline-block">
            Add 'Comments' field
            <input
              type="checkbox"
              checked={getValue(
                props,
                `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].commentEnabled`,
                ''
              )}
              onChange={() =>
                props.handleChangeQuestionAdvancedOptions(
                  'commentEnabled',
                  !getValue(
                    props,
                    `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].commentEnabled`,
                    ''
                  ),
                  props.setQuestionsInfo,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
            <span className="checkmark"></span>
          </Label>
          {getValue(
            props,
            `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].commentEnabled`,
            ''
          ) ? (
            <div className="form-group ml-4 pl-2 mt-2">
              <Input
                type="textarea"
                rows={3}
                value={getValue(
                  props,
                  `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].commentMsg`,
                  ''
                )}
                name="commentMsg"
                onChange={(evt) =>
                  props.handleChange(evt, props.pageIndex, props.questionIndex)
                }
              />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      <div className="mb-2">
        <Label className="checkbox-container fs-16 d-inline-block">
          Question hint
          <input
            type="checkbox"
            checked={getValue(
              props,
              `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].hintEnabled`,
              ''
            )}
            onChange={() =>
              props.handleChangeQuestionAdvancedOptions(
                'hintEnabled',
                !getValue(
                  props,
                  `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].hintEnabled`,
                  ''
                ),
                props.setQuestionsInfo,
                props.pageIndex,
                props.questionIndex
              )
            }
          />
          <span className="checkmark"></span>
        </Label>
        {getValue(
          props,
          `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].hintEnabled`,
          ''
        ) ? (
          <div className="form-group ml-4 pl-2 mt-2">
            <Input
              type="textarea"
              rows={4}
              value={getValue(
                props,
                `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].hintMsg`,
                ''
              )}
              name="hintMsg"
              onChange={(evt) =>
                props.handleChange(evt, props.pageIndex, props.questionIndex)
              }
            />
          </div>
        ) : (
          ''
        )}
      </div>
      {(props.questionInfo[props.pageIndex].questions[props.questionIndex]
        .type === 'SINGLE_CHOICE' ||
        props.questionInfo[props.pageIndex].questions[props.questionIndex]
          .type === 'CHECK_BOX') && (
        //   ||
        // props.questionInfo[props.pageIndex].questions[props.questionIndex]
        //   .type === 'DROPDOWN'
        <div className="mb-2">
          <Label className="checkbox-container fs-16 d-inline-block">
            Randomize, sort, or flip choices
            <input
              type="checkbox"
              checked={getValue(
                props,
                `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].randomEnabled`,
                ''
              )}
              onChange={() =>
                props.handleChangeQuestionAdvancedOptions(
                  'randomEnabled',
                  !getValue(
                    props,
                    `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].randomEnabled`,
                    ''
                  ),
                  props.setQuestionsInfo,
                  props.pageIndex,
                  props.questionIndex
                )
              }
            />
            <span className="checkmark"></span>
          </Label>
        </div>
      )}
    </div>
  );
};
export default AdvancedOptions;
