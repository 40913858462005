import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Table,
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
} from 'reactstrap';
import LeftNav from '../../components/menu/left-nav';
import {
  deleteSurvey,
  get360SummarySurveyInfo,
  get360SummarySurveyRawdata,
  getCompletedSurveys,
  get360SummarySurveyRawdataGrid,
  getSummarySurvey,
  getSurveySummaryResponse,
  getSummarySurveyPagination,
  get360TotalSummary,
} from '../../core/services/surveyService';
import { getValue } from '../../core/lodash';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import { NavLink } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';
import Pagination from 'react-js-pagination';
import BackSVG from '../../components/svg/back-svg';
import SummarySurveyQuestion from './summary/summarySurveyQuestions';
import MoreSVG from '../../components/svg/more-svg';
import ArrowLeftSVG from '../../components/svg/arrow-left-svg';
import SortSVG from '../../components/svg/sort-svg';

interface ITemplateMainProps {}

const Summary360Level1: React.FunctionComponent<ITemplateMainProps> = (
  props
) => {
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [search, openSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [surveyInfo, setSurveyInfo] = useState([]);
  const [surveyInfos, setSurveyInfos] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // summary
  const [summaryInfo, setSummaryInfo] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [sort, setSort] = useState('newest');
  const [sortStatus, setSortStatus] = useState('desc');

  // delete
  const [isOpen, setIsOPen] = useState(false);
  const [removeValue, setRemoveValue] = useState('');
  const [deleteId, setDeleteId] = useState('');



  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleDeleteSurvey = async (id, value) => {
    setDeleteId(id);
    setRemoveValue(value);
    handleModal();
  };

  // ===========================================================================
  // chart section
  // ===========================================================================

  const [series, setSeries] = useState([
    {
      data: [400, 540, 1200, 1380],
    },
  ]);

  const [options, setOptions] = useState<any>({
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Red', 'Blue', 'Green', 'Yellow', 'Violet'],
    },
  });
  // 2nd chart

  const [option1, setOption1] = useState<any>({
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      stackType: '100%',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: '100% Stacked Bar',
    },
    xaxis: {
      categories: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + 'K';
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40,
    },
  });

  const [series1, setSeries1] = useState([
    {
      name: 'Marine Sprite',
      data: [44, 55, 41, 37, 22, 43, 21],
    },
    {
      name: 'Striking Calf',
      data: [53, 32, 33, 52, 13, 43, 32],
    },
    {
      name: 'Tank Picture',
      data: [12, 17, 11, 9, 15, 11, 20],
    },
    {
      name: 'Bucket Slope',
      data: [9, 7, 5, 8, 6, 9, 4],
    },
    {
      name: 'Reborn Kid',
      data: [25, 12, 19, 32, 25, 24, 10],
    },
  ]);

  // ===========================================================================
  // handle change section
  // ===========================================================================

  const handleDeleteFun = async () => {
    try {
      let resp = await deleteSurvey(deleteId);
      if (resp) {
        toast.success('Deleted successfully', { className: 'green-circle' });
        handleModal();
        // getOpenSurveyFun();
      }
    } catch (error) {
      handleModal();
    }
  };

  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  // ===========================================================================
  // api section
  // ===========================================================================

  useEffect(() => {
    // getSurveySummary();
    // getSummerySurveyQuestions();
    // if (!getValue(userInfo, 'empId', '')) {
    getUserInfo();
    // }
  }, []);

  const getUserInfo = async () => {
    let resp = await get360SummarySurveyInfo(
      getValue(props, 'match.params.surveyId', ''),
      getValue(props, 'match.params.empId', '')
    );
    if (resp) {
      setUserInfo(getValue(resp, `data`, {}));
    }
  };
  useEffect(() => {
    if (activeTab === '1') {
      getSummaryOptions('');
      getSurveyTotalSummary();
    } else if (activeTab === '2') {
      getSurveyInfo(skip,sortStatus);
    } else {
      // getSummerySurveyQuestion();
    }
  }, [activeTab]);

  const [chartSummary, setChartSummary] = useState([]);
  const [chartSummaryInfo, setChartSummaryInfo] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [
    summaryQuestionsWithoutAnswers,
    setSummaryQuestionsWithoutAnswers,
  ] = useState([]);
  const [
    summaryQuestionsWithoutAnswersQuestions,
    setSummaryQuestionsWithoutAnswersQuestions,
  ] = useState([]);

  // ===========================================================================
  // summary/graph section
  // ===========================================================================
  const getSummaryOptions = async (pageId) => {
    try {
      setSummaryLoading(true);
      let resp = await get360SummarySurveyRawdataGrid(
        getValue(props, 'match.params.surveyId', ''),
        getValue(props, 'match.params.empId', ''),
        pageId
      );
      if (resp) {
        setChartSummary(getValue(resp, 'data.page', {}));
        setSummaryQuestionsWithoutAnswers(getValue(resp, 'data', {}));
        setSummaryQuestionsWithoutAnswersQuestions(
          getValue(resp, 'data.page', [])
        );

        setSummaryLoading(false);
      } else {
        setSummaryLoading(false);
      }
    } catch (error) {
      setSummaryLoading(false);
    }
  };

  const getSummerySurveyQuestions = async () => {
    let resp = await getSummarySurvey(
      getValue(props, 'match.params.surveyId', '')
    );
    if (resp) {
      getSummaryOptions(getValue(resp, 'data.surveyPageIds[0]', ''));
      setSummaryQuestionsWithoutAnswers(getValue(resp, 'data', {}));
    }
  };

  const getSummerySurveyQuestionspage = async (pageId) => {
    getSummaryOptions(pageId);
    let resp = await getSummarySurveyPagination(
      getValue(props, 'match.params.surveyId', ''),
      pageId
    );
    if (resp) {
      setSummaryQuestionsWithoutAnswers(getValue(resp, 'data', {}));
    }
  };

  const handleChangePage = (index) => {
    togglePage(index);
  };

  const togglePage = async (index) => {
    let pageID = getValue(
      summaryQuestionsWithoutAnswers,
      `surveyPageIds[${index - 1}]`,
      0
    );
    getSummaryOptions(pageID);
  };

  const getSurveyInfo = async (skip,sort) => {
    try {
      setIsLoading(true);
      let resp = await get360SummarySurveyRawdata(
        getValue(props, 'match.params.surveyId', ''),
        getValue(props, 'match.params.empId', ''),
        skip,
        limit,
        sort
      );
      if (resp) {
        setIsLoading(false);
        setSurveyInfos(getValue(resp, 'data', []));
        setSurveyInfo(getValue(resp, 'data.userSurvey', []));
        setTotalCount(getValue(resp, 'data.count', []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [surveyTotalSummary, setSurveyTotalSummary] = useState({});

  const getSurveyTotalSummary = async () => {
    let resp = await get360TotalSummary(
      getValue(props, 'match.params.surveyId', ''),
      getValue(props, 'match.params.empId', '')
    );
    if (resp) {
      setSurveyTotalSummary(getValue(resp, 'data.summary', {}));
    }
  };

  // const getSummerySurveyQuestions = async (skip) => {
  //   let resp = await get360SummarySurveyRawdata(
  //     getValue(props, 'match.params.surveyId', ''),
  //     getValue(props, 'match.params.empId', ''),skip,limit
  //   );
  //   if (resp) {
  //     //console.log(resp);
  //   }
  // };

  // ===========================================================================
  // search section
  // ===========================================================================

  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput('');
    globalSearch('');
  };

  const globalSearch = async (value) => {
    try {
      let response = await getCompletedSurveys(skip, limit, value, sort);
      if (response) {
        setSurveyInfo(getValue(response, 'data.surveys', []));
        setTotalCount(getValue(response, 'data.count', []));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    // getSummerySurveyQuestions(skip);
    getSurveyInfo(skip,sortStatus);
  };

  const handleSort = (status) => {
    setSortStatus(status)
    getSurveyInfo(skip,status);

  }
  //console.log(chartSummary);
  //console.log(summaryQuestionsWithoutAnswers);
  return (
    <div>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="page-padding">
            <Row>
              {/* <Col g={{ size: 10, offset: 1 }}>
                <div className="back-btn"> */}
              <a
                href={`${window.location.origin}/360-summary/${getValue(
                  props,
                  `match.params.surveyId`,
                  ''
                )}/${getValue(props, `match.params.routeUrl`, '')}`}
              >
                <Button color="nothing">
                  <BackSVG />
                </Button>
              </a>
              {/* </div>
                
              </Col>
             */}
              <Col lg={{ size: 10, offset: 1 }}>
                <p className="mb-1">
                  <b className="text-black fw-sbold">ID:</b>&nbsp;&nbsp;
                  {getValue(userInfo, 'empId', '-')}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Employee Name:</b>
                  &nbsp;&nbsp;
                  {getValue(userInfo, 'name', '-')}
                </p>
                {getValue(userInfo, 'email', '') ? (
                  <p className="mb-1">
                    <b className="text-black fw-sbold">Email:</b>&nbsp;&nbsp;
                    {getValue(userInfo, 'email', '')}
                  </p>
                ) : (
                  ''
                )}
                {/* <p className="mb-1">
                  <b className="text-black fw-sbold">Feedback Completed Percentage:</b>&nbsp;&nbsp;
                  {getValue(userInfo, 'feebackCompletedPercentage', '0')}{' '}%
                </p> */}
                <p className="mb-1">
                  <b className="text-black fw-sbold">Self Evaluation:</b>
                  &nbsp;&nbsp;
                  {!getValue(userInfo, 'selfCompleted', '')
                    ? 'Pending'
                    : 'Completed'}
                </p>
                {getValue(summaryQuestionsWithoutAnswers, 'page.id', '') && (
                  <>
                    <p className="mb-1">
                      <b className="text-black fw-sbold">Survey Name:</b>
                      &nbsp;&nbsp;
                      {getValue(summaryQuestionsWithoutAnswers, 'name', '')}
                    </p>
                    <p className="mb-1">
                      <b className="text-black fw-sbold">Survey Type:</b>
                      &nbsp;&nbsp;
                      {getValue(summaryQuestionsWithoutAnswers, 'type', '') ===
                      'SURVEY360'
                        ? 'Survey 360'
                        : getValue(chartSummaryInfo, 'type', '')}
                    </p>

                    <p className="mb-1">
                      <b className="text-black fw-sbold">Status:</b>&nbsp;&nbsp;
                      {getValue(summaryQuestionsWithoutAnswers, 'status', '')}
                    </p>
                  </>
                )}
                {/* <p className="mb-1">
                  <b className="text-black fw-sbold">
                    Completion Date 
                    &amp; Time Taken
                    :
                  </b>
                  &nbsp;&nbsp; 11 Dec 2020, 09:30 AM{' '}
                  <span className="dot-grey"></span> 10 Min, 50 Sec
                </p> */}
                {/* <p className="mb-1">
                  <b className="text-black fw-sbold">Questions:</b>&nbsp;&nbsp;
                  {getValue(surveyInfos,'totalQuestions',0)}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Pages:</b>&nbsp;&nbsp;
                  {getValue(surveyInfos,'totalPages',0)}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Self:</b>
                  &nbsp;&nbsp; 0/1
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Manager Participants:</b>
                  &nbsp;&nbsp; 0/0
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Peers Participants:</b>
                  &nbsp;&nbsp; 0/0
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Subordinate Participants:</b>
                  &nbsp;&nbsp; 0/0
                </p>
              
                <p className="mb-1">
                  <b className="text-black fw-sbold">Total Participants:</b>
                  &nbsp;&nbsp; {getValue(surveyInfos,'attemptedParticipant',0)}/{getValue(surveyInfos,'totalParticipant',0)}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Client Participants:</b>
                  &nbsp;&nbsp; 01/01
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Status:</b>&nbsp;&nbsp;
                  <span className="text-yellow">{getValue(surveyInfos,'status',0)}</span>
                </p> */}
              </Col>

              <div className="create-survey-tabs">
                {/* <Col md="6">
                <ul className="table-header-actions mt-1">
                  <li className="hover-circle">
                    <Button color="nothing">
                      <DeleteSVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <EditSVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <CopySVG />
                    </Button>
                  </li>
                  <li className="hover-circle">
                    <Button color="nothing">
                      <ShareSVG />
                    </Button>
                  </li>
                </ul>
              </Col> */}
              </div>
            </Row>
          </div>
          <hr className="mb-0" />
          <div className="create-survey-tabs">
            <Nav pills className="basic-tabs pl-0">
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Summary
                </NavLink1>
              </NavItem>
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Raw Data
                </NavLink1>
              </NavItem>
            </Nav>
          </div>
          <div className="page-padding pt-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {/* <Row>
                  <Col md="6">
                    <h5 className="mt-3 mb-1">
                      {getValue(summaryInfo, `name`, 0)}
                    </h5>
                    <h5 className="text-green-light">
                      Status:{getValue(summaryInfo, 'status', '-')}
                    </h5>
                  </Col>
                  <Col md="6">
                    <ul className="table-header-actions mt-1">
                      <li className="hover-circle">
                        <Button color="nothing">
                          <PageSVG />
                        </Button>
                      </li>
                      <li className="hover-circle">
                        <Button color="nothing">
                          <CopySVG />
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="2">
                        <p className="mb-0">Respondents</p>
                        <Row>
                          <Col md="6">
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `respondCollected`, 0)}
                            </h3>
                            <p className="text-green-light mb-0">
                              <small>Collected</small>
                            </p>
                          </Col>
                          <Col md="6">
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `respondTarget`, 0)}
                            </h3>
                            <p className="mb-0">
                              <small>Targeted</small>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="2">
                        <p className="mb-0">Start Date</p>
                        <h3 className="mb-0">
                          <Moment format="DD MMM YYYY">
                            {getValue(summaryInfo, `launchDate`, 0)}
                          </Moment>
                        </h3>
                        <p className="mb-0">
                          <small>
                            <Moment format="hh:mm a">
                              {getValue(summaryInfo, `launchDate`, 0)}
                            </Moment>
                          </small>
                        </p>
                      </Col>
                      <Col md="2">
                        <p className="mb-0">End Date</p>
                        <h3 className="mb-0">
                          <Moment format="DD MMM YYYY">
                            {getValue(summaryInfo, `endDate`, 0)}
                          </Moment>
                        </h3>
                        <p className="mb-0">
                          <small>
                            <Moment format="hh:mm a">
                              {getValue(summaryInfo, `endDate`, 0)}
                            </Moment>
                          </small>
                        </p>
                      </Col>
                      <Col md="4">
                        <Row>
                          <Col md="6">
                            <p className="mb-0">Type</p>
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `type`, '')
                                ? getValue(summaryInfo, `type`, '')
                                    .split(' ')
                                    .map(
                                      (w) =>
                                        w[0].toUpperCase() +
                                        w.substr(1).toLowerCase()
                                    )
                                    .join(' ')
                                : ''}
                            </h3>
                            <p className="mb-0">
                              <small>Private</small>
                            </p>
                          </Col>
                          <Col md="6">
                            <p className="mb-0">Questions</p>
                            <h3 className="mb-0">
                              {getValue(summaryInfo, `totalQuestions`, 0)}
                            </h3>
                            <p className="mb-0">
                              <small>
                                Pages :  {getValue(summaryInfo, `totalPages`, 0)}
                              </small>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
               */}
                <h3 className="mt-4 text-green-light text-center"></h3>
                <div className="table-responsive">
                  <Table className="table-head-light">
                    <thead>
                      <tr>
                        <th style={{ width: '40%' }}>Row</th>
                        <th style={{ width: '10%' }}>Self Evaluation Score</th>
                        <th style={{ width: '10%' }}>Manager Average Score</th>
                        <th style={{ width: '10%' }}>Peers Average Score</th>
                        <th style={{ width: '10%' }}>
                          Subordinates Average Score
                        </th>
                        <th style={{ width: '10%' }}>External Average Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-green-light">All Parameters</td>
                        <td className="text-green-light">
                          {getValue(
                            surveyTotalSummary,
                            `[${0}].averageRating`,
                            0.0
                          )}
                        </td>
                        <td className="text-green-light">
                          {getValue(
                            surveyTotalSummary,
                            `[${1}].averageRating`,
                            0.0
                          )}
                        </td>
                        <td className="text-green-light">
                          {getValue(
                            surveyTotalSummary,
                            `[${2}].averageRating`,
                            0.0
                          )}
                        </td>
                        <td className="text-green-light">
                          {getValue(
                            surveyTotalSummary,
                            `[${3}].averageRating`,
                            0.0
                          )}
                        </td>
                        <td className="text-green-light">
                          {getValue(
                            surveyTotalSummary,
                            `[${4}].averageRating`,
                            0.0
                          )}
                          
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {/* <Col md="13" className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle color="border" caret>
                            All Participants
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem>All Participants</DropdownItem>
                            <DropdownItem>Employee (Self)</DropdownItem>
                            <DropdownItem>Manager</DropdownItem>
                            <DropdownItem>Peers</DropdownItem>
                            <DropdownItem>Subordinates</DropdownItem>
                            <DropdownItem>Clients</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col> */}
                {getValue(summaryQuestionsWithoutAnswers, 'page.id', '') && (
                  <SummarySurveyQuestion
                    summaryInfo={summaryInfo}
                    options={options}
                    series={series}
                    toggleModal={toggleModal}
                    option1={option1}
                    series1={series1}
                    questionsInfo={chartSummary}
                    infoData={summaryQuestionsWithoutAnswers}
                    handleSurveySubmit={getSummaryOptions}
                    handleChangePage={handleChangePage}
                    from={360}
                  />
                )}
                {/* <Card className="mb-3">
                  <CardBody>
                    <Row>
                      <Col md="9">
                        <h5 className="mb-1">
                          Question 1 <span className="text-red">*</span>
                        </h5>
                        <h3 className="mb-1">
                          Which color of visible light travels the fastest in
                          the crown glass?
                        </h3>
                        <p>
                          <span className="mr-3">Answered: 400</span> Skipped: 0
                        </p>
                      </Col>
                      <Col md="3" className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle color="border" caret>
                            All Participants
                          </DropdownToggle>
                          <DropdownMenu right>
                            <DropdownItem>All Participants</DropdownItem>
                            <DropdownItem>Employee (Self)</DropdownItem>
                            <DropdownItem>Manager</DropdownItem>
                            <DropdownItem>Peers</DropdownItem>
                            <DropdownItem>Subordinates</DropdownItem>
                            <DropdownItem>Clients</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                    <ReactApexChart options={option1} series={series1} type="bar" height={350} />
                    <h5>Response Data:</h5>
                    <Table className="table-head-light">
                      <thead>
                        <tr>
                          <th style={{ width: '40%' }}>Exceeds Expectations</th>
                          <th style={{ width: '20%' }}>Meets Expectations</th>
                          <th style={{ width: '20%' }}>Doesn't Meet Expectations</th>
                          <th style={{ width: '20%' }}>Doesn't Apply</th>
                          <th style={{ width: '20%' }}>Response Count</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Choice 1</td>
                          <td className="text-green-light">66.7%(30)</td>
                          <td className="text-green-light">66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>100</td>

                        </tr>
                        <tr>
                          <td>Choice 2</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>100</td>

                        </tr>
                        <tr>
                          <td>Choice 3</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>100</td>

                        </tr>
                        <tr>
                          <td>Choice 4</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>100</td>

                        </tr>
                        <tr>
                          <td>Choice 5</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>66.7%(30)</td>
                          <td>100</td>

                        </tr>
                      </tbody>
                    </Table>
                    <br></br>
                    <h5>Score: 140/400</h5>
                    <Table className="table-head-light">
                      <thead>
                        <tr>
                          <th style={{ width: '40%' }}>Choices</th>
                          <th style={{ width: '20%' }}>Max Score</th>
                          <th style={{ width: '20%' }}>Self Evaluation Score</th>
                          <th style={{ width: '20%' }}>Manager Avg Score</th>
                          <th style={{ width: '20%' }}>Peers Avg Score</th>
                          <th style={{ width: '20%' }}>Subordinates Avg Score</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Choice 1</td>
                          <td className="text-green-light">5.0</td>
                          <td>4.0</td>
                          <td>03</td>
                          <td>04</td>
                          <td>04</td>

                        </tr>
                        <tr>
                          <td>Choice 2</td>
                          <td className="text-green-light">5.0</td>
                          <td>4.0</td>
                          <td>03</td>
                          <td>04</td>
                          <td>04</td>

                        </tr>
                        <tr>
                          <td>Choice 3</td>
                          <td className="text-green-light">5.0</td>
                          <td>4.0</td>
                          <td>03</td>
                          <td>04</td>
                          <td>04</td>

                        </tr>
                        <tr>
                          <td>Choice 4</td>
                          <td className="text-green-light">5.0</td>
                          <td>4.0</td>
                          <td>03</td>
                          <td>04</td>
                          <td>04</td>

                        </tr>
                        <tr>
                          <td>Choice 5</td>
                          <td className="text-green-light">5.0</td>
                          <td>4.0</td>
                          <td>03</td>
                          <td>04</td>
                          <td>04</td>

                        </tr>
                      </tbody>
                    </Table>

                  </CardBody>
                </Card>
                <Card className="mb-3">
                  <CardBody>
                    <h5 className="mb-1">Question 2:</h5>
                    <h3 className="mb-1">
                      Which color of visible light travels the fastest in the
                      crown glass?
                    </h3>
                    <p>
                      <span className="mr-3">Answered: 400</span> Skipped: 0
                    </p>
                    <div className="text-center">
                      <Button color="nothing text-blue" onClick={toggleModal}>
                        Show 400 Responses
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              */}
              </TabPane>
              <TabPane tabId="2">
                {!isLoading ? (
                  <div className="table-responsive">
                    <Table className="table-custom">
                      <thead>
                        <tr>
                          <th>Employee Name</th>
                          <th>Employee ID</th>
                          <th>Group</th>
                          <th>Completion Date &amp; Time</th>
                          <th>Answered</th>
                          <th>Skipped</th>
                          <th>Score</th>
                          <th style={{cursor:'pointer'}} onClick={()=>handleSort(sortStatus === 'asc'?'desc':'asc')}><SortSVG /></th>
                        </tr>
                      </thead>
                      <tbody>
                        {getValue(surveyInfo, 'length', 0) !== 0 ? (
                          surveyInfo.map((survey) => {
                            return (
                              <tr>
                                <td>
                                  <NavLink
                                    to={`/survey-preview/${getValue(
                                      survey,
                                      `surveyId`,
                                      ''
                                    )}/${getValue(
                                      survey,
                                      `id`,
                                      ''
                                    )}/inprogress-surveys`}
                                    style={{ color: '#d7242e' }}
                                  >
                                    {getValue(survey, 'name', '')
                                      ? getValue(survey, 'name', '')
                                      : getValue(survey, 'id', '')}
                                  </NavLink>
                                  <p>{getValue(survey, 'email', '')}</p>
                                </td>
                                <td>
                                  <NavLink
                                    to={`/survey-preview/${getValue(
                                      survey,
                                      `surveyId`,
                                      ''
                                    )}/${getValue(
                                      survey,
                                      `id`,
                                      ''
                                    )}/inprogress-surveys`}
                                    style={{ color: '#d7242e' }}
                                  >
                                    {getValue(survey, 'empId', '')}
                                  </NavLink>
                                </td>
                                <td>{getValue(survey, 'group', '')}</td>
                                <td>
                                  <Moment format="DD MMM YYYY hh:mm a">
                                    {getValue(survey, 'updatedAt', '')}
                                  </Moment>
                                </td>
                                <td>{getValue(survey, 'answered', '')}</td>
                                <td>{getValue(survey, 'skipped', '')}</td>
                                <td>
                                  {getValue(survey, 'score.score', '')}/
                                  {getValue(survey, 'score.total', '')}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  'Please wait...'
                )}
              </TabPane>
              {totalCount > 10 && (
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={onProductPageChangeHandler}
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              )}
              <TabPane tabId="3">Lorem Ipsum</TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <DeleteSurveyModal
        isOpen={isOpen}
        handleDeleteFun={handleDeleteFun}
        removeValue={removeValue}
        handleModal={handleModal}
      />
      <Modal isOpen={modal} toggle={toggleModal} centered className="modal-lg">
        <ModalBody>
          <h5 className="mb-1">Question 4:</h5>
          <h3 className="mb-1">What is the capital city of India</h3>
          <hr className="my-2" />
          <ol className="mt-3 answer-ol">
            <li>Delhi</li>
            <li>Bangalore</li>
            <li>Pune</li>
          </ol>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Summary360Level1;
