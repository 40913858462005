import * as React from 'react';
import LeftNavLinks from "./left-nav-links"
interface ToggleProps {
  isLeftNav,
  collapseLeftNav
}
const LeftNav = (Props: ToggleProps) => {
  const { isLeftNav } = Props;
  return (
    <div className={"left-nav " + (isLeftNav ? "active" : "")}>
      <LeftNavLinks />
    </div>
  )
}
export default LeftNav
