import { NavLink } from 'react-router-dom';
import {
  Container,
  Button,
  Col,
  Row,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Input,
} from 'reactstrap';

import MenuSVG from '../svg/menu-svg';
import * as React from 'react';
import BulbSVG from '../../components/svg/bulb-svg';
import BulkSVG from '../../components/svg/bulk-svg';
import CloseSVG from '../../components/svg/close-svg';
import RadioEmptySVG from '../../components/svg/radio-empty-svg';
import AddSVG from '../../components/svg/add-svg';
import MoreSVG from '../../components/svg/more-svg';
import CheckboxEmptySVG from '../../components/svg/checkbox-empty-svg';
import MinusSVG from '../../components/svg/minus-svg';
import DefaultSection from './defaultSection';
import { getValue } from 'core/lodash';
import AdvancedOptions from './AdvancedOptions';
import ThumbSVG from 'components/svg/thumb-svg';

interface IRenderOptionsProps {
  pageIndex: number;
  questionInfo: any;
  setQuestionsInfo: any;
  questionIndex: number;
  surveyId: string;
  handleQuestionTypeValues: (
    e: any,
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  addMoreOption: (value: any, pageIndex: number, questionIndex: number) => void;
  removeMoreOption: (
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleScore: (
    index: number,
    name: string,
    value: number,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  addPredefinedOption: (
    value: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  enableSideMenuBulkAnswer: (pageIndex: number, questionIndex: number) => void;
  handleScoreInputBoxEdit: (
    index: number,
    e: any,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  surveyType: string;
  handleAssessmentCorrectAnswer: (
    optionIndex: number,
    status: boolean,
    updateState: any,
    pageIndex: number,
    questionIndex: number,
    type:string
  ) => void;
}
const CheckBoX: React.FunctionComponent<IRenderOptionsProps> = (props) => {
  return (
    <div className="checkbox-choice">
      <DefaultSection
        addPredefinedOption={props.addPredefinedOption}
        pageIndex={props.pageIndex}
        questionIndex={props.questionIndex}
        enableSideMenuBulkAnswer={props.enableSideMenuBulkAnswer}
      />
      {getValue(
        props,
        `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].options`,
        []
      ).map((item, index) => {
        return (
          <div className="thumbs-up" key={index}>
            <Row>
              <Col
                md={
                  getValue(
                    props,
                    `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
                    ''
                  )
                    ? '11'
                    : '12'
                }
              >
                <Card className="icon-text-more-close mr-0 mb-3">
                  <CardBody>
                    <span className="left-icon">
                      <CheckboxEmptySVG />
                    </span>
                    <Input
                      type="text"
                      placeholder="Enter answer text..."
                      name="text"
                      value={getValue(item, 'text', '')?getValue(item, 'text', ''):""}
                      onChange={(e) =>
                        props.handleQuestionTypeValues(
                          e,
                          index,
                          props.pageIndex,
                          props.questionIndex
                        )
                      }
                    />{' '}
                    <span className="right-icon">
                      <UncontrolledDropdown>
                        <DropdownToggle color="nothing">
                          <MoreSVG />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() =>
                              props.addMoreOption(
                                getValue(
                                  props,
                                  `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].options`,
                                  []
                                )[index],
                                props.pageIndex,
                                props.questionIndex
                              )
                            }
                          >
                            Duplicate
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </span>
                  </CardBody>
                </Card>
              </Col>
              {getValue(
                props,
                `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
                ''
              ) ? (
                <Col md="1">
                  <Card className="icon-text-more-close mr-0 pl-0 pr-0 mb-3">
                    <CardBody>
                      <Input
                        className="text-center"
                        type="number"
                        value={getValue(item, 'score', 0)?getValue(item, 'score', 0):0}
                        name="score"
                        onChange={(e) =>
                          props.handleScoreInputBoxEdit(
                            index,
                            e,
                            props.setQuestionsInfo,
                            props.pageIndex,
                            props.questionIndex
                          )
                        }
                      />
                    </CardBody>
                    {index !== 0 ? (
                      <span
                        className="close-icon"
                        onClick={() =>
                          props.removeMoreOption(
                            index,
                            props.pageIndex,
                            props.questionIndex
                          )
                        }
                      >
                        <CloseSVG />
                      </span>
                    ) : (
                      ''
                    )}
                  </Card>
                </Col>
              ) : (
                ''
              )}
            </Row>
            {getValue(props, 'surveyType', '') === 'ASSESSMENT' &&
              (item.correct ? (
                <Button
                  color="nothing thumb-icon"
                  className="active"
                  onClick={() => {
                    props.handleAssessmentCorrectAnswer(
                      index,
                      !item.correct,
                      props.setQuestionsInfo,
                      props.pageIndex,
                      props.questionIndex,
                      'CHECK_BOX'
                    );
                  }}
                >
                  <ThumbSVG />
                </Button>
              ) : (
                <Button
                  color="nothing thumb-icon"
                  onClick={() => {
                    props.handleAssessmentCorrectAnswer(
                      index,
                      !item.correct,
                      props.setQuestionsInfo,
                      props.pageIndex,
                      props.questionIndex,
                      'CHECK_BOX'
                    );
                  }}
                >
                  <ThumbSVG />
                </Button>
              ))}
          </div>
        );
      })}
      {/* <Row>
        <Col md="9">
          <Card className="icon-text-more-close mr-0 mb-3">
            <CardBody>
              <span className="left-icon">
                <CheckboxEmptySVG />
              </span>
              <Input type="text" placeholder="Enter answer text..." />
              <span className="right-icon">
                <UncontrolledDropdown>
                  <DropdownToggle color="nothing">
                    <MoreSVG />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>Duplicate</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </span>
            </CardBody>
          </Card>
        </Col>
        <Col md="3">
          <Card className="plus-minus-card mb-3">
            <CardBody>
              <Button color="nothing" className="left-icon">
                <MinusSVG />
              </Button>
              <span className="number-txt">01</span>
              <Button color="nothing" className="right-icon">
                <AddSVG />
              </Button>
              <span className="close-icon">
                <CloseSVG />
              </span>
            </CardBody>
          </Card>
        </Col>
      </Row>
      */}
      <div className="my-3">
        <Button
          color="nothing"
          className="blue-text-svg"
          onClick={() =>
            props.addMoreOption(
              {
                text: '',
                score: 0,
                correct: false,
              },
              props.pageIndex,
              props.questionIndex
            )
          }
        >
          <AddSVG /> Add more
        </Button>
      </div>
      <h5>Advance Options:</h5>
      <AdvancedOptions
        surveyType={props.surveyType}
        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
    </div>
  );
};
export default CheckBoX;
