import { NavLink } from 'react-router-dom';
// import ProfileDropdown from "./profileDropdown";
import {
  Container,
  Button,
  Col,
  Row,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Input,
} from 'reactstrap';

import MenuSVG from '../svg/menu-svg';
import * as React from 'react';
import BulbSVG from '../svg/bulb-svg';
import BulkSVG from '../svg/bulk-svg';
import CloseSVG from '../svg/close-svg';
import RadioEmptySVG from '../svg/radio-empty-svg';
import AddSVG from '../svg/add-svg';
import ThumbSVG from '../svg/thumb-svg';
import MoreSVG from '../svg/more-svg';
import DefaultSection from './defaultSection';
import MinusSVG from 'components/svg/minus-svg';
import CheckboxEmptySVG from 'components/svg/checkbox-empty-svg';
import { getValue } from 'core/lodash';
import { alphbet } from '../constants/alphabet';
import AdvancedOptions from './AdvancedOptions';

interface ISingleChoiceProps {
  pageIndex: number;
  questionInfo: any;
  setQuestionsInfo: any;
  questionIndex: number;
  surveyId: string;
  handleQuestionTypeValues: (
    e: any,
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  addMoreOption: (value: any, pageIndex: number, questionIndex: number) => void;
  removeMoreOption: (
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleScore: (
    index: number,
    name: string,
    value: number,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  addPredefinedOption: (
    value: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  enableSideMenuBulkAnswer: (pageIndex: number, questionIndex: number) => void;
  handleScoreInputBoxEdit: (
    index: number,
    e: any,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  handleAssessmentCorrectAnswer: (
    optionIndex: number,
    status: boolean,
    updateState: any,
    pageIndex: number,
    questionIndex: number,
    type:string
  ) => void;
  surveyType: string;
}

const SingleChoice: React.FunctionComponent<ISingleChoiceProps> = (props) => {
  return (
    <div className="multiple-choice">
      <DefaultSection
        addPredefinedOption={props.addPredefinedOption}
        pageIndex={props.pageIndex}
        questionIndex={props.questionIndex}
        enableSideMenuBulkAnswer={props.enableSideMenuBulkAnswer}
      />
      {getValue(
        props,
        `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].options`,
        []
      ).map((item, index) => {
        return (
          <div className="thumbs-up" key={index}>
            <Row>
              <Col
                md={
                  getValue(
                    props,
                    `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
                    ''
                  )
                    ? '11'
                    : '12'
                }
              >
                <Card className="icon-text-more-close mr-0 mb-3">
                  <CardBody>
                    <span className="left-icon">
                      <RadioEmptySVG />
                    </span>
                    <Input
                      type="text"
                      placeholder="Enter answer text..."
                      name="text"
                      value={
                        getValue(item, 'text', '')
                          ? getValue(item, 'text', '')
                          : ''
                      }
                      onChange={(e) =>
                        props.handleQuestionTypeValues(
                          e,
                          index,
                          props.pageIndex,
                          props.questionIndex
                        )
                      }
                    />
                    <span className="right-icon">
                      <UncontrolledDropdown>
                        <DropdownToggle color="nothing">
                          <MoreSVG />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() =>
                              props.addMoreOption(
                                getValue(
                                  props,
                                  `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].options`,
                                  []
                                )[index],
                                props.pageIndex,
                                props.questionIndex
                              )
                            }
                          >
                            Duplicate
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </span>
                  </CardBody>
                  {getValue(
                    props,
                    `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
                    ''
                  ) ? (
                    ''
                  ) : (
                    <>
                      {index !== 0 ? (
                        <span
                          className="close-icon"
                          onClick={() =>
                            props.removeMoreOption(
                              index,
                              props.pageIndex,
                              props.questionIndex
                            )
                          }
                        >
                          <CloseSVG />
                        </span>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Card>
              </Col>
              {getValue(
                props,
                `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
                ''
              ) ? (
                <Col md="1">
                  <>
                    <Card className="icon-text-more-close mr-0 pl-0 pr-0 mb-3">
                      <CardBody className="plus-minus-card-p">
                        <Input
                          className="text-center"
                          type="number"
                          value={
                            getValue(item, 'score', 0)
                              ? getValue(item, 'score', 0)
                              : 0
                          }
                          name="score"
                          onChange={(e) =>
                            props.handleScoreInputBoxEdit(
                              index,
                              e,
                              props.setQuestionsInfo,
                              props.pageIndex,
                              props.questionIndex
                            )
                          }
                        />
                      </CardBody>
                      {index !== 0 ? (
                        <span
                          className="close-icon"
                          onClick={() =>
                            props.removeMoreOption(
                              index,
                              props.pageIndex,
                              props.questionIndex
                            )
                          }
                        >
                          <CloseSVG />
                        </span>
                      ) : (
                        ''
                      )}
                    </Card>
                  </>
                </Col>
              ) : (
                ''
              )}
            </Row>
            {getValue(props, 'surveyType', '') === 'ASSESSMENT' &&
              (item.correct ? (
                <Button
                  color="nothing thumb-icon"
                  className="active"
                  onClick={() => {
                    props.handleAssessmentCorrectAnswer(
                      index,
                      !item.correct,
                      props.setQuestionsInfo,
                      props.pageIndex,
                      props.questionIndex,
                      'SINGLE_CHOICE'
                    );
                  }}
                >
                  <ThumbSVG />
                </Button>
              ) : (
                <Button
                  color="nothing thumb-icon"
                  onClick={() => {
                    props.handleAssessmentCorrectAnswer(
                      index,
                      !item.correct,
                      props.setQuestionsInfo,
                      props.pageIndex,
                      props.questionIndex,
                      'SINGLE_CHOICE'
                    );
                  }}
                >
                  <ThumbSVG />
                </Button>
              ))}
          </div>
        );
      })}
      <div className="my-3">
        <Button
          color="nothing"
          className="blue-text-svg btn-fs-16"
          onClick={() =>
            props.addMoreOption(
              {
                text: '',
                score: 0,
                correct: false,
              },
              props.pageIndex,
              props.questionIndex
            )
          }
        >
          <AddSVG /> Add more
        </Button>
      </div>
      <h5>Advance Options:</h5>

      <AdvancedOptions
        surveyType={props.surveyType}
        handleChangeQuestionAdvancedOptions={
          props.handleChangeQuestionAdvancedOptions
        }
        questionIndex={props.questionIndex}
        pageIndex={props.pageIndex}
        setQuestionsInfo={props.setQuestionsInfo}
        handleChange={props.handleChange}
        questionInfo={props.questionInfo}
      />
    </div>
  );
};

export default SingleChoice;
