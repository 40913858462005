import React from 'react';

const Smiley9SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <defs></defs>
      <g transform="translate(0.028 0.028)">
        <circle
          class="a"
          style={{ fill: '#feca2e' }}
          cx="18"
          cy="18"
          r="18"
          transform="translate(-0.028 -0.028)"
        />
        <path
          class="b"
          style={{ fill: '#6d4c41' }}
          d="M118.265,224.258a8.693,8.693,0,0,1-12.9,0A.781.781,0,0,0,104.2,225.3a10.256,10.256,0,0,0,15.229,0,.781.781,0,0,0-1.164-1.042Z"
          transform="translate(-93.842 -202.119)"
        />
        <path
          class="b"
          style={{ fill: '#6d4c41' }}
          d="M17.972,0A17.972,17.972,0,1,0,35.945,17.972,17.993,17.993,0,0,0,17.972,0Zm0,34.382a16.41,16.41,0,1,1,16.41-16.41A16.428,16.428,0,0,1,17.972,34.382Z"
        />
        <path
          class="b"
          style={{ fill: '#6d4c41' }}
          d="M87.808,115.9a.782.782,0,0,0-.432-.7l-6.251-3.126a.782.782,0,0,0-.7,1.4l4.854,2.427-4.853,2.426a.782.782,0,0,0,.7,1.4l6.251-3.126A.782.782,0,0,0,87.808,115.9Z"
          transform="translate(-72.18 -101.051)"
        />
        <path
          class="b"
          style={{ fill: '#6d4c41' }}
          d="M215.732,112.429a.781.781,0,0,0-1.049-.349l-6.251,3.126a.781.781,0,0,0,0,1.4l6.251,3.126a.782.782,0,0,0,.7-1.4l-4.854-2.426,4.853-2.426A.782.782,0,0,0,215.732,112.429Z"
          transform="translate(-187.683 -101.057)"
        />
      </g>
    </svg>
  </>
);
export default Smiley9SVG;
