import React, { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Label } from 'reactstrap';
import BackSVG from '../components/svg/back-svg';
import { getValue } from '../core/lodash';
import {
  getEmpId,
  getSurveyBasedOnToken,
  getTakeSurvey,
  getTakeSurvey360,
  getTakeSurveyToken,
} from '../core/services/surveyService';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import LoaderCommon from '../components/common/loader-common';
import axios from 'axios';
import { config } from '../config';

interface Props {
  type: string;
  surveyUrl: string;
  surveyLoading: boolean;
  errorMessage: string;
  questionsInfo: any;
  check: boolean;
  setChecked: any;
  handleChangeStatus: () => void;
}
const HRPolicy: FunctionComponent<Props> = (props) => {
  // const [questionsInfo, setQuestionsInfo] = React.useState({});
  // const [check, setChecked] = useState(true);
  // const [email, setEmail] = useState('');
  // const [surveyToken, setSurveyToken] = useState('');
  // const [surveyId, setSurveyId] = React.useState(
  //   getValue(props, 'match.params.surveyId', '')
  // );
  // const [type] = React.useState(getValue(props, 'match.params.type', ''));

  // const [errorMessage, setErrorMessage] = useState('');
  // const [surveyLoading, setSuveyLoading] = useState(false);

  // React.useEffect(() => {
  //   // get360Survey();
  //   Cookies.remove('userSurveyId');
  //   sessionStorage.removeItem('maxPageNo');
  //   getToken();
  // }, []);

  // const getToken = async () => {
  //   setSuveyLoading(true);
  //   // let resp = await getTakeSurveyToken(
  //   //   getValue(props, 'match.params.type', ''),
  //   //   getValue(props, 'match.params.surveyUrl', '')
  //   // );
  //   axios
  //     .get(
  //       `${config.API_URL}survey-collector/token/${getValue(
  //         props,
  //         'match.params.type',
  //         ''
  //       )}/${getValue(props, 'match.params.surveyUrl', '')}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.access_token ? localStorage.access_token : ''
  //             }`,
  //         },
  //       }
  //     )
  //     .then(async (resp) => {
  //       if (resp) {
  //         setSuveyLoading(false);
  //         setSurveyToken(getValue(resp, 'data.data.surveyToken', ''));
  //         if (getValue(resp, 'data.data.surveyToken', '')) {
  //           let resp1 = await getSurveyBasedOnToken(
  //             getValue(props, 'match.params.surveyUrl', ''),
  //             getValue(resp, 'data.data.surveyToken', '')
  //           );
  //           if (resp1) {
  //             setSuveyLoading(false);
  //             setQuestionsInfo(getValue(resp1, 'data.data', ''));
  //           }
  //         } else {
  //           setSuveyLoading(false);
  //           window.location.href = '/home';
  //         }
  //       } else {
  //         setSuveyLoading(false);
  //       }
  //     })
  //     .catch((error) => {
  //       if (getValue(error, `response.data.statusCode`, '') === 401) {
  //         window.location.href = `${config.API_URL}auth/sso/stohrm/login?relay_state=${window.location.href}`;
  //       } else {
  //         setSuveyLoading(false);
  //         toast.error(
  //           getValue(error, `response.data.message`, 'Software updated')
  //           , { className: 'red-circle' }
  //         );
  //         setErrorMessage(
  //           getValue(error, `response.data.message`, 'Survey Closed')
  //         );
  //       }
  //     });
  // };

  const {
    surveyLoading,
    questionsInfo,
    type,
    setChecked,
    check,
    errorMessage,
    handleChangeStatus,
  } = props;

  return (
    <>
      <div className="page-layout page-layout-full">
        {!surveyLoading ? (
          <>
            {getValue(questionsInfo, 'id', '') ? (
              <>
                <div className="create-survey-tabs">
                  <div className="basic-tabs pt-1">
                    <div
                      className="logo-box"
                      style={{
                        backgroundImage: `url(${getValue(
                          questionsInfo,
                          'settings.headerLogoUrl',
                          ''
                        )})`,
                      }}
                    ></div>
                    <h5>
                      {getValue(questionsInfo, 'survey360Name', '')
                        ? getValue(questionsInfo, 'name', '') +
                          ' ' +
                          'for' +
                          ' ' +
                          getValue(questionsInfo, 'survey360Name', '')
                        : getValue(questionsInfo, 'name', '')}
                    </h5>
                  </div>
                  {type !== 'public' && (
                    <div className="back-btn">
                      <NavLink to="/home">
                        <Button color="nothing">
                          <BackSVG />
                        </Button>
                      </NavLink>
                    </div>
                  )}
                </div>
                <div className="page-height page-padding">
                  <Col lg={{ size: 10, offset: 1 }}>
                    {/* {getValue(
                          questionsInfo,
                          'settings.termsConditionsDescription',
                          ''
                        ) && ( */}
                    <>
                      <h6>Introduction</h6>
                      <Card className="mb-3">
                        <CardBody>
                          {/* <p>HR Policy survey 2021</p> */}
                          <p
                            className="abtcnt"
                            // style={{ whiteSpace: 'pre-line' }}
                            dangerouslySetInnerHTML={{
                              __html: getValue(
                                questionsInfo,
                                'settings.introductionPageDescription',
                                ''
                              ),
                            }}
                          />
                        </CardBody>
                      </Card>
                    </>
                    {/* )} */}
                    {/* {getValue(
              questionsInfo,
              'settings.termsConditionsDescription',
              ''
            ) && ( */}
                    <>
                      <h6>Terms of Service</h6>
                      <Card className="mb-3">
                        <CardBody>
                          <p
                            className="abtcnt"
                            // style={{ whiteSpace: 'pre-line' }}
                            dangerouslySetInnerHTML={{
                              __html: getValue(
                                questionsInfo,
                                'settings.termsConditionsDescription',
                                ''
                              ),
                            }}
                          />
                        </CardBody>
                      </Card>
                    </>
                    {/* )} */}
                    <div className="">
                      {/* {getValue(
              questionsInfo,
              'settings.termsConditionsDescription',
              ''
            ) || getValue(
              questionsInfo,
              'settings.termsConditionsDescription',
              ''
            ) && ( */}
                      <Label className="checkbox-container my-4">
                        I agree to the terms of service.
                        <input
                          type="checkbox"
                          onChange={() => setChecked(!check)}
                          checked={check}
                        />
                        <span className="checkmark"></span>
                      </Label>
                      {/* )} */}
                      {/* <NavLink
                        to={`/take-surveys/${getValue(
                          props,
                          'match.params.type',
                          ''
                        )}/${getValue(props, 'match.params.surveyUrl', '')}`}
                      > */}
                      {check ? (
                        getValue(questionsInfo, 'id', '') ? (
                          <Button
                            color="blue"
                            style={{ textAlign: 'center' }}
                            onClick={handleChangeStatus}
                          >
                            Start Survey
                          </Button>
                        ) : (
                          <Button
                            color="blue"
                            style={{ textAlign: 'center' }}
                            disabled
                          >
                            Start Survey
                          </Button>
                        )
                      ) : (
                        <Button color="blue" disabled>
                          Start Survey
                        </Button>
                      )}
                      {/* </NavLink> */}
                    </div>
                  </Col>
                </div>
              </>
            ) : (
              <div className="page-height">
                <div className="page-padding">
                  <div className="text-center mt-5">
                    <div className="mt-4 mb-2">
                      <img src="/images/empty.PNG" alt="" />
                    </div>
                    <h4 className="mb-2">
                      {errorMessage ? errorMessage : 'Survey Closed'}
                    </h4>
                    {type !== 'public' && (
                      <NavLink to="/home">
                        <Button color="blue">Home</Button>
                      </NavLink>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="text-center my-3">
            <LoaderCommon />
          </div>
        )}
      </div>
    </>
  );
};
export default HRPolicy;
