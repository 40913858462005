import * as React from 'react';
import { Input } from 'reactstrap';
import { getValue } from '../../core/lodash';
import CommentOtherOption from './commentsOtherOption';

interface Props {
  options: any;
  questions: any;
}
const Dropdown: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <Input type="select">
        {getValue(props, 'options', []).map((opt) => {
          let a = getValue(props, 'questions.response', []).filter(
            (ids) => ids === opt.id
          );
          console.log(props.questions)
          if (a.length !== 0) {
            return (
              <>
                <option selected={getValue(a[0], 'id', '') === opt.id} >
                  {getValue(opt, 'text', '')}
                </option>
              </>
            );
          } else {
            return (
              <>
                <option disabled selected={opt[0]}>
                  {getValue(opt, 'text', '')}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {opt.correct && "Correct Answer"}

                </option>
              </>
            );
          }
        })}
      </Input>
      <CommentOtherOption
        questions={props.questions}
      />
      {/* {getValue(props, 'questions.otherOptionEnabled', '') ? (
        <>
          <div className="mb-2">
            <Label className="radio-container d-inline-block">
              {getValue(props, 'questions.otherOptionMsg', '')}
              <input type="checkbox" />
              <span className="checkmark"></span>
            </Label>
          </div>
          <div className="pl-2 ml-4">
            <div className="form-group">
              <Input type="text" placeholder="Enter Text here" disabled />
            </div>
          </div>
        </>
      ) : (
        ''
      )} */}
      {/* {getValue(props, 'questions.commentEnabled', '') ? (
        <div className="form-group">
          <Label>Any Additonal Comments?</Label>
          <Input
            type="textarea"
            rows="3"
            placeholder="Enter Text here"
            disabled
          />
        </div>
      ) : (
        ''
      )} */}
    </>
  );
};
export default Dropdown;
