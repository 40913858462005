// import ProfileDropdown from "./profileDropdown";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import RadioSVG from '../svg/radio-svg';
import CheckboxSVG from '../svg/checkbox-svg';
import WarningSVG from '../svg/warning-svg';
import DropdownSVG from '../svg/dropdown-svg';
import ShortSVG from '../svg/short-svg';
import LongSVG from '../svg/long-svg';
import TimeSVG from '../svg/time-svg';
import EmailSVG from '../svg/email-svg';
import NumberSVG from '../svg/number-svg';
import GridSVG from '../svg/grid-svg';
import NameSVG from '../svg/name-svg';
import CheckboxGridSVG from '../svg/checkbox-grid-svg';
import CalendarSVG from '../svg/calendar-svg';
import RatingScaleSVG from '../svg/rating-scale-svg';
import { useState } from 'react';
import { QuestionType } from '../constants/questionType';
import StarSVG from 'components/svg/star-svg';
import UploadSVG from 'components/svg/upload-svg';

const DropdownList = (props) => {
  const [type] = useState(QuestionType);

  const getType = (answer) => {
    let name = type.filter((item) => item.name === answer);
    return name[0].value;
  };
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="nothing btn-fs-16" caret>
        {props.type === 'SINGLE_CHOICE' ? (
          <RadioSVG />
        ) : props.type === 'CHECK_BOX' ? (
          <CheckboxSVG />
        ) : props.type === 'DROPDOWN' ? (
          <DropdownSVG />
        ) : props.type === 'SHORT_ANSWER' ? (
          <ShortSVG />
        ) : props.type === 'LONG_ANSWER' ? (
          <LongSVG />
        ) : props.type === 'RATING_SCALE' ? (
          <RatingScaleSVG />
        ) : props.type === 'STAR_RATING' ? (
          <StarSVG />
        ) : props.type === 'MATRIX_RADIO_BOX' ? (
          <GridSVG />
        ) : props.type === 'MATRIX_CHECK_BOX' ? (
          <CheckboxGridSVG />
        ) : props.type === 'DATE' ? (
          <CalendarSVG />
        ) : props.type === 'TIME' ? (
          <TimeSVG />
        ) : props.type === 'FULL_NAME' ? (
          <NameSVG />
        ) : props.type === 'EMAIL_TEXTBOX' ? (
          <EmailSVG />
        ) : props.type === 'NUMERIC_TEXTBOX' ? (
          <NumberSVG />
        ) : props.type === 'FILE_UPLOAD' ? (
          <UploadSVG />
        ) : (
          ''
        )}{' '}
        <span className="ml-1">{getType(props.type)}</span>
      </DropdownToggle>
      <DropdownMenu className="custom-dropdown" right>
        <h6 className="">Select a question format</h6>
        <p>
          <span className="warning-ico">
            <WarningSVG />
          </span>
          Warning: Changing the question format will drop some of your question
          properties
        </p>
        {props.questionTypes.includes('MULTIPLE_CHOICE') && (
          <DropdownItem
            className={
              props.type === 'SINGLE_CHOICE' ? 'dropdown_selected' : ''
            }
            onClick={() =>
              props.handleQuestionType(
                'SINGLE_CHOICE',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <RadioSVG /> Single Choice
          </DropdownItem>
        )}
        {props.questionTypes.includes('CHECK_BOX') && (
          <DropdownItem
            className={props.type === 'CHECK_BOX' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'CHECK_BOX',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <CheckboxSVG /> Checkboxes
          </DropdownItem>
        )}
        {props.questionTypes.includes('DROPDOWN') && (
          <DropdownItem
            className={props.type === 'DROPDOWN' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'DROPDOWN',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <DropdownSVG /> Dropdown
          </DropdownItem>
        )}
        {props.questionTypes.includes('SHORT_ANSWER') && (
          <DropdownItem
            className={props.type === 'SHORT_ANSWER' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'SHORT_ANSWER',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <ShortSVG /> Short Answer Question
          </DropdownItem>
        )}
        {props.questionTypes.includes('LONG_ANSWER') && (
          <DropdownItem
            className={props.type === 'LONG_ANSWER' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'LONG_ANSWER',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <LongSVG /> Long Answer Question
          </DropdownItem>
        )}
        {props.questionTypes.includes('RATING_SCALE') && (
          <DropdownItem
            className={props.type === 'RATING_SCALE' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'RATING_SCALE',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <RatingScaleSVG /> Rating Scale
          </DropdownItem>
        )}
        {props.questionTypes.includes('STAR_RATING') && (
          <DropdownItem
            className={props.type === 'STAR_RATING' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'STAR_RATING',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <StarSVG /> Star Rating
          </DropdownItem>
        )}
        {props.questionTypes.includes('MATRIX_RADIO_BOX') && (
          <DropdownItem
            className={
              props.type === 'MATRIX_RADIO_BOX' ? 'dropdown_selected' : ''
            }
            onClick={() =>
              props.handleQuestionType(
                'MATRIX_RADIO_BOX',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <GridSVG /> Single Choice Grid
          </DropdownItem>
        )}
        {props.questionTypes.includes('MATRIX_CHECK_BOX') && (
          <DropdownItem
            className={
              props.type === 'MATRIX_CHECK_BOX' ? 'dropdown_selected' : ''
            }
            onClick={() =>
              props.handleQuestionType(
                'MATRIX_CHECK_BOX',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <CheckboxGridSVG /> Checkbox Grid
          </DropdownItem>
        )}
        {props.questionTypes.includes('DATE') && (
          <DropdownItem
            className={props.type === 'DATE' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'DATE',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <CalendarSVG /> Date
          </DropdownItem>
        )}
        {props.questionTypes.includes('TIME') && (
          <DropdownItem
            className={props.type === 'TIME' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'TIME',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <TimeSVG /> Time
          </DropdownItem>
        )}
        {props.questionTypes.includes('FULL_NAME') && (
          <DropdownItem
            className={props.type === 'FULL_NAME' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'FULL_NAME',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <NameSVG /> Full Name
          </DropdownItem>
        )}
        {props.questionTypes.includes('EMAIL_TEXTBOX') && (
          <DropdownItem
            className={
              props.type === 'EMAIL_TEXTBOX' ? 'dropdown_selected' : ''
            }
            onClick={() =>
              props.handleQuestionType(
                'EMAIL_TEXTBOX',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <EmailSVG /> Email
          </DropdownItem>
        )}
        {props.questionTypes.includes('NUMERIC_TEXTBOX') && (
          <DropdownItem
            className={
              props.type === 'NUMERIC_TEXTBOX' ? 'dropdown_selected' : ''
            }
            onClick={() =>
              props.handleQuestionType(
                'NUMERIC_TEXTBOX',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <NumberSVG /> Number
          </DropdownItem>
        )}
        {/* <DropdownItem
          onClick={() => props.handleQuestionType('Contact Information')}
        >
          <CheckboxSVG /> Contact Information
        </DropdownItem> */}
        {props.questionTypes.includes('FILE_UPLOAD') && (
          <DropdownItem
            className={props.type === 'FILE_UPLOAD' ? 'dropdown_selected' : ''}
            onClick={() =>
              props.handleQuestionType(
                'FILE_UPLOAD',
                props.pageIndex,
                props.questionIndex,
                'type'
              )
            }
          >
            <UploadSVG /> File Upload
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
export default DropdownList;
