import { getValue } from 'core/lodash';
import * as React from 'react';
import { Col, Row, Label, Input } from 'reactstrap';
import AdvancedOptions from './AdvancedOptions';

interface IFullNameProps {
  handleFullName: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  handleFullNameCheckbox: (
    status: boolean,
    pageIndex: number,
    questionIndex: number,
    optioIndex: number
  ) => void;
  surveyType: string;
}

const FullName: React.FunctionComponent<IFullNameProps> = (props) => {
  return (
    <>
      <Row>
        <Col md="3"></Col>
        <Col md="4">
          <h6 className="text-red">Question Label</h6>
        </Col>
        <Col md="4">
          <h6 className="text-red">Make this question mandatory</h6>
        </Col>
      </Row>
      <Row>
        <Col md="3" className="d-flex align-items-center">
          <h6>First Name</h6>
        </Col>
        <Col md="4">
          <div className="form-group">
            <Input
              type="text"
              placeholder="First Name"
              onChange={(e) =>
                props.handleFullName(e, props.pageIndex, props.questionIndex, 0)
              }
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${
                  props.questionIndex
                }].row[${0}].columns[${0}].question.text`,
                ''
              )?getValue(
                props.questionInfo[props.pageIndex],
                `questions[${
                  props.questionIndex
                }].row[${0}].columns[${0}].question.text`,
                ''
              ):""}
            />
          </div>
        </Col>
        <Col md="4" className="d-flex align-items-center">
          <Label className="checkbox-container mb-3">
            <input
              type="checkbox"
              onChange={() => {
                props.handleFullNameCheckbox(
                  !getValue(
                    props.questionInfo[props.pageIndex],
                    `questions[${
                      props.questionIndex
                    }].row[${0}].columns[${0}].question.mandatoryEnabled`,
                    false
                  ),
                  props.pageIndex,
                  props.questionIndex,
                  0
                );
              }}
              checked={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${
                  props.questionIndex
                }].row[${0}].columns[${0}].question.mandatoryEnabled`,
                ''
              )}
            />
            <span className="checkmark"></span>
          </Label>
        </Col>
      </Row>
      <Row>
        <Col md="3" className="d-flex align-items-center">
          <h6>Last Name</h6>
        </Col>
        <Col md="4">
          <div className="form-group">
            <Input
              type="text"
              placeholder="Last Name"
              onChange={(e) =>
                props.handleFullName(e, props.pageIndex, props.questionIndex, 1)
              }
              value={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${
                  props.questionIndex
                }].row[${0}].columns[${1}].question.text`,
                ''
              )?getValue(
                props.questionInfo[props.pageIndex],
                `questions[${
                  props.questionIndex
                }].row[${0}].columns[${1}].question.text`,
                ''
              ):""}
            />
          </div>
        </Col>
        <Col md="4" className="d-flex align-items-center">
          <Label className="checkbox-container mb-3">
            <input
              type="checkbox"
              onChange={() => {
                props.handleFullNameCheckbox(
                  !getValue(
                    props.questionInfo[props.pageIndex],
                    `questions[${
                      props.questionIndex
                    }].row[${0}].columns[${1}].question.mandatoryEnabled`,
                    false
                  ),
                  props.pageIndex,
                  props.questionIndex,
                  1
                );
              }}
              checked={getValue(
                props.questionInfo[props.pageIndex],
                `questions[${
                  props.questionIndex
                }].row[${0}].columns[${1}].question.mandatoryEnabled`,
                false
              )}
            />
            <span className="checkmark"></span>
          </Label>
        </Col>
      </Row>
      <div>
        <h5>Advance Options:</h5>
        <AdvancedOptions
              surveyType={props.surveyType}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          questionIndex={props.questionIndex}
          pageIndex={props.pageIndex}
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChange}
          questionInfo={props.questionInfo}
        />
      </div>
    </>
  );
};

export default FullName;
