import React, { useEffect, useState } from 'react';
import { getValue, setValue } from '../../core/lodash';
import FormHeader from './common/FormHeader';
import { toast } from 'react-toastify';
import BackSVG from '../../components/svg/back-svg';
import { Button } from 'reactstrap';
import Cookies from 'js-cookie';
import { NavLink } from 'react-router-dom';
import ConfirmSurveyComponent from './ConfirmSurveyComponent';
import {
  getSurvey,
  publishNowD,
  publishNowDSave,
} from '../../core/services/surveyService';
import { useHistory } from 'react-router-dom';

interface ISurveyPublishProps { }
export interface IpublishDate {
  endDateEnabled: boolean;
  endDate: Date;
  responseLimitEnabled: boolean;
  responseLimit: number;
  timeLimitEnabled: boolean;
  timeLimit: string;
}

const SurveyPublish: React.FunctionComponent<ISurveyPublishProps> = (props) => {
  const [surveyDetails, updateSurvey] = useState({});
  const surveyId = getValue(props, 'match.params.id', '');
  const surveyQuestionType = getValue(props, 'match.params.type', '');
  const surveyTemplate = getValue(props, 'match.params.templates', '');


  useEffect(() => {
    getSurveyList();
  }, []);
  const [publishNow, setPublishNow] = useState<IpublishDate>({
    endDateEnabled: false,
    endDate: new Date(),
    responseLimitEnabled: false,
    responseLimit: 0,
    timeLimitEnabled: false,
    timeLimit: '',
  });
  const [publishLoading, setPublishLoading] = useState(false)
  const tomorrow = () => {
    let myDate = new Date();
    //add a day to the date
    myDate.setDate(myDate.getDate() + 1);
    return myDate;
  };

  const getSurveyList = async () => {
    try {
      if (surveyId) {
        let resp = await getSurvey(surveyId);
        if (resp) {
          updateSurvey(getValue(resp, 'data', {}));
          setPublishNow({
            ...publishNow,
            endDateEnabled: getValue(
              resp,
              'data.settings.endDateEnabled',
              false
            ),
            endDate: getValue(resp, 'data.settings.endDate', '')
              ? new Date(getValue(resp, 'data.settings.endDate', ''))
              : tomorrow(),

            // new Date(new Date()).setDate(
            //   new Date(new Date()).getDate() + 1
            // )
            // getValue(resp, 'data.settings.endDate',''),
            // endDate:Date("2021-04-22T18:30:00.000Z"),
            responseLimitEnabled: getValue(
              resp,
              'data.settings.responseLimitEnabled',
              ''
            ),
            responseLimit: getValue(
              resp,
              'data.settings.responseLimit',
              ''
            ),
            timeLimitEnabled: getValue(
              resp,
              'data.settings.timeLimitEnabled',
              ''
            ),
            timeLimit: getValue(resp, 'data.settings.timeLimit', ''),
          });
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  const [isLeftMenu, manageMenu] = useState(false);

  const history = useHistory();
  const onChangePublishDate = (date, name) => {
    setValue(publishNow, `[${name}]`, date);
    setPublishNow({ ...publishNow });
  };

  const handlePublishNow = async () => {


    try {
      if (getValue(surveyDetails, `pages[${0}].questions.length`, 0) === 0) {
        toast.error("Survey questions should not be empty"
        ,{className:'red-circle'}
        )
      } else {
        if (
          (publishNow.endDateEnabled && publishNow.endDate) ||
          (publishNow.responseLimitEnabled && publishNow.responseLimit)
        ) {
          // let req = {
          //   endDateEnabled: publishNow.endDateEnabled,
          //   // endDate:publishNow.endDate?moment(publishNow.endDate).format("DD-MM-YYYY"):"",
          //   endDate: publishNow.endDate ? publishNow.endDate : '',
          //   responseLimitEnabled: publishNow.responseLimitEnabled,
          //   responseLimit: publishNow.responseLimit,
          //   timeLimitEnabled: publishNow.timeLimitEnabled,
          //   timeLimit: publishNow.timeLimit ? publishNow.timeLimit : '',
          // };
          setPublishLoading(true)
          let resp = await publishNowD(surveyId);
          if (resp) {
            setPublishLoading(false)
            history.push(
              `/publish/${getValue(resp, 'data.publicUrl', 'q32v-tclk-vp1f-ip5n')}`
            );
            toast.success('Published successfully'
            ,{className:'green-circle'}
            );
          } else {
            setPublishLoading(false)
          }
        } else {
          toast.error('End date or Response limit should be required'
          ,{className:'red-circle'}
          );
        }
      }
    } catch (error) {
      setPublishLoading(false)
    }
  };
  const [loading, setLoading] = useState(false)
  const handleSavePublishNow = async () => {
    if (getValue(surveyDetails, `pages[${0}].questions.length`, 0) === 0) {
      toast.error("Survey questions should not be empty"
      ,{className:'red-circle'}
      )
    } else {
      if (
        (publishNow.endDateEnabled && publishNow.endDate) ||
        (publishNow.responseLimitEnabled && publishNow.responseLimit)
      ) {
        try {
          setLoading(true)
          let req = {
            endDateEnabled: publishNow.endDateEnabled,
            // endDate:publishNow.endDate?moment(publishNow.endDate).format("DD-MM-YYYY"):"",
            endDate: publishNow.endDate ? publishNow.endDate : '',
            responseLimitEnabled: publishNow.responseLimitEnabled,
            responseLimit: publishNow.responseLimit,
            timeLimitEnabled: publishNow.timeLimitEnabled,
            timeLimit: publishNow.timeLimit ? publishNow.timeLimit : '',
          };
          let resp = await publishNowDSave(surveyId, req);
          if (resp) {
            // history.push(
            //   `/publish/${getValue(resp, 'data.publicUrl', 'q32v-tclk-vp1f-ip5n')}`
            // );
            setLoading(false)
            toast.success('Saved successfully'
            ,{className:'green-circle'}
            );
            getSurveyList();
          } else {
            setLoading(false);
            getSurveyList();
          }
        } catch (error) {
          setLoading(false)
        }
      } else {
        // toast.error('End date or Response limit should be required');
      }
    }
  };
  const handleChange = (evt, path, state) => {
    const { name, value } = evt.target;
    path({
      ...state,
      [name]: value,
    });
  };

  const handleChangeBooleanFourParams = (name, value, path, state) => {
    path({
      ...state,
      [name]: value,
    });
  };

  return (
    <div className="page-layout page-layout-full">
      <div className="create-survey-tabs">
        <FormHeader
          surveyDetails={surveyDetails} 
          surveyId={surveyId} 
          surveyQuestionType={surveyQuestionType}
          surveyTemplate={surveyTemplate}
          />
        <div className="back-btn">
          {!Cookies.get('template') ? (
            <NavLink to="/home">
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          ) : (
            <NavLink to="/templates">
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          )}
        </div>
      </div>
      <div className="page-height">
        <ConfirmSurveyComponent
          surveyType={getValue(surveyDetails, 'type', '')}
          handleChange={handleChange}
          handleChangeBooleanFourParams={handleChangeBooleanFourParams}
          onChangePublishDate={onChangePublishDate}
          handlePublishNow={handlePublishNow}
          publishNow={publishNow}
          setPublishNow={setPublishNow}
          surveyDetails={surveyDetails}
          handleSavePublishNow={handleSavePublishNow}
          loading={loading}
          publishLoading={publishLoading}
          surveyQuestionType={surveyQuestionType}
        />
      </div>
    </div>
  );
};

export default SurveyPublish;
