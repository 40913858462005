import axios from 'axios';
import { getValue } from 'core/lodash';
import {
  get,
  patch,
  post,
  Delete,
  put,
  postFileUpload,
  patchFileUpload,
  getOnlyToken,
  postOnlyToken,
} from './helpers/http-handler';
import { toast } from 'react-toastify';
import { config } from '../../config';

interface surveyResponse {
  survey: object;
}

export async function fetchLyrics(
  survey: object
): Promise<surveyResponse | any> {
  try {
    return await axios.get(`https://api.lyrics.ovh/v1/${survey}`);
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// *********** delete survey *********** //

export const deleteSurvey = (surveyId: string) =>
  Delete(`${config.API_URL}survey-builder/surveys/${surveyId}`);

// *********** upload api *********** //

export const uploadFile = (formatData: any) =>
  postFileUpload(
    `${config.API_URL}file-uploader/upload/public/image/single`,
    formatData
  );

export const uploadFileHeader = (surveyId: string, payload: any) =>
  patch(
    `${config.API_URL}survey-builder/surveys/${surveyId}/settings/header`,
    payload
  );

// export async function uploadFile(
//   formatData: any
// ): Promise<surveyResponse | any> {
//   try {
//     return await axios.post(
//       `${config.API_URL}file-uploader/upload/public/image/single`,
//       formatData
//     );
//   } catch (error) {
//     if (getValue(
//       error,
//       `response.data.statusCode`,
//       ''
//     ) === 401) {
//       window.location.href = `${config.API_URL}auth/sso/stohrm/login?relay_state=${window.location.href}&tenant_id=${localStorage.getItem('tenant_id')}`
//     } else {
//       toast.error(
//         getValue(
//           error,
//           `response.data.message`,
//           'Software update in progress'
//         )
//       );
//     }
//   }
// }

export async function uploadFileRemove(
  url: string
): Promise<surveyResponse | any> {
  try {
    return await axios.post(`${config.API_URL}microsites/remove-image/${url}`);
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// export async function uploadFileHeader(
//   surveyId: string,
//   payload: any
// ): Promise<surveyResponse | any> {
//   try {
//     return await axios.patch(
//       `${config.API_URL}survey-builder/surveys/${surveyId}/settings/header`,
//       payload
//     );
//   } catch (error) {
//     if (getValue(
//       error,
//       `response.data.statusCode`,
//       ''
//     ) === 401) {
//       window.location.href = `${config.API_URL}auth/sso/stohrm/login?relay_state=${window.location.href}&tenant_id=${localStorage.getItem('tenant_id')}`
//     } else {
//       toast.error(
//         getValue(
//           error,
//           `response.data.message`,
//           'Software update in progress'
//         )
//       );
//     }

//   }
// }

// *********** question bank *********** //

export const questionBankSearch = (skip: number, limit: number, text: string) =>
  get(
    `survey-builder/search-question?skip=${skip}&limit=${limit}&text=${text}`
  );

export const getSpecificQuestion = (
  surveyId: string,
  pageID: string,
  questionId: string
) =>
  get(
    `${config.API_URL}survey-builder/surveys/${surveyId}/pages/${pageID}/questions/${questionId}`
  );

// *********** survey *********** //

export const assesmentTypes = () =>
  get(`${config.API_URL}survey-builder/assesment/question-types`);
export const survey360Types = () =>
  get(`${config.API_URL}survey-builder/survey360/question-types`);

export const surveysTypes = () =>
  get(`${config.API_URL}survey-builder/surveys/question-types`);

export const createSurvey = (survey: object) =>
  post(`${config.API_URL}survey-builder/surveys`, survey);
export const createSurveyFromTemplate = (templateSurveyId: string) =>
  post(
    `${config.API_URL}survey-builder/surveys/from-template/${templateSurveyId}`
  );

export const createSurveyFromExistingSurvey = (surveyId: string) =>
  post(`${config.API_URL}survey-builder/surveys/from-existing/${surveyId}`);

export const getSurvey = (surveyId: string) =>
  get(`${config.API_URL}survey-builder/surveys/${surveyId}`);

export const getAssessmentTotalScore = (surveyId: string) =>
  get(`${config.API_URL}survey-builder/surveys/${surveyId}/total-score`);
export const updateAssessmentPassScore = (surveyId: string, payload: any) =>
  patch(
    `${config.API_URL}survey-builder/surveys/${surveyId}/pass-score`,
    payload
  );
export const updateSurveyName = (surveyId: string, payload: any) =>
  put(`${config.API_URL}survey-builder/surveys/${surveyId}`, payload);

export const endSurvey = (surveyId: string) =>
  postOnlyToken(
    `${config.API_URL}survey-collector/surveys/published/${surveyId}/close`
  );

// ******* survey/settings ********* //

export const settingsIntroduction = (surveyId: string, payload: object) =>
  patch(
    `${config.API_URL}survey-builder/surveys/${surveyId}/settings/introduction-page`,
    payload
  );

export const settingsTermsCondition = (surveyId: string, payload: object) =>
  patch(
    `${config.API_URL}survey-builder/surveys/${surveyId}/settings/terms-conditions`,
    payload
  );

// ******* survey/email ********* //

export const pickAudianceEmails = (surveyId: string, payload: any) =>
  post(
    `${config.API_URL}survey-builder/surveys/${surveyId}/invite-participants`,
    payload
  );

export const enableAddParticipants = (surveyId: string, payload: any) =>
  put(
    `${config.API_URL}survey-builder/surveys/${surveyId}/enable-add-participant`,
    payload
  );

export const getAudianceEmails = (surveyId: string) =>
  get(
    `${config.API_URL}survey-builder/surveys/${surveyId}/invite-participants`
  );

// ******* survey/360 ********* //

export const get360SurveyEmails = (surveyId: string) =>
  get(`${config.API_URL}survey-builder/surveys/${surveyId}/360-participants`);

export const import360SurveyEmails = (surveyId: string, formData: any) =>
  postFileUpload(
    `${config.API_URL}survey-builder/surveys/${surveyId}/360-participants/import`,
    formData
  );

export const add360SurveyEmails = (surveyId: string, payload: any) =>
  post(
    `${config.API_URL}survey-builder/surveys/${surveyId}/360-participants`,
    payload
  );

export const add360SurveyEmailsEmployees = (
  surveyId: string,
  empId: string,
  payload: any
) =>
  put(
    `${config.API_URL}survey-builder/surveys/${surveyId}/360-participants/${empId}`,
    payload
  );

export const add360SurveyEmailsEmployeesOnPopup = (
  surveyId: string,
  participantId: string,
  payload: any
) =>
  put(
    `${config.API_URL}survey-builder/surveys/${surveyId}/360-participants/feedback-participant/${participantId}`,
    payload
  );

export const delete360SurveyEmails = (surveyId: string) =>
  Delete(
    `${config.API_URL}survey-builder/surveys/${surveyId}/360-participants`
  );

export const get360SurveyParticipantEmails = (
  surveyId: string,
  empId: string
) =>
  get(
    `${config.API_URL}survey-builder/surveys/${surveyId}/360-participants/${empId}`
  );
// ******* survey/publish ********* //

export const publishNowD = (
  surveyId: string
  // payload: any
) =>
  post(
    `${config.API_URL}survey-builder/surveys/${surveyId}/publish`
    // ,
    // payload
  );

// export const publishNowDSave = (
//   surveyId: string,
//   payload: any
// ) => put(
//   `${config.API_URL}survey-builder/surveys/${surveyId}/publish`,
//   payload
// )
export const publishNowDSave = (surveyId: string, payload: any) =>
  patch(
    `${config.API_URL}survey-builder/surveys/${surveyId}/settings/publish`,
    payload
  );

//  ********  survey page ******* //

export const createSurveyPage = (surveyId: string, payload: any) =>
  post(`${config.API_URL}survey-builder/surveys/${surveyId}/pages`, payload);

export const getSurveyPage = (surveyId: string, pageId: string) =>
  get(
    `${config.API_URL}survey-builder/surveys/${surveyId}/pages/${
      pageId ? pageId : ''
    }`
  );

export const updateSurveyPageD = (
  surveyId: string,
  pageId: string,
  payload: any
) =>
  put(
    `${config.API_URL}survey-builder/surveys/${surveyId}/pages/${pageId}`,
    payload
  );

// ***** survey questions ****** //

export const addSurveyQuestion = (
  surveyId: string,
  pageId: string,
  payload: any
) =>
  post(
    `${config.API_URL}survey-builder/surveys/${surveyId}/pages/${pageId}/questions`,
    payload
  );

export const getSurveyQuestions = (surveyId: string) =>
  get(`${config.API_URL}survey-builder/surveys/${surveyId}`);

export const updateSurveyPageQuestion = (
  surveyId: string,
  pageId: string,
  questionId: string,
  payload: any
) =>
  put(
    `${config.API_URL}survey-builder/surveys/${surveyId}/pages/${pageId}/questions/${questionId}`,
    payload
  );

export const deleteSurveyPageQuestion = (
  surveyId: string,
  pageId: string,
  questionId: string
) =>
  Delete(
    `${config.API_URL}survey-builder/surveys/${surveyId}/pages/${pageId}/questions/${questionId}`
  );

// ******* categories ******** //

export const getSurveyCategories = () =>
  get(`${config.API_URL}survey-builder/surveys/categories`);

export const getSurveyAllList = (skip: number, limit: number, text: string) =>
  get(
    `${config.API_URL}survey-builder/surveys?skip=${skip}&take=${limit}&text=${text} `
  );

export const getSurveyCollectorList = (
  skip: number,
  limit: number,
  text: string,
  sort: string
) =>
  getOnlyToken(
    `${config.API_URL}survey-collector/surveys/take-survey?skip=${skip}&take=${limit}&text=${text}&sort=${sort}`
  );

// ******* take a survey/collector ******** //

// @surya
export async function getTakeSurveyToken(
  type: string,
  surveyUrl: string,
  tenant_id: string
): Promise<surveyResponse | any> {
  try {
    return await axios.get(
      `${config.API_URL}survey-collector/surveys/${surveyUrl}/${type}/token?tenant_id=${tenant_id}`,
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.access_token ? localStorage.access_token : ''
          }`,
        },
      }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// @surya
export async function getSurveyBasedOnToken(
  surveyUrl: string,
  token: string
): Promise<surveyResponse | any> {
  try {
    return await axios.get(
      `${config.API_URL}survey-collector/surveys/${surveyUrl}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// @surya
export async function createSurveySubmission(
  surveyUrl: string,
  token: string,
  payload: any
): Promise<surveyResponse | any> {
  try {
    return await axios.post(
      `${config.API_URL}survey-collector/surveys/${surveyUrl}/response`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// @surya
export async function createFinalSubmission(
  surveyUrl: string,
  token: string
): Promise<surveyResponse | any> {
  try {
    return await axios.post(
      `${config.API_URL}survey-collector/surveys/${surveyUrl}/response/submit`,
      '',
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// @surya
export async function getSureveyResponseByResponseId(
  surveyUrl: string,
  token: string,
  responseId: string
): Promise<surveyResponse | any> {
  try {
    return await axios.get(
      `${config.API_URL}survey-collector/surveys/${surveyUrl}/response/${responseId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// @surya
export async function updateSurveySubmission(
  surveyUrl: string,
  token: string,
  payload: any,
  responseId: string
): Promise<surveyResponse | any> {
  try {
    return await axios.put(
      `${config.API_URL}survey-collector/surveys/${surveyUrl}/response/${responseId}`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// @surya
export async function getSurveyPages(
  surveyUrl: string,
  pageId: string,
  token: string
): Promise<surveyResponse | any> {
  try {
    return await axios.get(
      `${config.API_URL}survey-collector/surveys/${surveyUrl}?pageId=${pageId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

export async function createTakeSurvey(
  surveyId: string,
  payload: any,
  token: string
): Promise<surveyResponse | any> {
  try {
    return await axios.post(
      `${config.API_URL}survey-collector/surveys/${surveyId}/response`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

export const getTakeSurvey = (surveyId: string, pageId: string) =>
  get(
    `${config.API_URL}survey-collector/surveys/${surveyId}/${
      pageId ? pageId : ''
    }`
  );

export const getTakeSurvey360 = (email: string, surveyId: string) =>
  get(
    `${config.API_URL}survey-collector/surveys/employee/${email}/${surveyId}`
  );

export const getEmpId = (empId: string, id: string) =>
  get(`${config.API_URL}emp/${empId}/${id}`);

export async function getTakeSurveyWithToken(
  surveyId: string,
  pageId: string,
  token: string
): Promise<surveyResponse | any> {
  try {
    return await axios.get(
      `${config.API_URL}survey-collector/surveys/${surveyId}/${
        pageId ? pageId : ''
      }`,
      { headers: { Authorization: `Bearer ${token ? token : ''}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

export async function updateTakeSurvey(
  surveyId: string,
  responseId: string,
  payload: any,
  token: string
): Promise<surveyResponse | any> {
  try {
    return await axios.put(
      `${config.API_URL}survey-collector/surveys/${surveyId}/response/${responseId}`,
      payload,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (error) {
    if (getValue(error, `response.data.statusCode`, '') === 401) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    } else {
      toast.error(
        getValue(error, `response.data.message`, 'Software update in progress'),
        { className: 'red-circle' }
      );
    }
  }
}

// ************ publish survey ************ //

export const SurveyPreferencePublish = (surveyId: string, payload: object) =>
  put(
    `${config.API_URL}survey-builder/surveys/${surveyId}/visibility`,
    payload
  );

// ************ survey draft ************ //

// export const getDraftSurveys = (
//   skip: number,
//   limit: number,
//   text: string
// ) => get(
//   `${config.API_URL}survey-builder/surveys-draft?skip=${skip}&take=${limit}&text=${text}`
// )

export const getDraftSurveys = (
  skip: number,
  limit: number,
  text: string,
  sort: string
) =>
  getOnlyToken(
    `${config.API_URL}survey-collector/surveys/draft?skip=${skip}&take=${limit}&text=${text}&sort=${sort}`
  );

// ************ survey running ************ //

// export const getRunningSurveys = (
//   skip: number,
//   limit: number,
//   text: string
// ) => get(
//   `${config.API_URL}survey-builder/surveys-running?skip=${skip}&take=${limit}&text=${text}`
// )

export const getRunningSurveys = (
  skip: number,
  limit: number,
  text: string,
  sort: string
) =>
  getOnlyToken(
    `${config.API_URL}survey-collector/surveys/published?skip=${skip}&take=${limit}&text=${text}&sort=${sort}`
  );

export const downloadAdvancedReport = (surveyId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/export/advanced`
  );

export const downloadFeedbackReport = (
  surveyId: string,
  participantId: string
) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/${participantId}/feedback-report`
  );

export const downloadDetailReport = (surveyId: string, participantId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/${participantId}/feedback-summary/export`
  );

export const downloadAdvancedReportStandard = (surveyId: string) =>
  get(`${config.API_URL}survey-response/export/${surveyId}`);

export const downloadAdvancedReport360 = (surveyId: string) =>
  get(
    `${config.API_URL}survey-response/surveys/${surveyId}/360-participants/summary/export`
  );

export const downloadAdvancedReportRemainder = (surveyId: string) =>
  get(`${config.API_URL}survey-response/${surveyId}/360-response/reminder`);
export const downloadSimpleReport = (surveyId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/export/simple`
  );

export const downloadCombinedReport = (surveyId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/combined-report`
  );

export const downloadCombinedDetailedReport = (surveyId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/combined-detailed-report`
  );

export const downloadSimpleIndividualReport = (surveyId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/feedback-summary/export`
  );
// ************ survey completed ************ //

// export const getCompletedSurveys = (
//   skip: number,
//   limit: number,
//   text: string
// ) => get(
//   `${config.API_URL}survey-builder/surveys-completed?skip=${skip}&take=${limit}&text=${text}`
// )

export const getCompletedSurveys = (
  skip: number,
  limit: number,
  text: string,
  sort: string
) =>
  getOnlyToken(
    `${config.API_URL}survey-collector/surveys/closed?skip=${skip}&take=${limit}&text=${text}&sort=${sort}`
  );

// ************ survey response ************ //

export const getSurveyResponse = (surveyId: string, userSurveyId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/usersurvey/${userSurveyId}`
  );

export const getSurveyResponsePages = (
  surveyId: string,
  userSurveyId: string,
  pageId: string
) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/usersurvey/${userSurveyId}/${pageId}`
  );

// ************ survey summary ************ //

export const getSurveySummaryResponse = (surveyId: string) =>
  get(`${config.API_URL}survey-response/summary/${surveyId}`);

export const getSummarySurvey = (surveyId: string) =>
  get(`${config.API_URL}survey-response/survey/${surveyId}`);

export const getSummarySurveyPagination = (surveyId: string, pageId: string) =>
  get(`${config.API_URL}survey-response/survey/${surveyId}/${pageId}`);

export const getSummarySurveyGraphPagination = (
  surveyId: string,
  pageId: string
) => get(`${config.API_URL}survey-response/longSummary/${surveyId}/${pageId}`);

export const get360SummarySurvey = (surveyId: string) =>
  get(`${config.API_URL}survey-response/360-response/summary/${surveyId}`);

// @surya
export const get360SummarySurveyRawdataGrid = (
  surveyId: string,
  empId: string,
  pageId: string
) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/${empId}/feedback-summary?surveyPageId=${pageId}`
  );

export const get360SummarySurveyRawdata = (
  surveyId: string,
  empId: string,
  skip: number,
  limit: number,
  sort: string
) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/${empId}/feedback-responses?skip=${skip}&limit=${limit}&sort=${sort}`
  );

export const get360TotalSummary = (surveyId: string, empId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/${empId}/total-summary`
  );

export const get360SummarySurveyInfo = (surveyId: string, empId: string) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/particpants/${empId}/info`
  );

export const get360SummaryEmployees = (
  surveyId: string,
  skip: number,
  limit: number
) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/360-response/participants?skip=${skip}&take=${limit}`
  );

export const SummarySurveyPageNavigation = (
  surveyId: string,
  userSurveyId: string
) =>
  get(
    `${config.API_URL}survey-response/${surveyId}/usersurvey/${userSurveyId}`
  );

export const getPermission = () => get(`${config.API_URL}account/user`);

// export const getOrganization = () => get(
//   `${config.API_URL}account/organisation-structure`
// )
export const getOrganization = (surveyId: string) =>
  get(
    `${config.API_URL}survey-builder/surveys/${surveyId}/organisation-structure`
  );

export const getOrganizationParticipant = (surveyId: string) =>
  get(
    `${config.API_URL}survey-builder/surveys/${surveyId}/organisation-participants`
  );

export const updateOrganizationParticipant = (
  surveyId: string,
  payload: object
) =>
  post(
    `${config.API_URL}survey-builder/surveys/${surveyId}/organisation-participants`,
    payload
  );

// excel file upload

export const uploadExcelFiles = (surveyId: string, formData: any) =>
  postFileUpload(
    `${config.API_URL}survey-builder/surveys/${surveyId}/upload/matrix-radio-box`,
    formData
  );

export const uploadExcelFilesStandard = (surveyId: string, formData: any) =>
  postFileUpload(
    `${config.API_URL}survey-builder/surveys/${surveyId}/upload/questions`,
    formData
  );
