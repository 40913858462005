import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import Moment from 'react-moment';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import RenderAnswerPreview from '../../../components/common/RenderAnswerPreview';
import ArrowLeftSVG from '../../../components/svg/arrow-left-svg';
import ArrowRightSVG from '../../../components/svg/arrow-right-svg';
import { getValue } from '../../../core/lodash';
import ChartDecider from './chartDecider';
import ChartPreview from './type1ChartPreview';

interface ISummarySurveyQuestionProps {
    summaryInfo: any;
    options: any,
    series: any,
    toggleModal: () => void;
    option1: any;
    series1: any;
    questionsInfo: any;
    infoData: any;
    handleSurveySubmit: any;
    handleChangePage: (index: any) => void
    from: any;

}

const SummarySurveyQuestion: React.FunctionComponent<ISummarySurveyQuestionProps> = (props) => {
    const
        { summaryInfo,
            options,
            series,
            toggleModal,
            option1,
            series1,
            infoData,
            handleSurveySubmit,
            questionsInfo,
        } = props

    return (
        <div>
            {props.from !== 360 ?
                <>
                    <Row>
                        <Col md="6">
                            <h5 className="mt-3 mb-1">
                                {getValue(summaryInfo, `name`, 0)}
                            </h5>
                            <h5 className="text-green-light">
                                Status : {getValue(summaryInfo, 'status', '-')}
                            </h5>
                        </Col>
                        {/* <Col md="6">
                    <ul className="table-header-actions mt-1">
                      <li className="hover-circle">
                        <Button color="nothing">
                          <PageSVG />
                        </Button>
                      </li>
                      <li className="hover-circle">
                        <Button color="nothing">
                          <CopySVG />
                        </Button>
                      </li>
                    </ul>
                  </Col> */}
                    </Row>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md="2">
                                    <p className="mb-0">Respondents</p>
                                    <Row>
                                        <Col md="6">
                                            <h3 className="mb-0">
                                                {getValue(summaryInfo, `respondCollected`, 0)}
                                            </h3>
                                            <p className="text-green-light mb-0">
                                                <small>Collected</small>
                                            </p>
                                        </Col>
                                        <Col md="6">
                                            <h3 className="mb-0">
                                                {getValue(summaryInfo, `respondTarget`, 0)}
                                            </h3>
                                            <p className="mb-0">
                                                <small>Targeted</small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="2">
                                    <p className="mb-0">Start Date</p>
                                    <h3 className="mb-0">
                                        <Moment format="DD MMM YYYY">
                                            {getValue(summaryInfo, `launchDate`, 0)}
                                        </Moment>
                                    </h3>
                                    <p className="mb-0">
                                        <small>
                                            <Moment format="hh:mm a">
                                                {getValue(summaryInfo, `launchDate`, 0)}
                                            </Moment>
                                        </small>
                                    </p>
                                </Col>
                                <Col md="2">
                                    <p className="mb-0">End Date</p>
                                    <h3 className="mb-0">
                                        <Moment format="DD MMM YYYY">
                                            {getValue(summaryInfo, `endDate`, 0)}
                                        </Moment>
                                    </h3>
                                    <p className="mb-0">
                                        <small>
                                            <Moment format="hh:mm a">
                                                {getValue(summaryInfo, `endDate`, 0)}
                                            </Moment>
                                        </small>
                                    </p>
                                </Col>
                                <Col md="4">
                                    <Row>
                                        <Col md="6">
                                            <p className="mb-0">Type</p>
                                            <h3 className="mb-0">
                                                {getValue(summaryInfo, `type`, '')
                                                    ? getValue(summaryInfo, `type`, '')
                                                        .split(' ')
                                                        .map(
                                                            (w) =>
                                                                w[0].toUpperCase() +
                                                                w.substr(1).toLowerCase()
                                                        )
                                                        .join(' ')
                                                    : ''}
                                            </h3>
                                            {/* <p className="mb-0">
                              <small>Private</small>
                            </p> */}
                                        </Col>
                                        <Col md="6">
                                            <p className="mb-0">Questions</p>
                                            <h3 className="mb-0">
                                                {getValue(summaryInfo, `totalQuestions`, 0)}
                                            </h3>
                                            <p className="mb-0">
                                                <small>
                                                    Pages : {getValue(summaryInfo, `totalPages`, 0)}
                                                </small>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </> : ""}
            <br></br>
            <br></br>
            <div className="page-layout page-layout-full">
                <div className="create-survey-tabs border-bottom-0">
                    <div className="back-btn">
                        {/* <NavLink to={`/summary/${surveyId}`}> */}
                        {/* <NavLink to={`/completed-surveys`}>
            
                            <Button color="nothing">
                                <BackSVG />
                            </Button>
                            </NavLink> */}
                    </div>
                </div>
                <div className="page-height">
                    <div className="add-survey-line">
                        <div className="page-padding py-0">
                            <div className="line-relative">
                                <ul className="line-relative-slick">
                                    {Array.from(
                                        { length: getValue(infoData, 'totalPages', []) },
                                        (v, k) => k
                                    ).map((k, i) => (
                                        <li key={i}>
                                            <Button
                                                className={
                                                    i === getValue(infoData, 'pageNo', 0) ? 'active' : ''
                                                }
                                                color="nothing"
                                                onClick={() => props.handleChangePage(i + 1)}
                                            >
                                                {i + 1}
                                            </Button>
                                        </li>
                                    ))}
                                </ul>
                                <Button
                                    color="nothing"
                                    className="left-prev"
                                    disabled={
                                        !getValue(infoData, 'previousPageId', '') ? true : false
                                    }
                                    onClick={() => {
                                        handleSurveySubmit(
                                            getValue(infoData, 'previousPageId', '')
                                        );
                                    }}
                                >
                                    <img src="/images/slick-fonts/left.svg" alt="" />
                                </Button>
                                {getValue(infoData, 'nextPageId', '') ? (
                                    <Button
                                        color="nothing"
                                        className="right-next"
                                        // disabled={
                                        //   activeTab - 1 === questionsInfo.length - 1 ? true : false
                                        // }
                                        onClick={() => {
                                            handleSurveySubmit(getValue(infoData, 'nextPageId', ''));
                                        }}
                                    >
                                        <img src="/images/slick-fonts/right.svg" alt="" />
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="page-padding pt-5">
                        <Row>
                            <Col lg={{ size: 10, offset: 1 }}>
                                <h5 className="mb-1 text-green-light text-center">
                                    {getValue(questionsInfo, 'title', '') === 'Untitled'
                                        ? ''
                                        : getValue(questionsInfo, 'title', '')}
                                </h5>
                                <p className="text-center">
                                    {getValue(questionsInfo, 'description', '') === 'Untitled'
                                        ? ''
                                        : getValue(questionsInfo, 'description', '')}
                                </p>
                                {getValue(questionsInfo, 'questions.length', 0) !== 0
                                    ? getValue(questionsInfo, 'questions', []).map(
                                        (qus, index) => {
                                            return (
                                                <>
                                                    <div className="ml-3">
                                                        <ChartDecider
                                                            options={options}
                                                            series={series}
                                                            type={qus.type}
                                                            questions={qus}
                                                            option={getValue(qus, 'options', [])}
                                                            qno={index + 1}
                                                        />
                                                    </div>
                                                    <hr className="my-4" />
                                                </>
                                            );
                                        }
                                    )
                                    : 'Please Add Questions'}

                                <div className="my-4 btn-group-margin">
                                    <Button
                                        color="border"
                                        disabled={
                                            !getValue(infoData, 'previousPageId', '') ? true : false
                                        }
                                        className="pl-1 py-1"
                                        onClick={() => {
                                            handleSurveySubmit(
                                                getValue(infoData, 'previousPageId', '')
                                            );
                                        }}
                                    >
                                        <ArrowLeftSVG /> <span className="pl-2">Previous</span>
                                    </Button>
                                    {getValue(infoData, 'nextPageId', '') ? (
                                        <Button
                                            color="border"
                                            className="pl-1 py-1"
                                            //   disabled={activeTab === infoData.length ? true : false}
                                            onClick={() => {
                                                handleSurveySubmit(
                                                    getValue(infoData, 'nextPageId', '')
                                                );
                                            }}
                                        >
                                            <ArrowRightSVG /> <span className="pl-2">Next</span>
                                        </Button>
                                    ) : (
                                        ''
                                        // <Button
                                        //   color="primary"
                                        //   className="pl-1 py-1"
                                        //   // onClick={()=>{handleSurveySubmit(getValue(questionsInfo, 'nextPageId', ''))}}
                                        // >
                                        //   <span className="pl-2">Submit</span>
                                        // </Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SummarySurveyQuestion;
