import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { NavLink } from 'react-router-dom';
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Label,
} from 'reactstrap';
import ChooseTemplateLayout from './ChooseTemplateLayout';
import classnames from 'classnames';
import BackSVG from '../../components/svg/back-svg';
import { getValue } from '../../core/lodash';
import { useHistory } from 'react-router-dom';
import {
  createSurvey,
  createSurveyFromExistingSurvey,
  createSurveyFromTemplate,
  createSurveyPage,
  getSurvey,
  getSurveyAllList,
  getSurveyCategories,
} from 'core/services/surveyService';
import Cookies from 'js-cookie';
import Select from 'react-select';
import ChooseTemplateExisitingSurvey from './ChooseTemplateForExisting';
// import { useForm } from 'react-hook-form';
import * as yup from 'yup';
// import { yupResolver } from '@hookform/resolvers/yup';
import {
  getSpecificSurveyTemplate,
  getSurveyTemplate,
} from 'core/services/surveyTemplateService';


interface IFormInputs {
  name: string;
  category: string;
}
const SurveySchema = yup.object().shape({
  name: yup.string().required(),
  category: yup.string().required(),
});

const CreateNewSurvey: FunctionComponent = (_props) => {
  // react-hook form
  // const { register, handleSubmit, errors } = useForm<IFormInputs>({
  //   resolver: yupResolver(SurveySchema),
  // });
  const simpleValidator = useRef(new SimpleReactValidator());
  const [isLeftMenu, manageMenu] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [surveyCategories, setSurveyCategories] = useState([]);
  const [request, setRequest] = useState({
    name: '',
    category: '',
    type: '',
  });
  const [surveyType, setSurveyType] = useState([
    { value: 'Standard Survey', label: 'Standard Survey' },
    { value: '360 Survey', label: '360 Survey' },
    { value: 'Assessment', label: 'Assessment' },
  ]);

  const [, forceUpdate] = useState(0);
  const [categoryOption, setCategoryOption] = useState([]);
  const [typeOption, setTypeOption] = useState([]);

  const [surveyDetails, updateSurvey] = useState([]);
  const [specificSurvey, setSpecificSurvey] = useState({});

  const [specificTemplate, setSpecificTemplate] = useState({});
  const [templateDetails, updateTemplate] = useState([]);

  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);

  // pagination template
  const [pageNumberTemp, setPageNumberTemp] = useState(1);
  const [limitTemp, setLimitTemp] = useState(10);
  const [totalCountTemp, setTotalCountTemp] = useState(0);
  const [skipTemp, setSkipTemp] = useState(0);

  // survey creating from existing
  const [name, setName] = useState('');
  const [nameTemp, setNameTemp] = useState('');

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    getSurveyCategoriesInfo();
    getSurveyList();
    getTemplateList();
    // Cookies.remove('template');
    // Cookies.remove('templateId');
    // Cookies.remove('templateEdit');
    Cookies.remove('progressSurvey');
  }, []);

  const onSubmits = (_data: IFormInputs) => {
    // onCreateSurvey(data);
    history.push('/create-new-survey-form');
  };
  const history = useHistory();

  // =============================================================================
  // handle change section
  // =============================================================================

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleChangeModal = (e) => {
    const { name, value } = e.target;
    setName(value);
  };

  const handleChangeModalTemp = (e) => {
    const { name, value } = e.target;
    setNameTemp(value);
  };

  const handleChange = (value) => {
    setCategoryOption(value);
    setRequest({
      ...request,
      category: value.value,
    });
  };
  const handleChangeType = (value) => {
    setTypeOption(value);
    setRequest({
      ...request,
      type: value.value,
    });
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  // ===========================================================================
  // Submit section
  // ===========================================================================

  const handleSubmitForm = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let surveyType = '';
      if (request.type === 'Standard Survey') {
        surveyType = 'SURVEY';
      } else if (request.type === '360 Survey') {
        surveyType = 'SURVEY360';
      } else {
        surveyType = 'ASSESSMENT';
      }
      let data = {
        name: request.name,
        category: request.category,
        type: surveyType,
      };
      // onCreateSurvey(data);
      try {
        setLoading(true)
        let resp = await createSurvey(data);
        if (resp) {
          let req = {
            title: 'Untitled',
            description: 'Untitled',
            uniqueOrder: 0,
          };
          Cookies.set('surveyId', getValue(resp, 'data.id', ''));
          let response = await createSurveyPage(
            getValue(resp, 'data.id', ''),
            req
          );
          if (response) {
            Cookies.set('pageId', getValue(response, 'data.id', ''));
            history.push(
              `/create-new-survey-form/${getValue(resp,'data.type','')}/${getValue(resp, 'data.id', '')}`
            );
            setLoading(false)
          } else {
            setLoading(false)
          }
        }
      } catch (error) {
        setLoading(false)
      }
    }
  };

  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getSurveyList();
  };
  const onProductPageChangeHandlerTemp = (pageNumber) => {
    setPageNumberTemp(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkipTemp(skip);
    getTemplateList();
  };
  // ===========================================================================
  // Api section
  // ===========================================================================

  const [loading3, setLoading3] = useState(false)
  const handleCreate = async () => {
    try {
      setLoading3(true)
      // let data = {
      //   name: name,
      //   surveyId: getValue(specificSurvey, 'id', ''),
      //   createType: 'from_exist',
      // };
      let resp = await createSurveyFromExistingSurvey(getValue(specificSurvey, 'id', ''));
      if (resp) {
        setLoading3(false)
        Cookies.set('surveyId', getValue(resp, 'data.id', ''));
        history.push(
          `/create-new-survey-form/${getValue(resp, 'data.type', '')}/${getValue(resp, 'data.id', '')}`
        );
      } else {
        setLoading3(false)
      }
    } catch (error) {
      //console.log(error);
      setLoading3(false)
    }
  };

  const getSpecificSurveyInfo = async (id) => {
    try {
      let resp = await getSurvey(id);
      if (resp) {
        setSpecificSurvey(getValue(resp, 'data', {}));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getSurveyList = async () => {
    let resp = await getSurveyAllList(skip, limit, '');
    if (resp) {
      updateSurvey(getValue(resp, 'data.surveys', {}));
      setTotalCount(getValue(resp, 'data.count', 0));
      getSpecificSurveyInfo(getValue(resp, 'data.surveys[0].id', ''));
    }
  };

  // templates
  const getSpecificTemplateInfo = async (id) => {
    try {
      let resp = await getSpecificSurveyTemplate(id);
      if (resp) {
        setSpecificTemplate(getValue(resp, 'data', {}));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const getTemplateList = async () => {
    let resp = await getSurveyTemplate(skipTemp, limitTemp, '');
    if (resp) {
      updateTemplate(getValue(resp, 'data.templates', {}));
      setTotalCountTemp(getValue(resp, 'data.count', 0));
      getSpecificTemplateInfo(getValue(resp, 'data.templates[0].id', ''));
    }
  };

  const getSurveyCategoriesInfo = async () => {
    try {
      let resp = await getSurveyCategories();
      if (resp) {
        setSurveyCategories(
          getValue(resp, 'data', []).map((item) => {
            return {
              label: item,
              value: item,
            };
          })
        );
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const [loading1, setLoading1] = useState(false)
  const handleCreateTemp = async () => {
    try {
      setLoading1(true)

      // createSurveyFromTemplate


      let resp = await createSurveyFromTemplate(getValue(specificTemplate, 'id', ''));
      //console.log(resp);
      if (resp) {
        setLoading1(false)
        Cookies.set('surveyId', getValue(resp, 'data.id', ''));
        history.push(
          `/create-new-survey-form/${getValue(resp, 'data.type', '')}/${getValue(resp, 'data.id', '')}`
        );
      } else {
        setLoading1(false)
      }
    } catch (error) {
      // toast.error(error.Error)
      //console.log(error);
      setLoading1(false)
    }
  };

  // ===========================================================================
  // search section
  // ===========================================================================

  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const globalSearch = async (value) => {
    try {
      let resp = await getSurveyAllList(skip, limit, value);
      if (resp) {
        updateSurvey(getValue(resp, 'data.surveys', {}));
        setTotalCount(getValue(resp, 'data.count', 0));
        getSpecificSurveyInfo(getValue(resp, 'data.surveys[0].id', ''));
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // template

  const [searchInputTemp, setSearchInputTemp] = useState('');

  const handleChangeSearchTemp = (e) => {
    const { name, value } = e.target;
    setSearchInputTemp(value);
    globalSearchTemp(value);
  };

  const globalSearchTemp = async (value) => {
    try {
      let resp = await getSurveyTemplate(skip, limit, value);
      if (resp) {
        updateTemplate(getValue(resp, 'data.templates', {}));
        setTotalCountTemp(getValue(resp, 'data.count', 0));
        getSpecificTemplateInfo(
          getValue(resp, 'data.templates[0].id', '')
        );
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <>
      <div className="page-layout page-layout-full">
        <div className="create-survey-tabs">
          <Nav pills className="basic-tabs">
            <NavItem>
              <NavLink1
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                Create New Survey
              </NavLink1>
            </NavItem>
            <NavItem>
              <NavLink1
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                Choose from Template
              </NavLink1>
            </NavItem>
            <NavItem>
              <NavLink1
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                }}
              >
                Choose Existing Survey
              </NavLink1>
            </NavItem>
          </Nav>
          <div className="back-btn">
            <NavLink to="/home">
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          </div>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <div className="page-height page-padding">
              <div className="create-survey-div">
                <h3 className="text-center">Name your Survey</h3>
                {/* <form onSubmit={handleSubmit(onSubmits)}> */}
                <div className="form-group">
                  <Label>
                    Survey Name<span className="imp">*</span>
                  </Label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Survey Name"
                    className="form-control"
                    value={request.name}
                    // ref={register}
                    onChange={handleChangeForm}
                  />
                  <div className="form-error">
                    {simpleValidator.current.message(
                      'name',
                      request.name,
                      'required'
                    )}
                  </div>
                  {/* <div className="form-error">
                      {getValue(errors, 'name.message', '')}
                    </div> */}
                </div>
                <div className="form-group form-react-select">
                  <Label>
                    Category Name <span className="imp">*</span>
                  </Label>
                  <Select
                    value={categoryOption ? categoryOption : ''}
                    onChange={handleChange}
                    options={surveyCategories}
                    name="category"
                  // ref={register}
                  />
                  <div className="form-error">
                    {simpleValidator.current.message(
                      'category',
                      request.category,
                      'required'
                    )}
                  </div>
                  {/* <div className="form-error">
                      {getValue(errors, 'category.message', '')}
                    </div> */}
                </div>
                <div className="form-group form-react-select">
                  <Label>
                    Survey Type<span className="imp">*</span>
                  </Label>
                  <Select
                    // value={
                    //   surveyCategories.filter(
                    //     (item) =>
                    //       getValue(item, 'label', '') ===
                    //       getValue(surveyDetails, 'category', '')
                    //   )[0]
                    // }
                    onChange={handleChangeType}
                    options={surveyType}
                    name="category"
                  />
                  <div className="form-error">
                    {simpleValidator.current.message(
                      'type',
                      request.type,
                      'required'
                    )}
                  </div>{' '}
                </div>
                <div className="form-group mt-4">
                  {loading ?
                    <Button
                      type="submit"
                      color="blue"
                      block
                    >
                      Please wait...
                    </Button> :
                    <Button
                      type="submit"
                      color="blue"
                      onClick={handleSubmitForm}
                      block
                    >
                      Create Survey
                    </Button>}
                </div>
                {/* </form> */}
              </div>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <ChooseTemplateLayout
              surveyDetails={templateDetails}
              totalCount={totalCountTemp}
              onProductPageChangeHandler={onProductPageChangeHandlerTemp}
              pageNumber={pageNumberTemp}
              limit={limitTemp}
              specificSurvey={specificTemplate}
              getSpecificSurveyInfo={getSpecificTemplateInfo}
              name={nameTemp}
              handleChange={handleChangeModalTemp}
              handleCreate={handleCreateTemp}
              handleChangeSearch={handleChangeSearchTemp}
              searchInput={searchInputTemp}
              loading1={loading1}
            />
          </TabPane>
          <TabPane tabId="3">
            <ChooseTemplateExisitingSurvey
              surveyDetails={surveyDetails}
              totalCount={totalCount}
              onProductPageChangeHandler={onProductPageChangeHandler}
              pageNumber={pageNumber}
              limit={limit}
              specificSurvey={specificSurvey}
              getSpecificSurveyInfo={getSpecificSurveyInfo}
              name={name}
              handleChange={handleChangeModal}
              handleCreate={handleCreate}
              handleChangeSearch={handleChangeSearch}
              searchInput={searchInput}
              loading3={loading3}
            />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};
export default CreateNewSurvey;
