import ComplatedSurveyMain from '../pages/CompletedSurvey/CompletedSurveysMain';
import Summary from '../pages/CompletedSurvey/Summary';
import CreateNewSurvey from '../pages/CreateNewSurvey/CreateNewSurveyComponent';
import SurveyFormContainer from '../pages/CreateNewSurvey/SurveyFormContainer';
import SurveyPickAudiance from '../pages/CreateNewSurvey/Surveypickaudiance';
import SurveyPublish from '../pages/CreateNewSurvey/SurveyPublish';
import SurveySettingsComponent from '../pages/CreateNewSurvey/SurveySettingsComponents';
import Home from '../pages/home';
import HRPolicy from '../pages/HRPolicy';
import OpenSurveyMain from '../pages/OpenSurvey/OpenSurveyMain';
import PageNotFound from '../pages/PageNotFound';
import ProgressSurveyMain from '../pages/ProgressSurevy/ProgressSurveyMain';
import Survey from '../pages/Survey';
import TakeSurveyComponent from '../pages/TakeSurvey/TakeSurveyComponent';
import Summary360 from '../pages/CompletedSurvey/360Summary';
import Summary360Level1 from '../pages/CompletedSurvey/360SummaryLevel1';
import CompletedSurveyPreview from '../pages/CompletedSurvey/completedSurveyPreview';
import TemplateMain from '../pages/Template/templateMain';
import TemplateFormComponant from '../pages/Template/template';
import TemplateSurveyCreationComponant from '../pages/Template/templateSurveyCreationPage';
import QuestionBankMain from '../pages/QuestionBank/Questionbank-main';
import ThankYou from '../pages/TakeSurvey/ThankYou';
import SurveyPublishSuccess from '../pages/TakeSurvey/SurveyPublishSuccess';
import SSOAuth from '../pages/sso-auth';
import TakeSurveyComponentAssessment from '../pages/TakeSurvey/TakeSurveyComponentAssessment';
import ThankYouAssessMent from '../pages/TakeSurvey/ThankYouAssessment';
import AssessmentSummaryPreview from '../pages/AssessmentPreview';

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/home', name: 'home', component: Home },

  // survey creattion
  {
    path: '/survey/:type/:surveyId',
    name: 'survey-creation',
    component: Survey,
  },
  {
    path: '/create-new-survey',
    name: 'survey-creation',
    component: CreateNewSurvey,
  },

  // Survey Question creation
  {
    path: '/create-new-survey-form/:type/:id/:templates',
    name: 'create-new-survey-form',
    component: SurveyFormContainer,
  },
  {
    path: '/create-new-survey-form/:type/:id',
    name: 'create-new-survey-form',
    component: SurveyFormContainer,
  },
  {
    path: '/create-survey/settings/:type/:id',
    name: 'create-survey-settings',
    component: SurveySettingsComponent,
  },
  // {
  //   path: '/create-survey/settings/:type/:id',
  //   name: 'create-survey-settings',
  //   component: SurveySettingsComponent,
  // },
  {
    path: '/create-survey/pick-audience/:type/:id',
    name: 'create-survey-pick-audience',
    component: SurveyPickAudiance,
  },
  {
    path: '/create-survey/publish/:type/:id',
    name: 'create-survey-publish',
    component: SurveyPublish,
  },

  // take a survey
  // {
  //   path: '/take-survey/:empId/:email/:userId',
  //   name: 'take-survey-360',
  //   component: HRPolicy,
  // },
  { path: '/take-survey/:surveyId', name: 'take-survey', component: HRPolicy },

  // @surya new api
  // { path: '/take-survey/:type/:surveyUrl', name: 'take-survey', component: HRPolicy },
  {
    path: '/take-survey/:type/:tenant_id/:surveyUrl',
    name: 'take-surveys',
    component: TakeSurveyComponent,
  },
  {
    path: '/take-survey/:type/:surveyUrl',
    name: 'take-surveys',
    component: TakeSurveyComponent,
  },
  {
    path: '/take-survey-assessment/:type/:surveyUrl',
    name: 'take-surveys',
    component: TakeSurveyComponentAssessment,
  },

  // end
  {
    path: '/take-surveys/:surveyId',
    name: 'take-surveys',
    component: TakeSurveyComponent,
  },
  {
    path: '/take-surveys-360/:surveyId/:email',
    name: 'take-surveys-360',
    component: TakeSurveyComponent,
  },

  // survey preview
  {
    path: '/survey-preview/:surveyId/:userId/:routeUrl',
    name: 'survey-preview',
    component: CompletedSurveyPreview,
  },

  // templates
  { path: '/templates', name: 'templates', component: TemplateMain },
  {
    path: '/templates-list/:id',
    name: 'templates-list',
    component: TemplateFormComponant,
  },
  {
    path: '/templates-list',
    name: 'templates-list',
    component: TemplateSurveyCreationComponant,
  },

  // question bank
  {
    path: '/question-bank/:surveyId',
    name: 'question-bank',
    component: QuestionBankMain,
  },

  // progress/completed/open survey list
  { path: '/open-surveys', name: 'open-survey', component: OpenSurveyMain },
  {
    path: '/inprogress-surveys',
    name: 'open-survey',
    component: ProgressSurveyMain,
  },
  {
    path: '/completed-surveys',
    name: 'open-survey',
    component: ComplatedSurveyMain,
  },

  // inside above routes
  { path: '/summary/:surveyId/:routeUrl', name: 'summary', component: Summary },
  {
    path: '/360-summary/:surveyId/:routeUrl',
    name: 'summary-360',
    component: Summary360,
  },
  {
    path: '/360/survey-detail/:surveyId/:empId/:routeUrl',
    name: 'summary-360-level1',
    component: Summary360Level1,
  },

  { path: '/auth/login/callback', name: 'thankyou', component: SSOAuth },

  // static pages
  { path: '/thankyou/:type', name: 'thankyou', component: ThankYou },
  {
    path: '/thankyou-assessment/:type/:surveyId/:userSurveyId',
    name: 'thankyou',
    component: ThankYouAssessMent,
  },

  {
    path: '/assessment-preview/:type/:surveyId/:userSurveyId',
    name: 'thankyou',
    component: AssessmentSummaryPreview,
  },

  { path: '/publish/:id', name: 'publish', component: SurveyPublishSuccess },
  { path: '/publish', name: 'publish', component: SurveyPublishSuccess },

  // { path: '*', name: 'Pagenotfound', component: PageNotFound }
];

export default routes;
