import React, { useState } from 'react';
import {
  Button,
  Nav,
  NavItem,
  Table,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Card,
  CardBody,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { getValue } from '../../core/lodash';
import { NavLink } from 'react-router-dom';
import AddSVG from '../svg/add-svg';
import TableSearch from '../tables/TableSearch';
import SearchSVG from '../svg/search-svg';
import CloseSVG from '../svg/close-svg';
import SortSVG from '../svg/sort-svg';
import MoreSVG from '../svg/more-svg';

interface ITemplateHomeProps {
  handleSubmitTemplate: (e: any) => void;
  handleChangeForm: (e: any) => void;
  handleChange: (e: any) => void;
  handleSubmitExistingTemplate: () => void;
  templateList: any[];

  simpleValidator: any;
  request: any;
  surveyCategories: any;
  categoryOption: any;
  handleDeleteSurvey: (id: string, name: string) => void;
  handleChangeSearch: (e: any) => void;
  searchInput: string;
  toggleSearch: () => void;
  search: any;
  toggleModal: () => void;
  modal: boolean;
}

const TemplateHome: React.FunctionComponent<ITemplateHomeProps> = (props) => {
  const {
    toggleSearch,
    search
  } = props;
  return (
    <>
      <>
        <div className="table-header">
          <h4 className="table-header-h4 mb-0">All Templates</h4>
          <ul className="table-header-actions">
            <li className="hover-circle">
              <Button color="nothing" onClick={toggleSearch}>
                <SearchSVG />
              </Button>
              <div
                className={'table-search-overlay ' + (search ? 'show' : '')}
                onClick={toggleSearch}
              ></div>
              <div className={'table-search ' + (search ? 'show' : '')}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  onChange={props.handleChangeSearch}
                  value={props.searchInput}
                />
                <Button
                  color="nothing"
                  onClick={toggleSearch}
                  className="table-search-close"
                >
                  <CloseSVG />
                </Button>
              </div>
            </li>
            <li className="orange-circle">
              <Button color="primary" onClick={props.toggleModal}>
                <AddSVG />
              </Button>
            </li>
            {/* <li className="sort-by">
              <p>Sort by:</p>
              <UncontrolledDropdown>
                <DropdownToggle color="nothing">
                  <SortSVG />
                </DropdownToggle>
                <DropdownMenu className="custom-dropdown3" right>
                  <h6 className="">Sort by</h6>
                  <DropdownItem>All</DropdownItem>
                  <DropdownItem>Date Modified</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li> */}
          </ul>
          <div className="clearfix"></div>
        </div>
        <Row className="custom-row">
          {getValue(props, 'templateList.length', 0) !== 0 ? (
            getValue(props, 'templateList', []).map((value, key) => {
              return (
                <Col md="3" className="mb-4 custom-col">
                  <Card className="template-card">
                    <CardBody>
                      <NavLink to={`templates-list/${value.id}`}>
                        <div className="mb-2">
                          <img src="/images/icon5.PNG" alt="" />
                        </div>
                        <h5>{value.name}</h5>
                        <span style={{color:'#d7242e'}}><b>Type:</b></span>{' '}<span className="mb-0" style={{color:'#d7242e'}}>
                          {value.type
                            ? value.type
                              .split(' ')
                              .map(
                                (w) =>
                                  w[0].toUpperCase() +
                                  w.substr(1).toLowerCase()
                              )
                              .join(' ')
                            : ''}
                        </span>

                        <hr className="my-2" />

                        <p>{getValue(value, 'category', '-')}</p>
                        <p className="mb-0">
                          <small>{value.totalPages} Page(s)</small>
                        </p>
                        <p className="mb-0">
                          <small>{value.totalQuestions} Question(s)</small>
                        </p>
                      </NavLink>
                      <UncontrolledDropdown>
                        <DropdownToggle color="nothing">
                          <MoreSVG />
                        </DropdownToggle>
                        <DropdownMenu className="custom-dropdown3 small" right>
                          <DropdownItem
                            onClick={() =>
                              props.handleDeleteSurvey(value.id, value.name)
                            }
                          >
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </CardBody>
                  </Card>
                </Col>
              );
            })
          ) : (
            <Col>
              <div className="text-center mt-5">
                <div className="mt-4 mb-2">
                  <img src="/images/empty.PNG" alt="" />
                </div>
                <h4 className="mb-2">No templates available</h4>
                <p>
                  Your organisation doesn’t have any
                  <br />
                  templates yet. Be the first to submit one.
                </p>
                <Button color="border" onClick={props.toggleModal}>
                  Submit Template
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </>
    </>
  );
};

export default TemplateHome;
