import { getValue } from './../../core/lodash';
export const Validation = (questionsInfo,answer) => {
    // =========================================================================
    // valid condition check for all types
    // =========================================================================

    let section1: any[];
    let section2: any[];
    let section3: any[];
    let section4: any[];
    let section5: any[];
    let section6: any[];
    
    let errorSection1: any[];
    let errorSection2: any[];
    let errorSection3:any = [];
    let errorSection4:any = [];
    let errorSection5:any = [];
    let errorSection6:any = [];

    // section1
    let validSection1 = getValue(questionsInfo, 'page.questions', []).filter(
      (item) =>
        (item.type === 'RATING_SCALE' ||
          item.type === 'STAR_RATING' ||
          item.type === 'FILE_UPLOAD' ||
          item.type === 'DATE' ||
          item.type === 'TIME' ||
          item.type === 'SINGLE_CHOICE' ||
          item.type === 'CHECK_BOX' ||
          item.type === 'DROPDOWN') &&
        item.mandatoryEnabled === true
    );
    if (validSection1.length !== 0) {
      section1 = validSection1.map((item) => item.id);
    }

    // section2
    let validSection2 = getValue(questionsInfo, 'page.questions', []).filter(
      (item) => item.type === 'EMAIL_TEXTBOX' && item.mandatoryEnabled === true
    );

    if (validSection2.length !== 0) {
      section2 = validSection2.map((item) => item.id);
    }

    // section3
    let validSection3 = getValue(questionsInfo, 'page.questions', []).filter(
      (item) =>
        (item.type === 'SHORT_ANSWER' || item.type === 'LONG_ANSWER') &&
        item.mandatoryEnabled === true
    );

    if (validSection3.length !== 0) {
      section3 = validSection3.map((item) => item.id);
    }

    // section4
    let validSection4 = getValue(questionsInfo, 'page.questions', []).filter(
      (item) =>
        item.type === 'NUMERIC_TEXTBOX' && item.mandatoryEnabled === true
    );

    if (validSection4.length !== 0) {
      section4 = validSection4.map((item) => item.id);
    }
    // section5
    let validSection5 = getValue(questionsInfo, 'page.questions', []).filter(
      (item) => item.type === 'FULL_NAME' && item.mandatoryEnabled === true
    );

    if (validSection5.length !== 0) {
      section5 = validSection5.map((item) => item.id);
    }

    // section6
    let validSection6 = getValue(questionsInfo, 'page.questions', []).filter(
      (item) =>
        (item.type === 'MATRIX_RADIO_BOX' ||
          item.type === 'MATRIX_CHECK_BOX') &&
        item.mandatoryEnabled === true
    );
    
    if (validSection6.length !== 0) {
      section6 = validSection6.map((item) => item.id);
    }

    // ErrorValidating section

    var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;


    let error1 = answer.filter(
      (err1) =>
        section1 &&
        section1.includes(err1.questionId) &&
        err1.response.length === 0
    );
    errorSection1 = error1.map((item) => item.questionId);

    let error2 = answer.filter(
      (err2) =>
        section2 &&
        section2.includes(err2.questionId) &&
        !pattern.test(err2.response)
    );
    errorSection2 = error2.map((item) => item.questionId);

    // =========================================================================
    // Short answer/long answer validation section
    // =========================================================================
    answer.filter((item) => {
      if (section3 && section3.includes(item.questionId)) {
        getValue(questionsInfo, 'page.questions', []).filter((a) => {
          if (a.id === item.questionId) {
            if (
              item.response.length < a.validations.minLength &&
              a.validations.maxLength > item.response.length
            ) {
              if (!errorSection3.includes(item.questionId)) {
                errorSection3.push(item.questionId);
              }
            } else {
              if (item.response.length >= a.validations.maxLength) {
                if (!errorSection3.includes(item.questionId)) {
                  errorSection3.push(item.questionId);
                }
              }
            }
          }
        });
      }
    });

    // =========================================================================
    // Number validation section
    // =========================================================================

    answer.filter((item) => {
      if (section4 && section4.includes(item.questionId)) {
        getValue(questionsInfo, 'page.questions', []).filter((a) => {
          if (a.id === item.questionId) {
            if (
              item.response.length < parseFloat(a.validations.minValue) ||
              item.response.length > parseFloat(a.validations.maxValue) - 1
            ) {
              if (!errorSection4.includes(item.questionId)) {
                errorSection4.push(item.questionId);
              }
            } else {
              if (item.response.length >= parseFloat(a.validations.maxValue)) {
                if (!errorSection4.includes(item.questionId)) {
                  errorSection4.push(item.questionId);
                }
              }
            }
          }
        });
      }
    });

    // =========================================================================
    // Full Name validation section
    // =========================================================================

    answer.filter((item) => {
      validSection5.map((qu) => {
        getValue(qu, `row[${0}].columns`, []).map((col) => {
          if (
            item.questionId === col.questionId &&
            item.response.length === 0
          ) {
            errorSection5.push(item.questionId);
          }
        });
      });
    });

    // =========================================================================
    // multichoice and checkbox grid validation
    // =========================================================================
    answer.filter((item:any) => {
      validSection6.filter((qu) => {
        if (qu.id === item.questionId) {
          if (qu.row.length <= item.response.length) {
            item.response.map((row) => {
              if (!row.columnId) {
                let checkQuestionId = validSection6.includes(item.questionId);
                if (!checkQuestionId) {
                  errorSection6.push(item.questionId);
                }
              }
            });
          } else {
            let checkQuestionId = validSection6.includes(item.questionId);
            if (!checkQuestionId) {
              errorSection6.push(item.questionId);
            }
          }
        }
      });
    });

    // =========================================================================
    // concating all errors
    // =========================================================================

    let ops:any = [
      errorSection1,
      errorSection2,
      errorSection3,
      errorSection4,
      errorSection5,
      errorSection6,
    ];
    var totalErrors = [].concat.apply([], ops);
    return totalErrors;
  };