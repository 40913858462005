import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import LeftNav from '../components/menu/left-nav';
import { getValue, setValue } from '../core/lodash';
import Moment from 'react-moment';
import Pagination from 'react-js-pagination';
import {
  add360SurveyEmailsEmployees,
  get360SurveyParticipantEmails,
  getSurveyCollectorList,
  import360SurveyEmails,
} from '../core/services/surveyService';
import Cookies from 'js-cookie';
import LoaderCommon from '../components/common/loader-common';
import { useHistory } from 'react-router-dom';
import { config } from '../config';
import InviteParticipantSurveyModal from './modal/inviteParticipantsModal';
import AlreadyThereParticipants from './modal/alreadyThereParticipantsModal';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

const HomePage = () => {
  const [isLeftMenu, manageMenu] = useState(false);
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [surveyDetails, updateSurvey] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState('newest');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  // modal
  const [isOpen, setIsOpen] = useState(false);
  const [participants, setParticipant] = useState([
    {
      name: '',
      email: '',
      feedbackParticipants: [
        {
          type: 'private',
          emails: [],
          group: 'manager',
        },
        {
          type: 'private',
          emails: [],
          group: 'peer',
        },
        {
          type: 'private',
          emails: [],
          group: 'subordinate',
        },
        {
          type: 'public',
          emails: [],
          group: 'public',
        },
      ],
    },
  ]);
  const [loading360, setLoading360] = useState(false);
  const [errorEmails, setErrorEmails] = useState([]);
  const [invalidEmailsDownloadUrl, setInvalidEmailsDownloadUrl] = useState('');
  const toggleModal = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const toggleModal1 = () => {
    getParticipantsEmails(surveyId, empId);
    setModal1(!modal1);
  };
  const [modal1, setModal1] = useState(false);
  const [userName, setUserName] = useState('');
  // ===========================================================================
  // Participants section
  // ===========================================================================

  const removeParticipants = (index) => {
    let participantArray = participants.filter((_item, i) => i !== index);
    setParticipant([...participantArray]);
  };
  const addParticipants = () => {
    participants.push({
      name: '',
      email: '',
      feedbackParticipants: [
        {
          type: 'private',
          emails: [],
          group: 'manager',
        },
        {
          type: 'private',
          emails: [],
          group: 'peer',
        },
        {
          type: 'private',
          emails: [],
          group: 'subordinate',
        },
        {
          type: 'public',
          emails: [],
          group: 'public',
        },
      ],
    });
    setParticipant([...participants]);
  };
  const handleEmailTemplateEmail = (email, emailIndex, mainIndex) => {
    setParticipant((pages) => {
      return pages.map((x, index) => {
        if (index !== mainIndex) return x;
        return {
          ...x,
          feedbackParticipants: getValue(x, 'feedbackParticipants', []).map(
            (y, index2) => {
              if (index2 !== emailIndex) return y;
              return {
                ...y,
                emails: email,
              };
            }
          ),
        };
      });
    });
  };
  const emailTemplateOnchange = (e, i) => {
    const { name, value } = e.target;
    setParticipant((pages) => {
      return pages.map((x, index) => {
        if (index !== i) return x;
        return {
          ...x,
          [name]: value,
        };
      });
    });
  };
  const add360EmailTemplate = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        var myNewArray;
        let wholeArray: any = [];
        for (let i = 0; i < participants.length; i++) {
          myNewArray = [].concat.apply(
            [],
            participants[i].feedbackParticipants.map((item) => {
              let emails: any = item.emails.map((email) => ({
                type: item.type,
                email: email,
                group: item.group,
              }));
              return emails;
            })
          );
          let req = {
            // name: participants[i].name,
            email: participants[i].email,
            feedbackParticipants: myNewArray,
          };
          wholeArray.push(req);
        }
        let req = {
          participants: wholeArray[0],
        };
        setLoading360(true);
        let resp = await add360SurveyEmailsEmployees(surveyId, empId, req);
        if (resp) {
          setLoading360(false);
          toast.success(
            'Participants added successfully'
            //  {className: 'green-circle'}
          );
          handleModal();
          if (
            getValue(
              resp,
              'data.participants.feedbackParticipants.length',
              0
            ) === 0
          ) {
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setParticipant([...participants]);
          }
          if (getValue(resp, 'data.invalidEmails.length', 0) !== 0) {
            setErrorEmails(getValue(resp, 'data.invalidEmails', []));
            setInvalidEmailsDownloadUrl(
              getValue(resp, 'data.invalidEmailsDownloadUrl', [])
            );
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setValue(participants[0], `feedbackParticipants[${0}].emails`, []);
            setParticipant([...participants]);
            toggleModal();
          }
        } else {
          setLoading360(false);
        }
      } catch (error) {
        setLoading360(false);
      }
    }
  };
  const [alreadyThereParticipants, setAlreadyThereParticipants] = useState({});
  const getParticipantsEmails = async (surveyId, empId) => {
    let resp = await get360SurveyParticipantEmails(surveyId, empId);
    if (resp) {
      setAlreadyThereParticipants(getValue(resp, 'data.participants', {}));
    }
  };
  const handleDownloadEmails = () => {
    if (invalidEmailsDownloadUrl) {
      saveAs(invalidEmailsDownloadUrl, 'Excel');
    } else {
      toast.error('No Emails Found', { className: 'red-circle' });
    }
  };

  const handleToggleUpload = () => {
    setToggleUpload(!toggleUpload);
  };
  const [toggleUpload, setToggleUpload] = useState(false);
  const [uploadData, setUploadData] = useState('');

  const import360Emails = async (e) => {
    setUploadData(e.target.files[0]);
  };
  const upload360Emails = async () => {
    let formData = new FormData();
    formData.append('file', uploadData);
    let resp = await import360SurveyEmails(surveyId, formData);
    if (resp) {
      handleToggleUpload();
    } else {
      handleToggleUpload();
    }
  };
  // ===========================================================================
  // End
  // ===========================================================================

  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const [empId, setEmpId] = useState('');
  const [surveyId, setSurveyId] = useState('');
  const handleModalWithParams = (empId, surveyId, userName) => {
    setEmpId(empId);
    setSurveyId(surveyId);
    setUserName(userName);
    handleModal();
  };

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };

  useEffect(() => {
    getSurveyList();
    Cookies.remove('progressSurvey');
    // Cookies.remove('template');
    // Cookies.remove('templateId');
    // Cookies.remove('templateEdit');
    Cookies.remove('progressSurvey');
    let access_token = localStorage.getItem('access_token');
    if (!access_token) {
      window.location.href = `${
        config.API_URL
      }auth/sso/stohrm/login?relay_state=${
        window.location.href
      }&tenant_id=${localStorage.getItem('tenant_id')}`;
    }
  }, []);

  // ===========================================================================
  // Api section
  // ===========================================================================
  const getSurveyList = async () => {
    try {
      setIsLoading(true);
      let resp = await getSurveyCollectorList(skip, limit, '', sort);
      if (resp) {
        setIsLoading(false);
        updateSurvey(getValue(resp, 'data.surveys', {}));
        setTotalCount(getValue(resp, 'data.count', 0));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // ===========================================================================
  // handle change section
  // ===========================================================================

  const onProductPageChangeHandler = async (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    let resp = await getSurveyCollectorList(skip, limit, '', sort);
    if (resp) {
      updateSurvey(getValue(resp, 'data.surveys', {}));
      setTotalCount(getValue(resp, 'data.count', 0));
    }
  };

  const history = useHistory();
  // const handleRedirectCreateSurvey = () => {
  //   history.push('/create-new-survey')
  // }

  return (
    <>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="page-padding">
            <div className="banner mb-4">
              <img
                src="/images/banner.svg"
                alt=""
                className="img-fluid w-100"
              />
            </div>
            {!isLoading ? (
              getValue(surveyDetails, `length`, 0) !== 0 ? (
                surveyDetails.map((list, key) => {
                  return (
                    <div className="card mb-3" key={key}>
                      <div className="card-body p-3">
                        <Row>
                          <Col md="6">
                            <div className="left-img-name">
                              <img
                                src={'/images/icon.svg'}
                                alt=""
                                width="36"
                                height="36"
                              />
                              {list.type === 'SURVEY360' ? (
                                <h5 className="detail-heading">
                                  {list.name}{' '}
                                  <span style={{ color: 'gray' }}>
                                    for{' '}
                                    <span style={{ color: '#D7242E' }}>
                                      {list.survey360Name}
                                    </span>
                                  </span>
                                </h5>
                              ) : (
                                <h5 className="detail-heading">{list.name}</h5>
                              )}
                              <p className="detail-para sm-txt">
                                {/* 33 Questions  */}
                                <span className="dot-grey mx-1"></span>{' '}
                                <b>
                                  {list.type
                                    ? list.type
                                        .split(' ')
                                        .map(
                                          (w) =>
                                            w[0].toUpperCase() +
                                            w.substr(1).toLowerCase()
                                        )
                                        .join(' ')
                                    : ''}
                                </b>{' '}
                                {list.survey360ParticipantType
                                  ? `(` + list.survey360ParticipantType + `)`
                                  : ''}
                                <span className="dot-grey mx-1"></span>
                                Questions - <b>{list.totalQuestions}</b>
                                <span className="dot-grey mx-1"></span> Launch
                                Date -{' '}
                                {getValue(list, 'launchDate', '') ? (
                                  <Moment format="DD MMM YYYY hh:mm a">
                                    {getValue(list, 'launchDate', '')}
                                  </Moment>
                                ) : (
                                  ''
                                )}
                                {/* 11 Dec 2020 */}
                              </p>
                            </div>
                          </Col>
                          {/* {!getValue(list, 'settings.isLearning', false) ? */}
                          <Col md="6" className="text-md-right">
                            {list.type === 'SURVEY360' &&
                            list.settings.enableAddParticipant === true &&
                            list.survey360ParticipantType === 'self' ? (
                              <Button
                                color="border btn-fs-16 mr-2 text-blue"
                                onClick={() =>
                                  handleModalWithParams(
                                    list.survey360EmpId,
                                    list.id,
                                    list.survey360Name
                                  )
                                }
                              >
                                Invite Participants
                              </Button>
                            ) : (
                              ''
                            )}
                            {list.takeSurveyStatus === 'submitted' ? (
                              <Button color="completed btn-fs-16">
                                {' '}
                                Completed
                              </Button>
                            ) : list.takeSurveyStatus === 'partial' ? (
                              <a href={`${list.surveyUrl}`}>
                                <Button color="orange btn-fs-16">
                                  Partially Completed
                                </Button>
                              </a>
                            ) : (
                              <a href={`${list.surveyUrl}`}>
                                <Button color="blue btn-fs-16">
                                  Get Started
                                </Button>
                              </a>
                            )}
                          </Col>
                          {/* :
                            <Col md="3" className="text-md-right">
                              {((list.type === 'SURVEY360') && (list.survey360ParticipantType === 'self') && (list.takeSurveyStatus !== 'submitted')) ? (
                                <Button color="block" onClick={() => handleModalWithParams(list.survey360EmpId, list.id, list.survey360Name)}>
                                  Invite Participants
                                </Button>
                              ) : ''}
                              {list.takeSurveyStatus === 'submitted' ? (
                                <Button color="completed"> Completed</Button>
                              ) : list.takeSurveyStatus === 'partial' ? (
                                <NavLink
                                  to={`take-survey-assessment/private/${list.surveyUrl}`}
                                >
                                  <Button color="orange">
                                    Partially Completed
                                  </Button>
                                </NavLink>
                              ) : (
                                <NavLink
                                  to={`take-survey-assessment/private/${list.surveyUrl}`}
                                >
                                  <Button color="blue btn-fs-16">
                                    Get Started
                                  </Button>
                                </NavLink>
                              )}
                            </Col>
                            } */}
                        </Row>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center mt-5">
                  <div className="mt-4 mb-2">
                    <img src="/images/empty.PNG" alt="" />
                  </div>
                  <h4 className="mb-2">No Survey Found</h4>
                  {/* <Button color="blue" onClick={handleRedirectCreateSurvey}>New Survey</Button> */}
                </div>
              )
            ) : (
              <div className="text-center my-3">
                <LoaderCommon />
              </div>
            )}
            {totalCount > 10 && (
              <Pagination
                activePage={pageNumber}
                itemsCountPerPage={limit}
                totalItemsCount={totalCount}
                pageRangeDisplayed={5}
                onChange={onProductPageChangeHandler}
                prevPageText="Prev"
                nextPageText="Next"
                hideFirstLastPages={true}
              />
            )}
          </div>
        </div>
      </div>
      <InviteParticipantSurveyModal
        handleModal={handleModal}
        isOpen={isOpen}
        addParticipants={addParticipants}
        removeParticipants={removeParticipants}
        handleEmailTemplateEmail={handleEmailTemplateEmail}
        add360EmailTemplate={add360EmailTemplate}
        loading360={loading360}
        simpleValidator={simpleValidator}
        emailTemplateOnchange={emailTemplateOnchange}
        participant={participants}
        handleDownloadEmails={handleDownloadEmails}
        modal={modal}
        toggleModal={toggleModal}
        errorEmails={errorEmails}
        handleToggleUpload={handleToggleUpload}
        toggleUpload={toggleUpload}
        import360Emails={import360Emails}
        upload360Emails={upload360Emails}
        uploadData={uploadData}
        userName={userName}
        getParticipantsEmails={getParticipantsEmails}
        empId={empId}
        surveyId={surveyId}
        toggleModal1={toggleModal1}
      />
      <AlreadyThereParticipants
        modal={modal1}
        toggleModal={toggleModal1}
        alreadyThereParticipants={alreadyThereParticipants}
      />
    </>
  );
};

export default HomePage;
