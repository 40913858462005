import * as React from 'react';
import { useEffect } from 'react';
import Moment from 'react-moment';
import { Button, Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { getValue } from '../../../core/lodash';
import ReactWordcloud from 'react-wordcloud';

interface IChartPreview2Props {
    questions: any
    qno: number
    type: string
}

const ChartPreview2: React.FunctionComponent<IChartPreview2Props> = (props) => {
    const [modal, setModal] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);

    const [words, setWords] = React.useState([])
    const toggleModal = () => {
        setModal(!modal)
    }
    const toggleModal2 = () => {
        setModal2(!modal2)
    }
    const {
        questions,
    } = props;
    console.log(props.questions)
    useEffect(() => {
        let mainArray = [];
        function count_duplicate(a) {
            let counts = {}
            for (let i = 0; i < a.length; i++) {
                if (counts[a[i]]) {
                    counts[a[i]] += 1
                } else {
                    counts[a[i]] = 1
                }
            }
            for (let prop in counts) {
                if (counts[prop] >= 2) {
                    mainArray.push({
                        text: prop,
                        value: counts[prop]
                    })
                    //console.log(prop + " counted: " + counts[prop] + " times.")
                } else {
                    mainArray.push({
                        text: prop,
                        value: counts[prop]
                    })
                }
            }
            //console.log(mainArray)
        }
        count_duplicate(getValue(props, `questions.response`, []))
        setWords(mainArray)
    }, [])
    return (
        <div>
            <Card className="mb-3">
                <CardBody>
                    {/* <h5 className="mb-1">Question {getValue(props, 'qno', 0)}:</h5> */}
                    <h3 className="mb-1">
                        {getValue(questions, 'text', '')}
                    </h3>
                    <p>
                        <span className="mr-3">Answered: {getValue(questions, 'resCount', 0)}</span> Skipped: {getValue(questions, 'skipped', 0)}
                    </p>
                    <div className="text-center">
                        {getValue(questions, 'resCount', '') === 0 ? "" :
                        <>
                            <Button color="nothing text-blue" onClick={toggleModal}>
                                Show {getValue(questions, 'resCount', 0)} Responses
                            </Button>{' '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {props.questions.type === 'SHORT_ANSWER' &&
                            <Button color="nothing text-blue" onClick={toggleModal2}>
                                View In Word Cloud
                            </Button>}
                        </>
                            }
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={modal} toggle={toggleModal} centered className="modal-lg">
                <ModalBody>
                    {/* <ReactWordcloud words={words} /> */}
                    {/* <h5 className="mb-1">Question 4:</h5> */}
                    <h3 className="mb-1">{getValue(questions, 'text', '')}</h3>
                    <hr className="my-2" />
                    <ol className="mt-3 answer-ol">
                        {getValue(questions, 'response', []).map((item, index) => {
                            if (getValue(props, 'type', '') === "DATE") {
                                return <li key={index}><Moment format="DD MMM YYYY">{item}</Moment></li>
                            } else {
                                return <li key={index}>{item}</li>
                            }
                        })}
                    </ol>

                </ModalBody>
            </Modal>
            <Modal isOpen={modal2} toggle={toggleModal2} centered className="modal-lg">
                <ModalBody>
                <h3 className="mb-1">{getValue(questions, 'text', '')}</h3>
                <hr className="my-2" />
                    <ReactWordcloud words={words} />
                    {/* <h5 className="mb-1">Question 4:</h5> */}
                    {/* <h3 className="mb-1">{getValue(questions, 'text', '')}</h3>
                    <hr className="my-2" />
                    <ol className="mt-3 answer-ol">
                        {getValue(questions, 'response', []).map((item, index) => {
                            if (getValue(props, 'type', '') === "DATE") {
                                return <li key={index}><Moment format="DD MMM YYYY">{item}</Moment></li>
                            } else {
                                return <li key={index}>{item}</li>
                            }
                        })}
                    </ol> */}

                </ModalBody>
            </Modal>
        </div>
    );
};

export default ChartPreview2;
