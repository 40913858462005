export function authHeaderOnlyToken(url) {
    // return authorization header with jwt token
    let access_token = localStorage.getItem('access_token');
    // let urls = url.split('/')[3];
    
    if (access_token) {
        // if(url.split('/')[3] !== 'survey-collector'){
        return { 'Authorization': 'Bearer ' + access_token,'Content-Type':'application/json' };
        // }else{
        //     return {'Content-Type':'application/json'};
        // }
    } else {
        return {'Content-Type':'application/json'};
    }
}