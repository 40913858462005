import { FunctionComponent, useState } from 'react';
import {
  Container,
  // NavLink as NavLink1,
  // Nav,
  // NavItem,
  // TabContent,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // TabPane,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  // Alert,
  Input,
} from 'reactstrap';
// import classnames from 'classnames';
// import RadioEmptySVG from '../../components/svg/radio-empty-svg';
// import RadioSVG from '../../components/svg/radio-svg';
// import AddSVG from 'components/svg/add-svg';
// import CKEditor from 'react-ckeditor-component';
// import ViewSVG from 'components/svg/view-svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getValue } from '../../core/lodash';
import moment from 'moment';

interface Props {
  handleChangeBooleanFourParams: (
    name: string,
    value: boolean,
    path: any,
    state: any
  ) => void;
  onChangePublishDate: (date: string, name: string) => void;
  handlePublishNow(): void;
  handleSavePublishNow(): void;
  publishNow: any;
  setPublishNow: any;
  handleChange: (evt: any, path: any, state: any) => void;
  surveyDetails: any;
  surveyType: string;
  publishLoading: boolean;
  loading:boolean;
  surveyQuestionType:string;
}

const ConfirmSurveyComponent: FunctionComponent<Props> = (props) => {
  const [activeTab1, setActiveTab1] = useState(true);
  const [activeTab2, setActiveTab2] = useState(false);

  const toggle1 = (status) => {
    setActiveTab1(status);
    setActiveTab2(!status);
  };

  const toggle2 = (status) => {
    setActiveTab2(status);
    setActiveTab1(!status);
  };
  return (
    <>
      <Container fluid={true} className=" border-bottom">
        <h6 className="text-center my-3">
          Review and start -{' '}
          <span style={{ color: 'blue' }}>
            {getValue(props.surveyDetails, 'name', '')}
          </span>
        </h6>
      </Container>
      <div className="page-padding">
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <Card>
              <CardBody>
                <Row>
                  <Col md="12"
                  //  className="border-right"
                  >
                    <div className={activeTab2 ? 'opacity-less' : ''}>
                      <Label className="radio-container mb-3">
                        Publish Now
                        <input
                          type="checkbox"
                          // onChange={() => {
                          //   toggle1(!activeTab1);
                          // }}
                          checked={activeTab1}
                        />
                        <span className="checkmark"></span>
                      </Label>
                      <Row
                        className={
                          props.publishNow.endDateEnabled ? '' : 'mb-3'
                        }
                      >
                        <Col md="6">
                          <h4 className="mb-1">Survey end date</h4>
                        </Col>
                        <Col md="6" className="text-right mt-1">
                          <Label className="switch">
                            <input
                              type="checkbox"
                              checked={props.publishNow.endDateEnabled}
                              onChange={() =>
                                props.handleChangeBooleanFourParams(
                                  'endDateEnabled',
                                  !props.publishNow.endDateEnabled,
                                  props.setPublishNow,
                                  props.publishNow
                                )
                              }
                            />
                            <span className="slider"></span>
                          </Label>
                        </Col>
                      </Row>
                      {props.publishNow.endDateEnabled && (
                        <div className="ml-5">
                          <div className="form-group single-datepicker">
                            <Label className="mb-0">
                              Set a date to stop collecting responses
                            </Label>
                            <Row>
                              <Col md="8">
                                <DatePicker
                                  block
                                  onChange={(date) =>
                                    props.onChangePublishDate(date, 'endDate')
                                  }
                                  className="form-control d-block"
                                  value={
                                    props.publishNow.endDate
                                      ? moment(props.publishNow.endDate).format(
                                        'DD MMM YYYY'
                                      )
                                      : props.publishNow.endDate
                                  }
                                  selected={props.publishNow.endDate}
                                  placeholderText="End Date"
                                  // format="MMM Do YY"
                                  minDate={new Date(new Date()).setDate(
                                    new Date(new Date()).getDate() + 1
                                  )}
                                />
                              </Col>
                            </Row>
                          </div>
                          {/* <div className="form-group">
                          <Row>
                            <Col md="4">
                              <Input type="select">
                                <option>01</option>
                              </Input>
                            </Col>
                            <Col md="4">
                              <Input type="select">
                                <option>00</option>
                              </Input>
                            </Col>
                            <Col md="4">
                              <Input type="select">
                                <option>AM</option>
                              </Input>
                            </Col>
                          </Row>
                        </div> */}
                        </div>
                      )}
                      {getValue(props, `surveyQuestionType`, ``) !== 'SURVEY360' &&
                        getValue(props, `surveyQuestionType`, ``) !== 'ASSESSMENT' ? (
                        <>
                          <Row
                            className={
                              props.publishNow.responseLimitEnabled
                                ? ''
                                : 'mb-3'
                            }
                          >
                            <Col md="6">
                              <h4 className="mb-1">Response limit</h4>
                            </Col>
                            <Col md="6" className="text-right mt-1">
                              <Label className="switch">
                                <input
                                  type="checkbox"
                                  checked={
                                    props.publishNow.responseLimitEnabled
                                  }
                                  onChange={() =>
                                    props.handleChangeBooleanFourParams(
                                      'responseLimitEnabled',
                                      !props.publishNow.responseLimitEnabled,
                                      props.setPublishNow,
                                      props.publishNow
                                    )
                                  }
                                />
                                <span className="slider"></span>
                              </Label>
                            </Col>
                          </Row>
                          {props.publishNow.responseLimitEnabled && (
                            <div className="ml-5">
                              <div className="form-group">
                                <Label className="mb-0">
                                  Set the maximum number of responses
                                </Label>
                                <Row>
                                  <Col md="8">
                                    <Input
                                      type="number"
                                      name="responseLimit"
                                      value={props.publishNow.responseLimit ? props.publishNow.responseLimit : ""}
                                      onChange={(evt) => {
                                        props.handleChange(
                                          evt,
                                          props.setPublishNow,
                                          props.publishNow
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        ''
                      )}

                      {getValue(props, `surveyQuestionType`, ``) !== 'SURVEY360' ? (
                        <>
                          {/* <Row
                            className={
                              props.publishNow.timeLimitEnabled ? '' : 'mb-3'
                            }
                          >
                            <Col md="8">
                              <h4 className="mb-1">Time limit</h4>
                            </Col>
                            <Col md="4" className="text-right mt-1">
                              <Label className="switch">
                                <input
                                  type="checkbox"
                                  checked={props.publishNow.timeLimitEnabled}
                                  onChange={() =>
                                    props.handleChangeBooleanFourParams(
                                      'timeLimitEnabled',
                                      !props.publishNow.timeLimitEnabled,
                                      props.setPublishNow,
                                      props.publishNow
                                    )
                                  }
                                />
                                <span className="slider"></span>
                              </Label>
                            </Col>
                          </Row>
                          {props.publishNow.timeLimitEnabled && (
                            <div className="ml-5">
                              <div className="form-group">
                                <Label className="mb-0">
                                  Total no. of minutes
                                </Label>
                                <Row>
                                  <Col md="8">
                                    <Input
                                      type="number"
                                      name="timeLimit"
                                      value={props.publishNow.timeLimit ? props.publishNow.timeLimit : ""}
                                      onChange={(evt) => {
                                        props.handleChange(
                                          evt,
                                          props.setPublishNow,
                                          props.publishNow
                                        );
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          )} */}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>

                  {/* <Col md="6">
                    <Label className="radio-container mb-3">
                      Publish Later
                      <input
                        type="checkbox"
                        onChange={() => {
                          toggle2(!activeTab2);
                        }}
                        checked={activeTab2}
                      />
                      <span className="checkmark"></span>
                    </Label>
                    <div className={activeTab1 ? 'opacity-less' : ''}>
                      <h4 className="mb-1">Schedule Type</h4>
                      <div className="form-group mb-2">
                        <Label className="radio-container">
                          One time schedule
                          <input type="radio" />
                          <span className="checkmark"></span>
                        </Label>
                      </div>
                      <div className="form-group">
                        <Label className="radio-container">
                          Recurring schedule
                          <input type="radio" />
                          <span className="checkmark"></span>
                        </Label>
                      </div>
                      <h4 className="mb-1">Survey start date</h4>
                      <div className="ml-5">
                        <div className="form-group">
                          <Label className="mb-0">
                            Set a date to start collecting responses
                          </Label>
                          <Input type="date" />
                        </div>
                        <div className="form-group">
                          <Row>
                            <Col md="4">
                              <Input type="select">
                                <option>01</option>
                              </Input>
                            </Col>
                            <Col md="4">
                              <Input type="select">
                                <option>00</option>
                              </Input>
                            </Col>
                            <Col md="4">
                              <Input type="select">
                                <option>AM</option>
                              </Input>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <h4 className="mb-1">Survey end date</h4>
                      <div className="ml-5">
                        <div className="form-group">
                          <Label className="mb-0">
                            Set a date to stop collecting responses
                          </Label>
                          <Input type="date" />
                        </div>
                        <div className="form-group">
                          <Row>
                            <Col md="4">
                              <Input type="select">
                                <option>01</option>
                              </Input>
                            </Col>
                            <Col md="4">
                              <Input type="select">
                                <option>00</option>
                              </Input>
                            </Col>
                            <Col md="4">
                              <Input type="select">
                                <option>AM</option>
                              </Input>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <h4 className="mb-1">Response limit</h4>
                      <div className="ml-5">
                        <div className="form-group">
                          <Label className="mb-0">
                            Set the maximum number of responses
                          </Label>
                          <Input type="text" />
                        </div>
                      </div>
                      <Row>
                        <Col md="8">
                          <h4 className="mb-1">Time limit</h4>
                        </Col>
                        <Col md="4" className="text-right mt-1">
                          <Label className="switch">
                            <input type="checkbox" />
                            <span className="slider"></span>
                          </Label>
                        </Col>
                      </Row>
                      <div className="ml-5" style={{ opacity: '0.5' }}>
                        <div className="form-group">
                          <Label className="mb-0">
                            Set the time limit for users
                          </Label>
                          <Row>
                            <Col md="4">
                              <Input
                                type="number"
                                name="timeLimit"
                                value={props.publishNow.timeLimit?props.publishNow.timeLimit:""}
                                onChange={(evt) => {
                                  props.handleChange(
                                    evt,
                                    props.setPublishNow,
                                    props.publishNow
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
               */}
                </Row>
              </CardBody>
            </Card>
            <div className="text-right btn-group-margin mt-4">
             {!props.loading
              ? <Button
                color="border"
                onClick={() => props.handleSavePublishNow()}
              >
                Save
              </Button>:
              <Button
                color="border"
              >
                Please wait...
              </Button>}
              {getValue(props, 'surveyDetails.settings.endDate', '') ?
                !getValue(props, 'publishLoading', false) ?
                  <Button color="blue" disabled={getValue(props, 'surveyDetails.status', '') === 'published' ? true : false} onClick={() => props.handlePublishNow()}>
                    {getValue(props, 'surveyDetails.status', '') === 'published' ? "Published" : "Publish"}
                  </Button> :
                  <Button color="blue">
                    Please wait...
                  </Button>
                : ""
              }

              {/* {getValue(props, 'surveyDetails.status', '') === 'published' ? (
                <Button
                  color="border"
                  onClick={() => props.handleSavePublishNow()}
                >
                  Save
                </Button>
              ) : (
                <Button color="blue" onClick={() => props.handlePublishNow()}>
                  Publish
                </Button>
              )} */}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ConfirmSurveyComponent;
