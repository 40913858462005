import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  Button,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import LeftNav from '../../components/menu/left-nav';
import {
  deleteSurvey,
  downloadAdvancedReport,
  downloadAdvancedReportStandard,
  downloadSimpleReport,
  getCompletedSurveys,
  downloadAdvancedReport360,
  downloadSimpleIndividualReport,
  downloadCombinedReport,
  downloadCombinedDetailedReport
} from '../../core/services/surveyService';
import { getValue } from '../../core/lodash';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import DeleteSurveyModal from '../../components/common/modal/deleteModal';
import Pagination from 'react-js-pagination';
import SearchSVG from '../../components/svg/search-svg';
import CloseSVG from '../../components/svg/close-svg';
import { NavLink, useHistory } from 'react-router-dom';
import LoaderCommon from '../../components/common/loader-common'
import moment from 'moment';
import 'moment-timezone';
import { saveAs } from "file-saver";
import MoreSVG from '../../components/svg/more-svg';


interface ITemplateMainProps { }

const ComplatedSurveyMain: React.FunctionComponent<ITemplateMainProps> = (
  props
) => {
  const [isLeftNav, manageLeftMenu] = useState(true);
  const [search, openSearch] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [isLeftMenu, manageMenu] = React.useState(false);
  const [completedSurvey, setCompletedSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // pagination
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [sort, setSort] = useState('newest')


  // delete
  const [isOpen, setIsOPen] = useState(false);
  const [removeValue, setRemoveValue] = useState('');
  const [deleteId, setDeleteId] = useState('');

  const handleModal = () => {
    setIsOPen(!isOpen);
  };

  const handleDeleteSurvey = async (id, value) => {
    setDeleteId(id);
    setRemoveValue(value);
    handleModal();
  };


  // ===========================================================================
  // handle change section
  // ===========================================================================

  const handleDeleteFun = async () => {
    try {
      let resp = await deleteSurvey(deleteId);
      if (resp) {
        toast.success('Deleted successfully'
          , { className: 'green-circle' }
        );
        handleModal();
        getOpenSurveyFun(skip);
      }
    } catch (error) {
      handleModal();
    }
  };

  const collapseLeftNav = () => {
    manageLeftMenu(!isLeftNav);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };

  const onProductPageChangeHandler = (pageNumber) => {
    setPageNumber(pageNumber);
    let skip = (pageNumber - 1) * 10;
    setSkip(skip);
    getOpenSurveyFun(skip);
  };

  // ===========================================================================
  // api section
  // ===========================================================================

  useEffect(() => {
    getOpenSurveyFun(skip);
  }, []);

  const getOpenSurveyFun = async (skip) => {
    try {
      setIsLoading(true);
      let resp = await getCompletedSurveys(skip, limit, '', sort);
      if (resp) {
        setIsLoading(false);
        setCompletedSurveys(getValue(resp, 'data.surveys', []));
        setTotalCount(getValue(resp, 'data.count', []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // ===========================================================================
  // search section
  // ===========================================================================

  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
    setSearchInput(value);
    globalSearch(value);
  };

  const toggleSearch = () => {
    openSearch(!search);
    setSearchInput('');
    globalSearch('');
  };

  const globalSearch = async (value) => {
    try {
      let response = await getCompletedSurveys(skip, limit, value, sort);
      if (response) {
        setCompletedSurveys(getValue(response, 'data.surveys', []));
        setTotalCount(getValue(response, 'data.count', []));
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const history = useHistory();

  // const handleRedirectCreateSurvey = () => {
  //   history.push('/create-new-survey')
  // }


  const handleDownloadAdvanced = async (surveyId) => {
    let resp = await downloadAdvancedReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, "Excel");
    }
  };

  const handleDownloadSimple = async (surveyId) => {
    let resp = await downloadSimpleReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, "Excel");
    }
  };
  const handleDownloadAdvancedStandard = async (surveyId) => {
    let resp = await downloadAdvancedReportStandard(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, "Excel");
    }
  };
  const handleDownloadAdvanced360 = async (surveyId) => {
    let resp = await downloadAdvancedReport360(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, "Excel");
    }
  };
  const handleIndividualDownload = async (surveyId) => {
    let resp = await downloadSimpleIndividualReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, "Excel");
    }
  };



  const handleDownloadCombinedReport = async (surveyId) => {
    let resp = await downloadCombinedReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, "Excel");
    }
  };

  const handleDownloadCombinedDetailedReport = async (surveyId) => {
    let resp = await downloadCombinedDetailedReport(surveyId);
    if (resp) {
      saveAs(resp.data.downloadUrl, "Excel");
    }
  };

  return (
    <div>
      <div className={'page-layout ' + (isLeftNav ? 'active' : '')}>
        <LeftNav
          isLeftNav={isLeftNav}
          collapseLeftNav={() => collapseLeftNav()}
        />
        <div className="page-height">
          <div className="create-survey-tabs create-survey-tabs-search">
            <Nav pills className="basic-tabs pl-0">
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                  Completed Surveys
                </NavLink1>
              </NavItem>
              {/* <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                >
                  Scheduled Surveys
                </NavLink1>
              </NavItem> */}
            </Nav>
            <div className="hover-circle">
              <Button color="nothing" onClick={toggleSearch}>
                <SearchSVG />
              </Button>
              <div className={'table-search ' + (search ? 'show' : '')}>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  onChange={handleChangeSearch}
                  value={searchInput}
                />
                <Button
                  color="nothing"
                  onClick={toggleSearch}
                  className="table-search-close"
                >
                  <CloseSVG />
                </Button>
              </div>
            </div>
          </div>
          <div className="page-padding pt-0">
            <TabContent activeTab={activeTab}>
              {!isLoading ? (
                completedSurvey.length !== 0
                  ?
                  <TabPane tabId="1">
                    <Table className="table-custom">
                      <thead>
                        <tr>
                          <th>Survey Name</th>
                          <th>Survey Type</th>
                          <th>Launch Date & Time</th>
                          <th>End Date</th>
                          <th>Action</th>
                          <th style={{ width: '50' }} className="text-right"></th>
                        </tr>
                      </thead>
                      {completedSurvey.map((survey, key) => {

                        let date = moment(survey.launchDate).tz("Asia/Kolkata").format("DD MMM YYYY");
                        let time = moment(survey.launchDate).tz("Asia/Kolkata").format("hh:mm a");
                        return (
                          <tbody key={key}>
                            <tr>
                              <td>
                                <h5>
                                  <NavLink
                                    to={
                                      survey.type === 'SURVEY360'
                                        ? `/360-summary/${survey.id}/completed-surveys`
                                        : `/summary/${survey.id}/completed-surveys`
                                    }
                                    style={{ color: '#276ef1' }}
                                  >
                                    {survey.name}
                                  </NavLink>
                                </h5>
                              </td>
                              <td>
                                {survey.type
                                  ? survey.type
                                    .split(' ')
                                    .map(
                                      (w) =>
                                        w[0].toUpperCase() +
                                        w.substr(1).toLowerCase()
                                    )
                                    .join(' ')
                                  : ''}
                              </td>
                              <td>
                                <h5>
                                  {date}
                                </h5>
                                <p>
                                  {time}
                                </p>
                              </td>
                              <td>
                                <h5>
                                  <Moment format="DD MMM YYYY">
                                    {survey.settings.endDate}
                                  </Moment>
                                </h5>
                                <p>
                                  <Moment format="hh:mm a">
                                    {survey.settings.endDate}
                                  </Moment>
                                </p>
                              </td>

                              {/* <td>
                                <h5>
                                  {getValue(survey, 'totalResponse', '-')}
                                </h5>
                                <p>
                                  Out of{' '}
                                  {getValue(survey, 'responseLimit', '-')}
                                </p>
                              </td> */}
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle color="nothing">
                                    <MoreSVG />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="custom-dropdown3"
                                    right
                                  >
                                    {survey.type !== 'SURVEY360' &&
                                      <>
                                        <DropdownItem>
                                          Download Standard Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadAdvancedStandard(survey.id)
                                          }
                                        >
                                          Download Summary Report
                                        </DropdownItem>
                                      </>

                                    }
                                    {survey.type === 'SURVEY360' &&
                                      <>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadSimple(survey.id)
                                          }
                                        >
                                          Download Simple Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadAdvanced(survey.id)
                                          }
                                        >
                                          Download Advanced Report
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadAdvanced360(survey.id)
                                          }
                                        >
                                          Download Summary Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleIndividualDownload(survey.id)
                                          }
                                        >
                                          Download Individual Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadCombinedReport(survey.id)
                                          }
                                        >
                                          Download Combined Report
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() =>
                                            handleDownloadCombinedDetailedReport(survey.id)
                                          }
                                        >
                                          Download Combined Detailed Report
                                        </DropdownItem>
                                      </>}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                      }
                    </Table>
                  </TabPane>
                  :
                  <div className="text-center mt-5">
                    <div className="mt-4 mb-2">
                      <img src="/images/empty.PNG" alt="" />
                    </div>
                    <h4 className="mb-2">No Survey Found</h4>
                    {/* <Button color="blue" onClick={handleRedirectCreateSurvey}>New Survey</Button> */}
                  </div>
              ) : (
                <div className="text-center my-3"><LoaderCommon /></div>
              )}
              {totalCount > 10 && (
                <Pagination
                  activePage={pageNumber}
                  itemsCountPerPage={limit}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={onProductPageChangeHandler}
                  prevPageText="Prev"
                  nextPageText="Next"
                  hideFirstLastPages={true}
                />
              )}
              <TabPane tabId="2">Lorem ipsum</TabPane>
            </TabContent>
          </div>
        </div>
      </div>
      <DeleteSurveyModal
        isOpen={isOpen}
        handleDeleteFun={handleDeleteFun}
        removeValue={removeValue}
        handleModal={handleModal}
      />
    </div>
  );
};

export default ComplatedSurveyMain;
