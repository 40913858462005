import { Col, Row, Label, Input } from 'reactstrap';

import * as React from 'react';
import AdvancedOptions from './AdvancedOptions';

interface ITimeProps {
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  surveyType: string;
}

const Time: React.FunctionComponent<ITimeProps> = (props) => {
  return (
    <>
      {/* <div className="form-group">
        <Label className="radio-container">
          <Row>
            <Col md="1">Time</Col>
            <Col md="2">_:_AM/PM</Col>
          </Row>
          <input type="radio" />
          <span className="checkmark"></span>
        </Label>
      </div>
      <div className="form-group">
        <Label className="radio-container">
          <Row>
            <Col md="1">Duration</Col>
            <Col md="2">_:_:_Hrs/Min/Sec</Col>
          </Row>
          <input type="radio" />
          <span className="checkmark"></span>
        </Label>
      </div> */}
      <div>
        <h5>Advance Options:</h5>
        <AdvancedOptions
              surveyType={props.surveyType}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          questionIndex={props.questionIndex}
          pageIndex={props.pageIndex}
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChange}
          questionInfo={props.questionInfo}
        />
      </div>
    </>
  );
};

export default Time;
