import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import * as React from 'react';
const Footer = () => (
    <>
        <div className="footer">
            <Container fluid={true}>
                <ul>
                    <li>
                        &copy; 2021 AscentHR
                    </li>
                    <li>
                        <NavLink to="/terms-of-service">
                            Terms of Service
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/privacy-policy">
                            Privacy Policy
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/send-feedback">
                            Send Feedback
                        </NavLink>
                    </li>
                </ul>
            </Container>
        </div>
    </>
)
export default Footer;
