import * as React from 'react';
import { Label, Input, Row, Col } from 'reactstrap';
import { getValue } from '../../core/lodash';

interface IMultipleChoiceGridProps {
  questions: object;
}

const MultipleChoiceGrid: React.FunctionComponent<IMultipleChoiceGridProps> = (
  props
) => {
  return (
    <table className="table table-borderless">
      {getValue(props.questions, `row`, []).map((rowData, i) => {
        return (
          <>
            {i === 0 ? (
              <thead>
                <tr>
                  <td></td>
                  {getValue(props.questions, `columns`, []).map((cols) => {
                    return (
                      <td className="text-center">
                        <p className="mb-0 text-capitalize">
                          {getValue(cols, `text`, '')}
                        </p>
                      </td>
                    );
                  })}
                </tr>
              </thead>
            ) : (
              ''
            )}
            <tbody>
              <tr>
                <td>
                  <p className="mb-0 text-capitalize">
                    {getValue(rowData, 'text', '')}
                  </p>
                </td>
                {getValue(props.questions, `columns`, []).map((items) => {
                  let a = getValue(props, 'questions.response', []).filter(
                    (ite) =>
                      ite.columnId === items.id &&
                      getValue(rowData, 'id', '') === ite.rowId
                  );
                  return (
                    <td className="text-center">
                      <div className="form-group mb-0">
                        <Label className="radio-container d-inline-block pl-4 ml-1">
                          <Input
                            type="radio"
                            placeholder="Enter here"
                            checked={a.length !== 0 ? true : false}
                            // onChange={() => {
                            //   props.handleUserSurveyResponseDropdownGrid(
                            //     getValue(selected[0], 'questionId', ''),
                            //     getValue(props.questions, 'type', ''),
                            //     rowData.id,
                            //     items.id
                            //   );
                            // }}
                          />
                          <span className="checkmark"></span>
                        </Label>
                      </div>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </>
        );
      })}
    </table>
  );
};

export default MultipleChoiceGrid;
