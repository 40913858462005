import React from 'react';

const Smiley3SVG = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36.005"
      height="36.005"
      viewBox="0 0 36.005 36.005"
    >
      <defs>
        <style></style>
      </defs>
      <g transform="translate(0.783 0.783)">
        <path
          class="a"
          style={{ fill: '#feca2e' }}
          d="M31.481,23.654a1.565,1.565,0,1,0-1.565-1.565A1.57,1.57,0,0,0,31.481,23.654ZM25.22,8A17.22,17.22,0,1,1,8,25.22,17.22,17.22,0,0,1,25.22,8Zm-4.7,14.089a1.565,1.565,0,1,0-1.565,1.565A1.57,1.57,0,0,0,20.523,22.089Z"
          transform="translate(-8 -8)"
        />
        <circle
          class="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(22.22 12.22)"
        />
        <circle
          class="a"
          style={{ fill: '#feca2e' }}
          cx="1.5"
          cy="1.5"
          r="1.5"
          transform="translate(9.22 12.22)"
        />
      </g>
      <path
        class="b"
        style={{ fill: '#6d4c41' }}
        d="M18,0A18,18,0,1,0,36,18,18.023,18.023,0,0,0,18,0Zm0,34.439A16.437,16.437,0,1,1,34.439,18,16.455,16.455,0,0,1,18,34.439Z"
      />
      <path
        class="b"
        style={{ fill: '#6d4c41' }}
        d="M100.7,130.348a2.348,2.348,0,1,0-2.348,2.348A2.351,2.351,0,0,0,100.7,130.348Zm-3.131,0a.783.783,0,1,1,.783.783A.784.784,0,0,1,97.565,130.348Z"
        transform="translate(-86.607 -115.477)"
      />
      <path
        class="b"
        style={{ fill: '#6d4c41' }}
        d="M226.348,128a2.342,2.342,0,0,0-.783,4.552,2.019,2.019,0,0,0,1.565,0,2.342,2.342,0,0,0-.783-4.552Zm0,3.131a.783.783,0,1,1,.783-.783A.784.784,0,0,1,226.348,131.131Z"
        transform="translate(-202.084 -115.477)"
      />
      <path
        class="b"
        style={{ fill: '#6d4c41' }}
        d="M111.827,224a10.259,10.259,0,0,0-7.627,3.391.783.783,0,1,0,1.165,1.046,8.708,8.708,0,0,1,12.923,0,.782.782,0,1,0,1.166-1.043A10.235,10.235,0,0,0,111.827,224Z"
        transform="translate(-93.825 -202.084)"
      />
      <path
        class="b"
        style={{ fill: '#6d4c41' }}
        d="M224.431,65.477l3.131,1.565a.783.783,0,0,0,.7-1.4l-3.131-1.565a.783.783,0,0,0-.7,1.4Z"
        transform="translate(-202.082 -57.732)"
      />
      <path
        class="b"
        style={{ fill: '#6d4c41' }}
        d="M96.777,67.129a.785.785,0,0,0,.349-.082l3.131-1.565a.783.783,0,1,0-.7-1.4l-3.131,1.565a.782.782,0,0,0,.351,1.482Z"
        transform="translate(-86.601 -57.736)"
      />
    </svg>
  </>
);
export default Smiley3SVG;
