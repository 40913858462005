import * as React from 'react';
import { Row, Col, Label, Button, Card, CardBody, Input } from 'reactstrap';
import { getValue } from '../../core/lodash';
import EditSVG from '../../components/svg/edit-svg';
import ViewSVG from '../../components/svg/view-svg';
import Cookies from 'js-cookie';
import AddSVG from '../../components/svg/add-svg';
import UploadSVG from '../../components/svg/upload-svg';
import DeleteSVG from '../../components/svg/delete-svg';
import PageSVG from '../../components/svg/page-svg'
import CheckSVG from '../../components/svg/check-svg';
import FlagSVG from '../../components/svg/flag-svg';
import CopySVG from '../../components/svg/copy-svg';
import DropdownList from '../../components/dropdown/DropdownList';
import Question from '../../components/questions/question';
import RenderQuestionType from '../../components/common/RenderQuestionType';
import { NavLink } from 'react-router-dom';
import CloseSVG from 'components/svg/close-svg';

interface IRenderSurveyQuestionProps {
  toggleModal: () => void;
  surveyDetails: any;
  surveyId: string;
  // handleSubmitSurveyTemplate: () => void;
  activeTabPage: any;
  questionsInfo: any;
  handleChangePage: (index: number) => void;
  handleNavigate: (e: any) => void;
  addNewPage: () => void;
  toggleModal2: () => void;
  deletePage: (pageIndex: number, pageId: string, value: any) => void;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  handleCopy: (pageIndex: number, questionIndex: number) => void;
  deleteQuestion: (
    pageIndex: number,
    questionIndex: number,
    pageId: string,
    surveyId: string,
    questionId: string
  ) => void;
  handleQuestionType: (
    value: any,
    pageIndex: number,
    questionIndex: number,
    type: string
  ) => void;
  handleEditQuestion: (
    evt: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleChangeQuestion: (
    evt: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  enableSideMenuBulkAnswer: (pageIndex: number, quesionIndex: number) => void;
  handleQuestionTypeValues: (
    e: any,
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  addMoreOption: (value: any, pageIndex: number, questionIndex: number) => void;
  removeMoreOption: (
    index: number,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleScore: (
    index: number,
    name: string,
    value: number,
    updateState: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  addPredefinedOption: (
    value: any,
    pageIndex: number,
    questionIndex: number
  ) => void;

  handleDateQuestionType: (e: any, pageIndex: any, questionIndex: any) => void;

  handleQuestionTypeDropdown: (
    e: object,
    pageIndex: number,
    questionIndex: number,
    maxLength: string
  ) => void;
  handleQuestionTypeInputBox: (
    e: any,
    pageIndex: number,
    questionIndex: number
  ) => void;

  handleFullName: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  handleGridData: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleGridDataColumn: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleAddGridDataRow: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleAddGridDataColumn: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleDeleteGridRow: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  handleDeleteGridColumn: (
    pageIndex: number,
    questionIndex: number,
    colIndex: number
  ) => void;
  handleRatingScaleDropdownString: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    param: any
  ) => void;
  handleScoreInputBoxEdit: (
    optionIndex: number,
    e: any,
    updateState: any,
    pageIndex: number,
    questionIndex: number
  ) => void;
  handleFullNameCheckbox: (
    status: boolean,
    pageIndex: number,
    questionIndex: number,
    optionIndex: number
  ) => void;
  handleAssessmentCorrectAnswer: (
    optionIndex: number,
    status: boolean,
    updateState: any,
    pageIndex: number,
    questionIndex: number,
    type:string
  ) => void;
  handleAddNewQuestion: (e: any) => void;
  handleAddQuestion: (
    pageIndex: number,
    questionIndex: number,
    pageId: string,
    surveyId: string
  ) => void;
  handleUpdateQuestion: (
    pageIndex: number,
    questionIndex: number,
    pageId: string,
    surveyId: string,
    questionId: string
  ) => void;
  handleGridDataColumnScore: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    colIndex: number,
    rowIndex: number
  ) => void;
  uploadExcelFile: (e: any) => void;

  setQuestionsInfo: any;
  questionTypes: any;
  isLoading: boolean;
  loading2: boolean;
  handleToggleUpload: () => void;
  handleToggleUpload1:()=>void;
  toggleUpload: boolean;
  handleUploadExcelFile: () => void;
  handleUploadExcelFile1:()=>void;
  surveyTemplate:string;
  uploadExcelFile1:(e:any)=>void;
  toggleUpload1:boolean;
}
const RenderSurveyQuestion: React.FunctionComponent<IRenderSurveyQuestionProps> = (
  props
) => {
  const {
    surveyDetails,
    toggleModal,
    surveyId,
    // handleSubmitSurveyTemplate,
    activeTabPage,
    questionsInfo,
    handleChangePage,
    handleNavigate,
    addNewPage,
    toggleModal2,
    deletePage,
    handleChangeQuestionAdvancedOptions,
    setQuestionsInfo,
    handleCopy,
    deleteQuestion,
    handleQuestionType,
    questionTypes,
    handleEditQuestion,
    handleChangeQuestion,
    enableSideMenuBulkAnswer,
    handleQuestionTypeValues,
    addMoreOption,
    removeMoreOption,
    handleScore,
    addPredefinedOption,
    handleDateQuestionType,
    handleQuestionTypeDropdown,
    handleQuestionTypeInputBox,
    handleFullName,
    handleGridData,
    handleAddGridDataRow,
    handleAddGridDataColumn,
    handleGridDataColumn,
    handleGridDataColumnScore,
    handleDeleteGridRow,
    handleDeleteGridColumn,
    handleRatingScaleDropdownString,
    handleScoreInputBoxEdit,
    handleFullNameCheckbox,
    handleAssessmentCorrectAnswer,
    handleAddNewQuestion,
    isLoading,
    handleAddQuestion,
    handleUpdateQuestion,
  } = props;
  return (
    <React.Fragment>
      <div className="page-height">
        <div className="page-padding">
          <Row>
            <Col lg={{ size: 5, offset: 1 }}>
              <div className="form-group">
                <Label>Survey Name:</Label>
                <p className="p-edit-text">
                  {getValue(surveyDetails, 'name', '')}{' '}
                  <Button color="nothing" onClick={toggleModal}>
                    <EditSVG />
                  </Button>
                </p>
              </div>
            </Col>
            <Col lg="5" className="text-right">
              <div className="btn-group-margin">
                {/* <Button color="icon">
                        <PageSVG />
                      </Button> */}
                {getValue(surveyDetails, 'type', '') === 'SURVEY360' ?(
                  <>
                    <Button color="icon" className="icon-upload-drop">
                      <div onClick={props.handleToggleUpload}>
                        <UploadSVG />
                      </div>
                      {props.toggleUpload && (
                        <>
                          <div className="upload-drop">
                            <input
                              type="file"
                              name="xlsFile"
                              id="xlsFile"
                              accept=".xls, .xlsx"
                              onChange={props.uploadExcelFile}
                            />
                            <Button
                              color="nothing"
                              className="m-0"
                              onClick={props.handleToggleUpload}
                            >
                              <CloseSVG />
                            </Button>
                            <div className="text-right">
                              <Button
                                color="border"
                                className="px-2 m-0 text-blue py-0"
                                onClick={props.handleUploadExcelFile}
                              >
                                Upload
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Button>
                  </>
                ):
                (
                  <>
                  <Button color="icon" className="icon-upload-drop">
                    <div onClick={props.handleToggleUpload1}>
                      <UploadSVG />
                    </div>
                    {props.toggleUpload1 && (
                      <>
                        <div className="upload-drop">
                          <input
                            type="file"
                            name="xlsFile"
                            id="xlsFile"
                            accept=".xls, .xlsx"
                            onChange={props.uploadExcelFile1}
                          />
                          <Button
                            color="nothing"
                            className="m-0"
                            onClick={props.handleToggleUpload1}
                          >
                            <CloseSVG />
                          </Button>
                          <div className="text-right">
                            <Button
                              color="border"
                              className="px-2 m-0 text-blue py-0"
                              onClick={props.handleUploadExcelFile1}
                            >
                              Upload
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Button>
                </>
                )}
                <NavLink to={`/survey/${getValue(surveyDetails, 'type', '')}/${surveyId}`}>
                  <Button color="icon">
                    <ViewSVG />
                  </Button>
                </NavLink>
                {/* {Cookies.get('template') && (
                  <Button
                    className="mt-2"
                    color="blue"
                    onClick={handleSubmitSurveyTemplate}
                  >
                    Submit Template
                  </Button>
                )}
                {Cookies.get('templateEdit') && (
                  <Button
                    className="mt-2"
                    color="blue"
                    onClick={handleSubmitSurveyTemplate}
                  >
                    Update Template
                  </Button>
                )} */}
              </div>
            </Col>
          </Row>
        </div>
        <div className="add-survey-line">
          <div className="page-padding py-0">
            <div className="line-relative">
              <ul className="line-relative-slick">
                {questionsInfo.map((_item, index) => (
                  <li key={index}>
                    <Button
                      className={
                        index === parseFloat(activeTabPage) - 1 ? 'active' : ''
                      }
                      color="nothing"
                      onClick={() => handleChangePage(index + 1)}
                    >
                      {index + 1}
                    </Button>
                  </li>
                ))}
              </ul>
              <Button
                color="nothing"
                className="left-prev"
                disabled={parseFloat(activeTabPage) - 1 === 0 ? true : false}
                onClick={() => {
                  handleNavigate(parseFloat(activeTabPage) - 1);
                }}
              >
                <img src="/images/slick-fonts/left.svg" alt="" />
              </Button>
              <Button
                color="nothing"
                className="right-next"
                disabled={
                  parseFloat(activeTabPage) - 1 === questionsInfo.length - 1
                    ? true
                    : false
                }
                onClick={() => {
                  handleNavigate(parseFloat(activeTabPage) + 1);
                }}
              >
                <img src="/images/slick-fonts/right.svg" alt="" />
              </Button>
              <Button color="orange" onClick={() => addNewPage()}>
                <AddSVG />
              </Button>
            </div>
          </div>
        </div>
        <div className="page-padding mt-4">
          <Row>
            <Col lg={{ size: 10, offset: 1 }}>
              <Card className="mb-3" onClick={toggleModal2}>
                <CardBody className="text-center card-untitled">
                  <h5 className="detail-heading">
                    {getValue(
                      questionsInfo[parseFloat(activeTabPage) - 1],
                      'title',
                      ''
                    )}
                  </h5>
                  <p className="detail-para sm-txt">
                    {getValue(
                      questionsInfo[parseFloat(activeTabPage) - 1],
                      'description',
                      ''
                    )}
                  </p>
                  <Button color="nothing" className="card-right-icon blue-svg">
                    <AddSVG />
                  </Button>
                </CardBody>
              </Card>
              <hr />
              <div className="text-center">
                <Button
                  color="nothing"
                  className="red-text-svg"
                  onClick={() => {
                    deletePage(
                      parseFloat(activeTabPage) - 1,
                      questionsInfo[parseFloat(activeTabPage) - 1].id,
                      getValue(
                        questionsInfo[parseFloat(activeTabPage) - 1],
                        'title',
                        ''
                      )
                    );
                  }}
                  disabled={parseFloat(activeTabPage) - 1 === 0 ? true : false}
                >
                  <DeleteSVG /> Delete this page
                </Button>
              </div>
              <hr />
              <>
                {questionsInfo &&
                  questionsInfo.map((pageItem, pageIndex) => {
                    if (pageIndex === parseFloat(activeTabPage) - 1) {
                      return getValue(pageItem, 'questions', []).map(
                        (item, questionIndex) => {
                          return (
                            <div key={pageIndex}>
                              <Row className="left-right-icons">
                                <Col md="6">
                                  <h5 className="mt-1">
                                    Question {questionIndex + 1}
                                  </h5>
                                  <div className="btn-group-margin">
                                    {!getValue(item, `review`, '') ? (
                                      <Button
                                        color="nothing"
                                        onClick={() =>
                                          handleChangeQuestionAdvancedOptions(
                                            'review',
                                            !getValue(item, `review`, ''),
                                            setQuestionsInfo,
                                            pageIndex,
                                            questionIndex
                                          )
                                        }
                                      >
                                        <CheckSVG />
                                      </Button>
                                    ) : (
                                      <Button
                                        color="nothing blue-text-svg"
                                        onClick={() =>
                                          handleChangeQuestionAdvancedOptions(
                                            'review',
                                            !getValue(item, `review`, ''),
                                            setQuestionsInfo,
                                            pageIndex,
                                            questionIndex
                                          )
                                        }
                                      >
                                        <CheckSVG />
                                      </Button>
                                    )}
                                    {!getValue(item, `flag`, '') ? (
                                      <Button
                                        onClick={() =>
                                          handleChangeQuestionAdvancedOptions(
                                            'flag',
                                            !getValue(item, `flag`, ''),
                                            setQuestionsInfo,
                                            pageIndex,
                                            questionIndex
                                          )
                                        }
                                        color="nothing"
                                      >
                                        <FlagSVG />
                                      </Button>
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          handleChangeQuestionAdvancedOptions(
                                            'flag',
                                            !getValue(item, `flag`, ''),
                                            setQuestionsInfo,
                                            pageIndex,
                                            questionIndex
                                          )
                                        }
                                        color="nothing blue-text-svg"
                                      >
                                        <FlagSVG />
                                      </Button>
                                    )}
                                    <Button
                                      color="nothing"
                                      onClick={() =>
                                        deleteQuestion(
                                          pageIndex,
                                          questionIndex,
                                          pageItem.id,
                                          surveyId,
                                          item.id
                                        )
                                      }
                                      disabled={
                                        questionIndex === 0 ? true : false
                                      }
                                    >
                                      <DeleteSVG />
                                    </Button>
                                    <Button
                                      color="nothing"
                                      // onClick={() =>
                                      //   copyQuestion(pageIndex, questionIndex)
                                      // }
                                      onClick={() =>
                                        handleCopy(pageIndex, questionIndex)
                                      }
                                    >
                                      <CopySVG />
                                    </Button>
                                  </div>
                                </Col>
                                <Col md="6" className="text-md-right">
                                  {/* <NavLink
                                          color="nothing btn-fs-16"
                                          className="mr-3"
                                          to={`/question-bank/${surveyId}`}
                                        >
                                          <SearchSVG />
                                          Question Bank
                                        </NavLink> */}
                                  <DropdownList
                                    handleQuestionType={handleQuestionType}
                                    type={item.type}
                                    pageIndex={pageIndex}
                                    questionIndex={questionIndex}
                                    questionTypes={questionTypes}
                                  />
                                </Col>
                              </Row>
                              <Question
                                surveyId={surveyId}
                                questionIndex={questionIndex}
                                pageIndex={parseFloat(activeTabPage) - 1}
                                item={getValue(
                                  questionsInfo[pageIndex],
                                  `questions[${questionIndex}]`,
                                  ''
                                )}
                                questionsInfo={questionsInfo}
                                handleEditQuestion={handleEditQuestion}
                              />
                              <p>
                                <Label className="checkbox-container fs-16 pt-1">
                                  Make this question mandatory
                                  <input
                                    type="checkbox"
                                    checked={getValue(
                                      item,
                                      `mandatoryEnabled`,
                                      ''
                                    )}
                                    onChange={() =>
                                      handleChangeQuestionAdvancedOptions(
                                        'mandatoryEnabled',
                                        !getValue(item, `mandatoryEnabled`, ''),
                                        setQuestionsInfo,
                                        pageIndex,
                                        questionIndex
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </Label>
                                {getValue(item, `mandatoryEnabled`, '') ? (
                                  <Row>
                                    <Col md="6">
                                      <div className="form-group ml-4 pl-2 mt-2">
                                        <Input
                                          type="text"
                                          value={getValue(
                                            item,
                                            'mandatoryMsg',
                                            ''
                                          )}
                                          name="mandatoryMsg"
                                          onChange={(evt) =>
                                            handleChangeQuestion(
                                              evt,
                                              pageIndex,
                                              questionIndex
                                            )
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                ) : (
                                  ''
                                )}
                              </p>
                              <RenderQuestionType
                                surveyType={getValue(surveyDetails, 'type', '')}
                                enableSideMenuBulkAnswer={
                                  enableSideMenuBulkAnswer
                                }
                                param={item.type}
                                pageIndex={pageIndex}
                                questionIndex={questionIndex}
                                questionInfo={questionsInfo}
                                setQuestionsInfo={setQuestionsInfo}
                                surveyId={surveyId}
                                handleQuestionTypeValues={
                                  handleQuestionTypeValues
                                }
                                addMoreOption={addMoreOption}
                                removeMoreOption={removeMoreOption}
                                handleChangeQuestionAdvancedOptions={
                                  handleChangeQuestionAdvancedOptions
                                }
                                handleScore={handleScore}
                                addPredefinedOption={addPredefinedOption}
                                handleChangeQuestion={handleChangeQuestion}
                                handleDateQuestionType={handleDateQuestionType}
                                handleQuestionTypeDropdown={
                                  handleQuestionTypeDropdown
                                }
                                handleQuestionTypeInputBox={
                                  handleQuestionTypeInputBox
                                }
                                handleFullName={handleFullName}
                                handleGridData={handleGridData}
                                handleAddGridDataRow={handleAddGridDataRow}
                                handleAddGridDataColumn={
                                  handleAddGridDataColumn
                                }
                                handleGridDataColumn={handleGridDataColumn}
                                handleGridDataColumnScore={
                                  handleGridDataColumnScore
                                }
                                handleDeleteGridRow={handleDeleteGridRow}
                                handleDeleteGridColumn={handleDeleteGridColumn}
                                handleRatingScaleDropdownString={
                                  handleRatingScaleDropdownString
                                }
                                handleScoreInputBoxEdit={
                                  handleScoreInputBoxEdit
                                }
                                handleFullNameCheckbox={handleFullNameCheckbox}
                                handleAssessmentCorrectAnswer={
                                  handleAssessmentCorrectAnswer
                                }
                              />
                              {!getValue(item, 'id', '') ? (
                                !isLoading ? (
                                  <Button
                                    className="mt-2"
                                    color="blue btn-fs-16"
                                    onClick={() =>
                                      handleAddQuestion(
                                        parseFloat(activeTabPage) - 1,
                                        questionIndex,
                                        questionsInfo[pageIndex].id,
                                        questionsInfo[pageIndex].surveyId
                                      )
                                    }
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    className="mt-2"
                                    color="blue btn-fs-16"
                                  >
                                    Please wait...
                                  </Button>
                                )
                              ) : !isLoading ? (
                                <Button
                                  className="mt-2"
                                  color="blue btn-fs-16"
                                  onClick={() =>
                                    handleUpdateQuestion(
                                      parseFloat(activeTabPage) - 1,
                                      questionIndex,
                                      questionsInfo[pageIndex].id,
                                      questionsInfo[pageIndex].surveyId,
                                      getValue(item, 'id', '')
                                    )
                                  }
                                >
                                  Update
                                </Button>
                              ) : (
                                <Button className="mt-2" color="blue btn-fs-16">
                                  Please wait...
                                </Button>
                              )}
                              <hr />
                            </div>
                          );
                        }
                      );
                    }
                  })}
              </>

              {/* </Slider> */}
              <div className="btn-group-margin mt-5">
                <Button
                  color="border btn-fs-16"
                  onClick={() =>
                    handleChangePage(parseFloat(activeTabPage) - 1)
                  }
                  disabled={parseFloat(activeTabPage) - 1 === 0 ? true : false}
                >
                  Back
                </Button>
                <Button
                  color="blue btn-fs-16"
                  onClick={() => {
                    handleAddNewQuestion(parseFloat(activeTabPage) - 1);
                  }}
                >
                  Add New Question Here
                </Button>
                {!props.loading2 ? (
                  <Button color="blue btn-fs-16" onClick={addNewPage}>
                    Add Next Page
                  </Button>
                ) : (
                  <Button color="blue btn-fs-16">Please wait...</Button>
                )}
                {getValue(props,'surveyTemplate','') && 
                <NavLink to='/templates'>
                  <Button
                    // className="mt-2"
                    color="blue btn-fs-16"
                  >
                    Save Template
                  </Button>
                  </NavLink>
                }

              </div>
              
            </Col>
          </Row>
          {/* Question Creation ennds */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RenderSurveyQuestion;
