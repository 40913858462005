import { Col, Row, Label, Card, CardBody, Input } from 'reactstrap';
import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import AdvancedOptions from './AdvancedOptions';

interface IAppProps {
  pageIndex: number;
  questionInfo: any;
  setQuestionsInfo: any;
  questionIndex: number;
  surveyId: string;
  handleDateQuestionType: (e: any, pageIndex: any, questionIndex: any) => void;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  surveyType: string;
}

const Date: React.FunctionComponent<IAppProps> = (props) => {
  const [formats, setFormat] = React.useState([
    // { label: '01/31/1970', value: '01/31/1970' },
    // { label: 'Jan 31, 1970', value: 'Jan 31, 1970' },
    // { label: '31/01/1970', value: '31/01/1970' },
    { label: '31 Jan 1970', value: '31 Jan 1970' },
  ]);
  // const d: Date = new Date(); // but the type can also be inferred from "new Date()" already

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="date-choice">
      {/* <h5>Advance Options:</h5>
      <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Format</h6>
        </Col>
        <Col md="3">
          <div className="form-group form-react-select">
            <Select options={formats} onChange={(e)=>props.handleDateQuestionType(e,props.pageIndex,props.questionIndex)} />
          </div>
        </Col>
      </Row> */}
      {/* <Row>
        <Col md="2" className="d-flex align-items-center">
          <h6>Range</h6>
        </Col>
        <Col md="3">
          <div className="form-group single-datepicker">
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              // minDate={}
              inline
            />
          </div>
        </Col>
      </Row> */}
      {/* <div className="mb-2">
        <Label className="checkbox-container d-inline-block">
          Include Time
          <input type="checkbox" />
          <span className="checkmark"></span>
        </Label>
      </div> */}
      <div>
        <h5>Advance Options:</h5>
        <AdvancedOptions
              surveyType={props.surveyType}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          questionIndex={props.questionIndex}
          pageIndex={props.pageIndex}
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChange}
          questionInfo={props.questionInfo}
        />
      </div>
    </div>
  );
};
export default Date;
