import * as React from 'react';
import { Label, Input, Row, Col } from 'reactstrap';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';

interface IMultipleChoiceGridProps {
  questions: object;
  // validation
  required: boolean;
  answer: any;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
  handleUserSurveyResponseDropdownGrid: (
    questionId: string,
    questionType: string,
    rowId: string,
    columnId: string
  ) => void;
  requiredErrors: any;
}
const MultipleChoiceGrid: React.FunctionComponent<IMultipleChoiceGridProps> = (
  props
) => {
  let selected = getValue(props, 'answer', []).filter((item) => {
    return item.questionId === getValue(props, 'questions.id', '');
  });
  return (
    <>
      <table className="table table-borderless">
        {getValue(props.questions, `row`, []).map((rowData, i) => {
          return (
            <>
              {i === 0 ? (
                <thead>
                  <tr>
                    <td></td>
                    {getValue(props.questions, `columns`, []).map((cols) => {
                      return (
                        <td className="text-center">
                          <p className="mb-0 text-capitalize">
                            {getValue(cols, `text`, '')}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                </thead>
              ) : (
                ''
              )}
              <tbody>
                <tr>
                  <td>
                    <p className="mb-0 text-capitalize">
                      {getValue(rowData, 'text', '')}
                    </p>
                  </td>
                  {getValue(props.questions, `columns`, []).map((items) => {
                    return (
                      <td className="text-center">
                        <div className="form-group mb-0">
                          <Label className="radio-container d-inline-block pl-4 ml-1">
                            <Input
                              type="checkbox"
                              placeholder="Enter here"
                              checked={
                                getValue(selected[0], 'response', []).filter(
                                  (ite) =>
                                    ite.columnId === items.id &&
                                    getValue(rowData, 'id', '') === ite.rowId
                                ).length !== 0
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                props.handleUserSurveyResponseDropdownGrid(
                                  getValue(selected[0], 'questionId', ''),
                                  getValue(props.questions, 'type', ''),
                                  rowData.id,
                                  items.id
                                );
                              }}
                            />
                            <span className="checkmark"></span>
                          </Label>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </tbody>
            </>
          );
        })}
      </table>
      <PreviewAdvancedOptions
        questions={props.questions}
        answer={props.answer}
        handleChangeTakeSurveyAdvancedOptionInput={
          props.handleChangeTakeSurveyAdvancedOptionInput
        }
      />
      <div className="form-error" style={{ color: 'red' }}>
        {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
          ? getValue(props.questions, 'mandatoryMsg', '')?getValue(props.questions, 'mandatoryMsg', ''):'This question is mandatory'
          : ''}
      </div>
    </>
  );
};

export default MultipleChoiceGrid;
