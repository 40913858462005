import { Button, Col, Row, Label, Card, CardBody, Input } from 'reactstrap';
import * as React from 'react';
import CloseSVG from '../svg/close-svg';
import RadioEmptySVG from '../svg/radio-empty-svg';
import AddSVG from '../svg/add-svg';
import AdvancedOptions from './AdvancedOptions';
import { getValue } from 'core/lodash';
import MinusSVG from 'components/svg/minus-svg';

interface IMultipleChoiceGridProps {
  pageIndex: number;
  questionIndex: number;
  handleChangeQuestionAdvancedOptions: (
    name: string,
    value: boolean,
    path: any,
    pageIndex: any,
    questionIndex: any
  ) => void;
  setQuestionsInfo: any;
  handleChange: (evt: any, pageIndex: number, questionIndex: number) => void;
  questionInfo: any;
  handleGridData: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleAddGridDataRow: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleAddGridDataColumn: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleGridDataColumn: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleGridDataColumnScore: (
    e: any,
    pageIndex: number,
    questionIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  handleDeleteGridRow: (
    pageIndex: number,
    questionIndex: number,
    rowIndex: number
  ) => void;
  handleDeleteGridColumn: (
    pageIndex: number,
    questionIndex: number,
    colIndex: number
  ) => void;
  surveyType: string;
}

const MultipleChoiceGrid: React.FunctionComponent<IMultipleChoiceGridProps> = (
  props
) => {
  return (
    <div className="multiple-grid-choice">
      <h4 className="d-inline-block mb-1">Answer</h4>
      <Row>
        <Col md="6">
          {getValue(
            props.questionInfo[props.pageIndex],
            `questions[${props.questionIndex}].row.length`,
            0
          ) !== 0
            ? getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].row`,
                []
              ).map((rowData, rowIndex) => {
                return (
                  <>
                    <Card className="icon-text-more-close add-close-both mb-3 pl-0" key={rowIndex}>
                      <CardBody className="pr-0">
                        <Input
                          type="text"
                          placeholder="Enter row text..."
                          value={getValue(rowData, `text`, '')?getValue(rowData, `text`, ''):""}
                          onChange={(e) => {
                            props.handleGridData(
                              e,
                              props.pageIndex,
                              props.questionIndex,
                              rowIndex,
                              0
                            );
                          }}
                        />
                        {rowIndex === 0 ? (
                          ''
                        ) : (
                          <span
                            className="close-icon"
                            onClick={() =>
                              props.handleDeleteGridRow(
                                props.pageIndex,
                                props.questionIndex,
                                rowIndex
                              )
                            }
                          >
                            <CloseSVG />
                          </span>
                        )}
                        <span
                          className="right-icon"
                          onClick={() => {
                            props.handleAddGridDataRow(
                              props.pageIndex,
                              props.questionIndex,
                              rowIndex,
                              0
                            );
                          }}
                        >
                          <AddSVG />
                        </span>
                      </CardBody>
                    </Card>
                  </>
                );
              })
            : 'No row data found'}
        </Col>
        <Col md="6">
          {getValue(
            props.questionInfo[props.pageIndex],
            `questions[${props.questionIndex}].columns.length`,
            0
          ) !== 0
            ? getValue(
                props.questionInfo[props.pageIndex],
                `questions[${props.questionIndex}].columns`,
                []
              ).map((columnData, colIndex) => {
                return (
                  <>
                    <Card className="icon-text-more-close mb-3">
                      <CardBody>
                        <span className="left-icon">
                          <RadioEmptySVG />
                        </span>

                        <Input
                          type="text"
                          name="text"
                          placeholder="Enter Column text..."
                          value={getValue(columnData, `text`, '')?getValue(columnData, `text`, ''):""}
                          onChange={(e) => {
                            props.handleGridDataColumn(
                              e,
                              props.pageIndex,
                              props.questionIndex,
                              colIndex,
                              0
                            );
                          }}
                        />
                        {colIndex === 0 ? (
                          ''
                        ) : (
                          <span
                            className="close-icon"
                            onClick={() =>
                              props.handleDeleteGridColumn(
                                props.pageIndex,
                                props.questionIndex,
                                colIndex
                              )
                            }
                          >
                            <CloseSVG />
                          </span>
                        )}
                        <span
                          className="right-icon"
                          onClick={() =>
                            props.handleAddGridDataColumn(
                              props.pageIndex,
                              props.questionIndex,
                              colIndex,
                              1
                            )
                          }
                        >
                          <AddSVG />
                        </span>
                      </CardBody>
                    </Card>
                  </>
                );
              })
            : 'No Column data found'}
        </Col>
      </Row>
      <>
        {getValue(
          props,
          `questionInfo[${props.pageIndex}].questions[${props.questionIndex}].scoreEnabled`,
          ''
        ) ? (
          <table className="table table-borderless">
            {getValue(
              props.questionInfo[props.pageIndex].questions[
                props.questionIndex
              ],
              `row`,
              []
            ).map((rowData, i) => {
              return (
                <>
                  {i === 0 ? (
                    <thead>
                      <tr>
                        <td></td>
                        {getValue(
                          props.questionInfo[props.pageIndex].questions[
                            props.questionIndex
                          ],
                          `columns`,
                          []
                        ).map((cols) => {
                          return (
                            <td className="text-center">
                              <p className="mb-0 text-capitalize">
                                {getValue(cols, `text`, '')}
                              </p>
                            </td>
                          );
                        })}
                      </tr>
                    </thead>
                  ) : (
                    ''
                  )}
                  <tbody>
                    <tr>
                      <td>
                        <p className="mt-3 mb-0 text-capitalize">
                          {getValue(rowData, 'text', '')}
                        </p>
                      </td>
                      {getValue(
                        props.questionInfo[props.pageIndex].questions[
                          props.questionIndex
                        ],
                        `columns`,
                        []
                      ).map((_items, colIndex) => {
                        return (
                          <td className="text-center">
                            <div className="form-group mb-0">
                              <Label className="d-inline-block">
                                <Input
                                  type="number"
                                  name="score"
                                  placeholder="Enter here"
                                  value={getValue(
                                    rowData,
                                    `score[${colIndex}]`,
                                    0
                                  )}
                                  onChange={(e) => {
                                    props.handleGridDataColumnScore(
                                      e,
                                      props.pageIndex,
                                      props.questionIndex,
                                      colIndex,
                                      i
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </Label>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </>
              );
            })}
          </table>
        ) : (
          ''
        )}
      </>
      <div>
        <h5>Advance Options:</h5>
        <AdvancedOptions
          surveyType={props.surveyType}
          handleChangeQuestionAdvancedOptions={
            props.handleChangeQuestionAdvancedOptions
          }
          questionIndex={props.questionIndex}
          pageIndex={props.pageIndex}
          setQuestionsInfo={props.setQuestionsInfo}
          handleChange={props.handleChange}
          questionInfo={props.questionInfo}
        />
      </div>
    </div>
  );
};

export default MultipleChoiceGrid;
