import axios from 'axios';
import { getValue } from 'core/lodash';
import { toast } from 'react-toastify';
import { config } from '../../config';
import { get, patch, post, Delete, put, getPayload } from './helpers/http-handler';

interface surveyResponse {
  survey: object;
}

// *********** Template *********** //


export const getSurveyTemplate = (
  skip: number,
  limit: number,
  text: string
) => get(
  `${config.API_URL}survey-builder/templates?skip=${skip}&take=${limit}&text=${text}`
)

export const getSpecificSurveyTemplate = (
  templateId: string
) => get(
  `${config.API_URL}survey-builder/templates/${templateId}`
)

export const createSurveyTemplate = (
  payload
) => post(
  `${config.API_URL}survey-builder/templates`,
  payload
)

export const updateSurveyTemplate = (
  templateId,
  payload
) => put(
  `${config.API_URL}survey-builder/templates/${templateId}`,
  payload
)

export const deleteSurveyTemplate = (
  templateId
) => Delete(
  `${config.API_URL}survey-builder/templates/${templateId}`
)
// *********** Email Template / survey creation page *********** //

export const getEmailTemplate = (
  surveyId: string
) => get(
  `${config.API_URL}survey-builder/email-template/survey/${surveyId}`
)
export const getGlobalEmailTemplate = (
) => get(
  `${config.API_URL}survey-builder/email-template`
)
export const getSpecificEmailTemplate = (
  id
) => get(
  `${config.API_URL}survey-builder/email-template/${id}`
)

export const createEmailTemplate = (
  payload
) => post(
  `${config.API_URL}survey-builder/email-template`,
  payload)

export const updateEmailTemplate = (
  id,
  payload
) => post(
  `${config.API_URL}survey-builder/email-template/${id}`,
  payload
)

export const deleteEmailTemplate = (
  id
) => put(
  `${config.API_URL}survey-builder/email-template/${id}`
)



export const getSurveyEmailTemplate = (
) => get(
  `${config.API_URL}survey-builder/survey/email-template`,
  )

export const getSurvey360EmailTemplate = (
) => get(
  `${config.API_URL}survey-builder/survey360/email-template`,
  )

export const getAssesmentEmailTemplate = (
) => get(
  `${config.API_URL}survey-builder/assessment/email-template`
  )

  export const getAssessmentLearningStatus = (
    surveyId:string,
    payload:any
    ) => put(
      `${config.API_URL}survey-builder/surveys/${surveyId}/assessment-type`,payload
      )












