import { FunctionComponent, useState } from 'react';
import {
  Container,
  NavLink as NavLink1,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import classnames from 'classnames';
import RadioEmptySVG from '../../components/svg/radio-empty-svg';
import UploadSVG from '../../components/svg/upload-svg';
import CloseSVG from '../../components/svg/close-svg';
import RadioSVG from '../../components/svg/radio-svg';
import AddSVG from '../../components/svg/add-svg';
import { ReactMultiEmail } from 'react-multi-email';
import { getValue } from '../../core/lodash';

interface Props {
  handlePickAudiance(): void;
  handleChangeEmail: (p: object, emailIndex: number, mainIndex: number) => void;
  handleEmailTemplateEmail: (
    p: object,
    index: number,
    mainIndex: number
  ) => void;
  emails: string[];
  surveyDetails: any;
  participant: any;
  setParticipant: any;
  emailTemplateOnchange(e: any, index: number): void;
  emailTemplateOnchangeNumber(e: any, index: number): void;
  add360EmailTemplate(): void;
  addParticipants(): void;
  removeParticipants: (index: number) => void;
  standardSurveyPermission: any;
  standardSurveyPermission1: any;
  participantsStandard: any;
  organizationLeftHeaders: any;
  organizationValues: any;
  addOrganisation: () => void;
  organizationParticipants: any;
  changeOrganizationParticipants: (
    headerIndex: number,
    sideHeaderIndex: number,
    id: string,
    value: string
  ) => void;
  pickAudianceLoading: boolean;
  orgLoading: boolean;
  loading360: boolean;
  import360Emails: (e: any) => void;
  upload360Emails: () => void;
  handleToggleUpload: () => void;
  toggleUpload: boolean;
  surveyQuestionType: string;
  simpleValidator: any;
  handleModal: () => void;
  handleEnableAddParticipant: (status: boolean) => void;
  enableAddParticipant: boolean;
  handleModalWithParams: (participantId: string) => void;
  surveyId: string;
}

const PickAudienceComponent: FunctionComponent<Props> = (props) => {
  const [activeTab2, setActiveTab2] = useState('2');
  const toggle2 = (tab) => {
    if (activeTab2 !== tab) setActiveTab2(tab);
  };
  //console.log(props.loading360)
  return (
    <>
      <Container fluid={true} className=" border-bottom">
        <h6 className="text-center my-3">
          Pick an audience for your survey{' '}
          <span style={{ color: 'blue' }}>
            {getValue(props.surveyDetails, 'name', '')}
          </span>
        </h6>
      </Container>
      <div className="choose-template-layout">
        <div className="choose-template-list">
          <Nav pills className="menu-ul">
            <li>
              <h6 className="mt-2">Type</h6>
            </li>

            {getValue(props, 'surveyQuestionType', '') === 'SURVEY' ||
              getValue(props, 'surveyQuestionType', '') !== 'SURVEY360' ? (
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab2 === '2' })}
                  onClick={() => {
                    toggle2('2');
                  }}
                >
                  {activeTab2 === '2' ? <RadioSVG /> : <RadioEmptySVG />}
                  Invite Participants
                </NavLink1>
              </NavItem>
            ) : (
              <>
                <NavItem>
                  <NavLink1
                    className={classnames({ active: activeTab2 === '2' })}
                    onClick={() => {
                      toggle2('2');
                    }}
                  >
                    {activeTab2 === '2' ? <RadioSVG /> : <RadioEmptySVG />}
                    Survey 360 Feedback
                  </NavLink1>
                </NavItem>
                <NavItem>
                  <NavLink1
                    className={classnames({ active: activeTab2 === '5' })}
                    onClick={() => {
                      toggle2('5');
                    }}
                  >
                    {activeTab2 === '5' ? <RadioSVG /> : <RadioEmptySVG />}
                    Invite Settings
                  </NavLink1>
                </NavItem>
              </>
            )}
            {getValue(props, 'surveyQuestionType', '') !== 'SURVEY360' && (
              <NavItem>
                <NavLink1
                  className={classnames({ active: activeTab2 === '3' })}
                  onClick={() => {
                    toggle2('3');
                  }}
                >
                  {activeTab2 === '3' ? <RadioSVG /> : <RadioEmptySVG />}Target
                  Audience
                </NavLink1>
              </NavItem>
            )}
          </Nav>
        </div>
        <div className="choose-template-height">
          <Col lg="11" className="text-right">
            <div className="btn-group-margin mt-3">
              {getValue(props.surveyDetails, 'status', '') !== 'published' &&
                getValue(props, 'surveyQuestionType', '') === 'SURVEY360' &&
                activeTab2 !== '5' && (
                  <>
                    <Button color="icon" className="icon-upload-drop mb-0 mr-2">
                      <div onClick={props.handleToggleUpload}>
                        <UploadSVG />
                      </div>
                      {props.toggleUpload && (
                        <>
                          <div className="upload-drop">
                            <input
                              type="file"
                              name="xlsFile"
                              id="xlsFile"
                              accept=".xls, .xlsx"
                              onChange={props.import360Emails}
                            />
                            <Button
                              color="nothing"
                              className="m-0"
                              onClick={props.handleToggleUpload}
                            >
                              <CloseSVG />
                            </Button>
                            <div className="text-right">
                              <Button
                                color="border"
                                className="px-2 m-0 text-blue py-0"
                                onClick={props.upload360Emails}
                              >
                                Upload
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Button>
                  </>
                )}
            </div>
          </Col>
          <TabContent activeTab={activeTab2}>
            <TabPane tabId="3">
              <div className="page-padding">
                <div className="mt-3 form-group">
                  <Col lg={{ size: 10, offset: 1 }}>
                    <Card>
                      <CardBody>
                        {getValue(props, `organizationLeftHeaders`, []).map(
                          (item, index) => {
                            return (
                              <>
                                <h4>{item}</h4>
                                {getValue(
                                  props,
                                  `organizationValues[${index}]`,
                                  []
                                ).map((item, index1) => {
                                  return (
                                    <Label className="checkbox-container mb-2">
                                      {item.name}
                                      <input
                                        type="checkbox"
                                        checked={
                                          getValue(
                                            props,
                                            `organizationParticipants`,
                                            ''
                                          )[
                                            getValue(
                                              props,
                                              `organizationLeftHeaders[${index}]`,
                                              0
                                            )
                                          ]
                                            ? getValue(
                                              props,
                                              `organizationParticipants`,
                                              ''
                                            )[
                                              getValue(
                                                props,
                                                `organizationLeftHeaders[${index}]`,
                                                ''
                                              )
                                            ].includes(item.id)
                                              ? true
                                              : false
                                            : false
                                        }
                                        onChange={() =>
                                          props.changeOrganizationParticipants(
                                            index,
                                            index1,
                                            item.id,
                                            getValue(
                                              props,
                                              `organizationLeftHeaders[${index}]`,
                                              ''
                                            )
                                          )
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </Label>
                                  );
                                })}
                                <hr />
                              </>
                            );
                          }
                        )}
                        {getValue(props.surveyDetails, 'status', '') !==
                          'published' && (
                            <div className="text-right mt-4">
                              {!props.orgLoading ? (
                                <Button
                                  color="blue"
                                  onClick={props.addOrganisation}
                                  disabled={
                                    getValue(
                                      props,
                                      `surveyDetails.pages[${0}].questions.length`,
                                      0
                                    ) === 0
                                      ? true
                                      : false
                                  }
                                >
                                  Save
                                </Button>
                              ) : (
                                <Button color="blue">Please wait...</Button>
                              )}
                            </div>
                          )}
                      </CardBody>
                    </Card>
                  </Col>
                </div>
              </div>
            </TabPane>
            {getValue(props, 'surveyQuestionType', '') === 'SURVEY' ||
              getValue(props, 'surveyQuestionType', '') !== 'SURVEY360' ? (
              <TabPane tabId="2">
                <div className="page-padding">
                  <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                      <Card>
                        <CardBody>
                          <div className="left-img-name">
                            <img
                              src={'/images/icon2.PNG'}
                              alt=""
                              width="36"
                              height="36"
                            />
                            <h4 className="detail-heading">
                              Invite people to participate
                            </h4>
                            <p className="detail-para">
                              Please enter email addresses of the participants
                              you want to take this survey
                            </p>
                          </div>
                          <hr />
                          <h6>To:</h6>

                          <div className="form-group">
                            {getValue(
                              props,
                              'standardSurveyPermission1',
                              false
                            ) && (
                                <>
                                  <Label>Enter Internal Email Here</Label>
                                  <ReactMultiEmail
                                    emails={getValue(
                                      props,
                                      `participantsStandard[${0}].feedbackParticipants[${0}].emails`,
                                      ''
                                    )}
                                    placeholder="One or more email here"
                                    onChange={(e) =>
                                      props.handleChangeEmail(e, 0, 0)
                                    }
                                    getLabel={(
                                      email: string,
                                      index: number,
                                      removeEmail: (index: number) => void
                                    ) => {
                                      return (
                                        <div data-tag key={index}>
                                          {email}
                                          <span
                                            data-tag-handle
                                            onClick={() => removeEmail(index)}
                                          >
                                            ×
                                          </span>
                                        </div>
                                      );
                                    }}
                                  />
                                </>
                              )}
                            {getValue(
                              props,
                              'standardSurveyPermission',
                              false
                            ) && (
                                <div className="form-group">
                                  <br></br>
                                  <Label>Enter External Email Here</Label>
                                  <ReactMultiEmail
                                    emails={getValue(
                                      props,
                                      `participantsStandard[${0}].feedbackParticipants[${1}].emails`,
                                      ''
                                    )}
                                    onChange={(e) =>
                                      props.handleChangeEmail(e, 1, 0)
                                    }
                                    placeholder="One or more email here"
                                    getLabel={(
                                      email: string,
                                      index: number,
                                      removeEmail: (index: number) => void
                                    ) => {
                                      return (
                                        <div data-tag key={index}>
                                          {email}
                                          <span
                                            data-tag-handle
                                            onClick={() => removeEmail(index)}
                                          >
                                            ×
                                          </span>
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              )}
                          </div>
                        </CardBody>
                      </Card>
                      {getValue(props.surveyDetails, 'status', '') !==
                        'published' && (
                          <div className="text-right mt-4">
                            {!props.pickAudianceLoading ? (
                              <Button
                                color="blue"
                                onClick={props.handlePickAudiance}
                                disabled={
                                  getValue(
                                    props,
                                    `surveyDetails.pages[${0}].questions.length`,
                                    0
                                  ) === 0
                                    ? true
                                    : false
                                }
                              >
                                Save
                              </Button>
                            ) : (
                              <Button color="blue">Please wait...</Button>
                            )}
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </TabPane>
            ) : (
              <TabPane tabId="2">
                <div className="page-padding">
                  <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                      <Card>
                        <CardBody>
                          <div className="left-img-name">
                            <img
                              src={'/images/icon.svg'}
                              alt=""
                              width="36"
                              height="36"
                            />
                            <h4 className="detail-heading">360 Feedback</h4>
                            <p className="detail-para">
                              Please enter email addresses of the participants
                              you want to take this survey
                            </p>
                          </div>
                          <hr />
                          {/* repeat row */}
                          <h6>360 Survey for</h6>
                          {getValue(props, `participant`, []).map(
                            (_item, index) => {
                              return (
                                <div key={index}>
                                  <hr />
                                  <Row>
                                    {/* <Col md="6">
                                      <div className="form-group">
                                        <Label>Name</Label>
                                        <Input
                                          type="text"
                                          placeholder="Enter Name"
                                          value={
                                            getValue(
                                              props,
                                              `participant[${index}].name`,
                                              ''
                                            )
                                              ? getValue(
                                                  props,
                                                  `participant[${index}].name`,
                                                  ''
                                                )
                                              : ''
                                          }
                                          onChange={(e) =>
                                            props.emailTemplateOnchange(
                                              e,
                                              index
                                            )
                                          }
                                          name="name"
                                        />
                                      </div>
                                    </Col> */}
                                    <Col md="6">
                                      <div className="form-group">
                                        <Label>
                                          Self Participant Email Address:
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {getValue(
                                            props.surveyDetails,
                                            'status',
                                            ''
                                          ) === 'published' && (
                                              <span
                                                style={{
                                                  color: 'red',
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                  props.handleModalWithParams(
                                                    _item.id
                                                  )
                                                }
                                              >
                                                Add Participants
                                              </span>
                                            )}
                                        </Label>
                                        <Input
                                          type="text"
                                          placeholder="Enter email address"
                                          value={
                                            getValue(
                                              props,
                                              `participant[${index}].email`,
                                              ''
                                            )
                                              ? getValue(
                                                props,
                                                `participant[${index}].email`,
                                                ''
                                              )
                                              : ''
                                          }
                                          onChange={(e) =>
                                            props.emailTemplateOnchange(
                                              e,
                                              index
                                            )
                                          }
                                          name="email"
                                        />
                                        <div className="form-error">
                                          {props.simpleValidator.current.message(
                                            'Participant Email Address',
                                            getValue(
                                              props,
                                              `participant[${index}].email`,
                                              ''
                                            ),
                                            'email|required'
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <h6>Feedback Participants</h6>
                                  <div className="text-right">
                                    <Button
                                      color="nothing"
                                      className="text-blue"
                                      onClick={() =>
                                        props.removeParticipants(index)
                                      }
                                      disabled={index === 0 ? true : false}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                  <Row>
                                    <Col md="6">
                                      <div className="form-group">
                                        <Row>
                                          <Col md="8">
                                            <Label className="pb-1">
                                              Manager Email Addresses
                                            </Label>
                                          </Col>
                                          <Col md="4" className="text-right">
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                color="nothing"
                                                className="text-blue mt-1"
                                                caret
                                              >
                                                Set Limit{' - '}{getValue(
                                                  props,
                                                  `participant[${index}].managerLimit`,
                                                  ''
                                                )}
                                              </DropdownToggle>
                                              <DropdownMenu right>
                                                <Input
                                                  type="number"
                                                  placeholder="100"
                                                  name="managerLimit"
                                                  value={
                                                    getValue(
                                                      props,
                                                      `participant[${index}].managerLimit`,
                                                      ''
                                                    )
                                                      ? getValue(
                                                        props,
                                                        `participant[${index}].managerLimit`,
                                                        ''
                                                      )
                                                      : ''
                                                  }
                                                  onChange={(e) =>
                                                    props.emailTemplateOnchangeNumber(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </Col>
                                        </Row>
                                        <ReactMultiEmail
                                          emails={getValue(
                                            props,
                                            `participant[${index}].feedbackParticipants[${0}].emails`,
                                            ''
                                          )}
                                          onChange={(e) =>
                                            props.handleEmailTemplateEmail(
                                              e,
                                              0,
                                              index
                                            )
                                          }
                                          placeholder="One or more email here"
                                          getLabel={(
                                            email: string,
                                            index: number,
                                            removeEmail: (index: number) => void
                                          ) => {
                                            return (
                                              <div data-tag key={index}>
                                                {email}
                                                <span
                                                  data-tag-handle
                                                  onClick={() =>
                                                    removeEmail(index)
                                                  }
                                                >
                                                  ×
                                                </span>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>

                                    <Col md="6">
                                      <div className="form-group">
                                        <Row>
                                          <Col md="8">
                                            <Label className="pb-1">
                                              Peers Email Addresses</Label>
                                          </Col>
                                          <Col md="4" className="text-right">
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                color="nothing"
                                                className="text-blue mt-1"
                                                caret
                                              >
                                                Set Limit{' - '}{getValue(
                                                  props,
                                                  `participant[${index}].peerLimit`,
                                                  ''
                                                )}
                                              </DropdownToggle>
                                              <DropdownMenu right>
                                                <Input
                                                  type="number"
                                                  placeholder="100"
                                                  name="peerLimit"
                                                  value={
                                                    getValue(
                                                      props,
                                                      `participant[${index}].peerLimit`,
                                                      ''
                                                    )
                                                      ? getValue(
                                                        props,
                                                        `participant[${index}].peerLimit`,
                                                        ''
                                                      )
                                                      : ''
                                                  }
                                                  onChange={(e) =>
                                                    props.emailTemplateOnchangeNumber(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </Col>
                                        </Row>

                                        <ReactMultiEmail
                                          emails={getValue(
                                            props,
                                            `participant[${index}].feedbackParticipants[${1}].emails`,
                                            ''
                                          )}
                                          onChange={(e) =>
                                            props.handleEmailTemplateEmail(
                                              e,
                                              1,
                                              index
                                            )
                                          }
                                          placeholder="One or more email here"
                                          getLabel={(
                                            email: string,
                                            index: number,
                                            removeEmail: (index: number) => void
                                          ) => {
                                            return (
                                              <div data-tag key={index}>
                                                {email}
                                                <span
                                                  data-tag-handle
                                                  onClick={() =>
                                                    removeEmail(index)
                                                  }
                                                >
                                                  ×
                                                </span>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>

                                    <Col md="6">
                                      <div className="form-group">
                                        <Row>
                                          <Col md="8">
                                            <Label className="pb-1">
                                              Subordinate Email Addresses</Label>
                                          </Col>
                                          <Col md="4" className="text-right">
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                color="nothing"
                                                className="text-blue mt-1"
                                                caret
                                              >
                                                Set Limit{' - '}{getValue(
                                                  props,
                                                  `participant[${index}].subordinateLimit`,
                                                  ''
                                                )}
                                              </DropdownToggle>
                                              <DropdownMenu right>
                                                <Input
                                                  type="number"
                                                  placeholder="100"
                                                  name="subordinateLimit"
                                                  value={
                                                    getValue(
                                                      props,
                                                      `participant[${index}].subordinateLimit`,
                                                      ''
                                                    )
                                                      ? getValue(
                                                        props,
                                                        `participant[${index}].subordinateLimit`,
                                                        ''
                                                      )
                                                      : ''
                                                  }
                                                  onChange={(e) =>
                                                    props.emailTemplateOnchangeNumber(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </Col>
                                        </Row>

                                        <ReactMultiEmail
                                          emails={getValue(
                                            props,
                                            `participant[${index}].feedbackParticipants[${2}].emails`,
                                            ''
                                          )}
                                          onChange={(e) =>
                                            props.handleEmailTemplateEmail(
                                              e,
                                              2,
                                              index
                                            )
                                          }
                                          placeholder="One or more email here"
                                          getLabel={(
                                            email: string,
                                            index: number,
                                            removeEmail: (index: number) => void
                                          ) => {
                                            return (
                                              <div data-tag key={index}>
                                                {email}
                                                <span
                                                  data-tag-handle
                                                  onClick={() =>
                                                    removeEmail(index)
                                                  }
                                                >
                                                  ×
                                                </span>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>

                                    <Col md="6">
                                      <div className="form-group">
                                        <Row>
                                          <Col md="8">
                                            <Label className="pb-1">
                                              Client Email Addresses</Label>
                                          </Col>
                                          <Col md="4" className="text-right">
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                color="nothing"
                                                className="text-blue mt-1"
                                                caret
                                              >
                                                Set Limit{' - '}{getValue(
                                                  props,
                                                  `participant[${index}].publicLimit`,
                                                  ''
                                                )}
                                              </DropdownToggle>
                                              <DropdownMenu right>
                                                <Input
                                                  type="number"
                                                  placeholder="100"
                                                  name="publicLimit"
                                                  value={
                                                    getValue(
                                                      props,
                                                      `participant[${index}].publicLimit`,
                                                      ''
                                                    )
                                                      ? getValue(
                                                        props,
                                                        `participant[${index}].publicLimit`,
                                                        ''
                                                      )
                                                      : ''
                                                  }
                                                  onChange={(e) =>
                                                    props.emailTemplateOnchangeNumber(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </Col>
                                        </Row>
                                        <ReactMultiEmail
                                          emails={getValue(
                                            props,
                                            `participant[${index}].feedbackParticipants[${3}].emails`,
                                            ''
                                          )}
                                          onChange={(e) =>
                                            props.handleEmailTemplateEmail(
                                              e,
                                              3,
                                              index
                                            )
                                          }
                                          placeholder="One or more email here"
                                          getLabel={(
                                            email: string,
                                            index: number,
                                            removeEmail: (index: number) => void
                                          ) => {
                                            return (
                                              <div data-tag key={index}>
                                                {email}
                                                <span
                                                  data-tag-handle
                                                  onClick={() =>
                                                    removeEmail(index)
                                                  }
                                                >
                                                  ×
                                                </span>
                                              </div>
                                            );
                                          }}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            }
                          )}
                          <hr />
                          {getValue(props.surveyDetails, 'status', '') !==
                            'published' && (
                              <Button
                                color="nothing"
                                className="blue-text-svg"
                                onClick={getValue(props, `addParticipants`, '')}
                                disabled={
                                  getValue(
                                    props,
                                    `surveyDetails.pages[${0}].questions.length`,
                                    0
                                  ) === 0
                                    ? true
                                    : false
                                }
                              >
                                <AddSVG /> Add more
                              </Button>
                            )}
                        </CardBody>
                      </Card>
                      {getValue(props.surveyDetails, 'status', '') !==
                        'published' && (
                          <div className="text-right mt-4">
                            {!props.loading360 ? (
                              <Button
                                color="blue"
                                onClick={props.add360EmailTemplate}
                                disabled={
                                  getValue(
                                    props,
                                    `surveyDetails.pages[${0}].questions.length`,
                                    0
                                  ) === 0
                                    ? true
                                    : false
                                }
                              >
                                Save
                              </Button>
                            ) : (
                              <Button color="blue">Please wait...</Button>
                            )}
                          </div>
                        )}
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}{' '}
            <TabPane tabId="5">
              <div className="page-padding">
                <Row>
                  <Col lg={{ size: 10, offset: 1 }}>
                    <Card>
                      <CardBody>
                        <Row>
                          <div className="mt-3 form-group">
                            <Label className="checkbox-container">
                              Enable self employee to add their own participants
                              <input
                                type="checkbox"
                                checked={getValue(
                                  props,
                                  'enableAddParticipant',
                                  false
                                )}
                                onChange={() =>
                                  props.handleEnableAddParticipant(
                                    !getValue(props, 'enableAddParticipant', '')
                                  )
                                }
                              />
                              <span className="checkmark"></span>
                            </Label>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};
export default PickAudienceComponent;
