import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import BackSVG from '../../components/svg/back-svg';
import ArrowLeftSVG from '../../components/svg/arrow-left-svg';
import ArrowRightSVG from '../../components/svg/arrow-right-svg';
import { getValue } from '../../core/lodash';
import {
  getSurveyResponse,
  getSurveyResponsePages,
} from '../../core/services/surveyService';
import RenderAnswerPreview from '../../components/common/RenderAnswerPreview';

interface ICompletedSurveyPreviewProps { }

const CompletedSurveyPreview: React.FunctionComponent<ICompletedSurveyPreviewProps> = (
  props
) => {
  const [activeTab, setActiveTab] = useState(1);
  const [isLeftMenu, manageMenu] = useState(false);
  const [surveyId] = useState(getValue(props, 'match.params.surveyId', ''));
  const [userId] = useState(getValue(props, 'match.params.userId', ''));
  const [questionsInfo, setQuestionsInfo] = useState([]);
  const [infoData, setInfoData] = useState([]);

  const enableLeftMenu = () => {
    manageMenu(!isLeftMenu);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getSurveyQuestion();
  }, []);
  const getSurveyQuestion = async () => {
    let resp = await getSurveyResponse(surveyId, userId);
    if (resp) {
      //console.log(resp)
      setInfoData(getValue(resp, 'data', ''));
      setQuestionsInfo(getValue(resp, 'data.page', ''));
    }
  };
  var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: true,
    draggable: false,
  };
  const handleNavigate = (index) => {
    handleChangePage(index);
  };
  const handleChangePage = (index) => {
    togglePage(index);
    // slickGoTo(e.target.value);
  };

  const togglePage = (index) => {
    let pageID = getValue(infoData, `surveyPageIds[${index - 1}]`, 0);
    handleSurveySubmit(pageID);
  };

  const handleSurveySubmit = async (pageId) => {
    let resp = await getSurveyResponsePages(surveyId, userId, pageId);
    if (resp) {
      setInfoData(getValue(resp, 'data', ''));
      setQuestionsInfo(getValue(resp, 'data.page', ''));
    }
  };
  return (
    <>
      <div className="page-layout page-layout-full">
        <div className="create-survey-tabs border-bottom-0">
          <div className="basic-tabs text-left pt-3 pb-5">
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Type:</b>&nbsp;&nbsp;
                  {getValue(infoData, 'type', '')
                    ? getValue(infoData, 'type', '')
                      .split(' ')
                      .map(
                        (w) => w[0].toUpperCase() + w.substr(1).toLowerCase()
                      )
                      .join(' ')
                    : ''}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Pages:</b>&nbsp;&nbsp;
                  {getValue(infoData, 'totalPages', '')}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Questions:</b>&nbsp;&nbsp;
                  {getValue(infoData, 'totalQuestions', '')}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Answered:</b>&nbsp;&nbsp;
                  {getValue(infoData, 'answered', '')}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Skipped:</b>&nbsp;&nbsp;
                  {getValue(infoData, 'skipped', '')}
                </p>
                <p className="mb-1">
                  <b className="text-black fw-sbold">Status:</b>&nbsp;&nbsp;
                  {getValue(infoData, 'status', '')}
                </p>
                {/* <p className="mb-1 text-green-light">
                  <b className="fw-sbold">Score:</b>&nbsp;&nbsp; 120/400
                </p> */}
                <hr className="mt-3" />
              </Col>
              <Col lg={{ size: 4, offset: 1 }}>
                <h4 className="mb-0 mt-3">{getValue(infoData, 'name', '')}</h4>
                <hr className="my-2" />
                <h6>
                  {getValue(infoData, 'totalQuestions', '')} Questions:{' '}
                  <span className="dot-grey mx-2"></span>{' '}
                  {getValue(infoData, 'answered', '')} Answers
                </h6>
              </Col>
            </Row>
          </div>
          <div className="back-btn">
            {/* <NavLink to={`/summary/${surveyId}`}> */}
            <NavLink to={`/${getValue(props, `match.params.routeUrl`, '')}`}>
              <Button color="nothing">
                <BackSVG />
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="page-height">
          <div className="add-survey-line">
            <div className="page-padding py-0">
              <div className="line-relative">
                <ul className="line-relative-slick">
                  {Array.from(
                    { length: getValue(infoData, 'totalPages', []) },
                    (v, k) => k
                  ).map((k, i) => (
                    <li key={i}>
                      <Button
                        className={
                          i === getValue(infoData, 'pageNo', 0) ? 'active' : ''
                        }
                        color="nothing"
                        onClick={() => handleChangePage(i + 1)}
                      >
                        {i + 1}
                      </Button>
                    </li>
                  ))}
                </ul>
                <Button
                  color="nothing"
                  className="left-prev"
                  disabled={
                    !getValue(infoData, 'previousPageId', '') ? true : false
                  }
                  onClick={() => {
                    handleSurveySubmit(
                      getValue(infoData, 'previousPageId', '')
                    );
                  }}
                >
                  <img src="/images/slick-fonts/left.svg" alt="" />
                </Button>
                {getValue(infoData, 'nextPageId', '') ? (
                  <Button
                    color="nothing"
                    className="right-next"
                    disabled={
                      activeTab - 1 === questionsInfo.length - 1 ? true : false
                    }
                    onClick={() => {
                      handleSurveySubmit(getValue(infoData, 'nextPageId', ''));
                    }}
                  >
                    <img src="/images/slick-fonts/right.svg" alt="" />
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="page-padding pt-5">
            <Row>
              <Col lg={{ size: 10, offset: 1 }}>
                <h5 className="mb-1 text-green-light text-center">
                  {getValue(questionsInfo, 'title', '') === 'Untitled'
                    ? ''
                    : getValue(questionsInfo, 'title', '')}
                </h5>
                <p className="text-center">
                  {getValue(questionsInfo, 'description', '') === 'Untitled'
                    ? ''
                    : getValue(questionsInfo, 'description', '')}
                </p>
                {getValue(questionsInfo, 'questions.length', 0) !== 0
                  ? getValue(questionsInfo, 'questions', []).map(
                    (qus, index) => {
                      //console.log(qus)
                      return (
                        <>
                          <h6 style={{ color: "gray" }}>
                          Question{' '}:
                            {/* {index + 1} */}
                            
                            {qus.mandatoryEnabled ? <span className="text-red">*</span> : ''}
                          </h6>
                          { (getValue(qus, 'totalScore', '') !== 0)&&
                          <div className="text-right">
                            {getValue(qus, 'totalScore', '')?
                          <span>Score : ({getValue(qus, 'getScore', '')}/{getValue(qus, 'totalScore', '')})</span>:""}
                          </div>}
                          <h3 className="mb-1">
                            {/* Which color of visible light travels the fastest in the crown
                           glass? */}
                            {/* <p
                                className="abtcnt"
                                // style={{ whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{
                                  __html: getValue(qus, 'text', ''),
                                }}
                              /> */}
                            {getValue(qus, 'text', '')}{' '}
                          </h3>
                         

                          <div className="ml-3">
                            <RenderAnswerPreview
                              param={qus.type}
                              options={getValue(qus, 'options', [])}
                              questions={qus}
                            />
                          </div>
                        </>
                      );
                    }
                  )
                  : 'Please Add Questions'}

                <div className="my-4 btn-group-margin">
                  <Button
                    color="border"
                    disabled={
                      !getValue(infoData, 'previousPageId', '') ? true : false
                    }
                    className="pl-1 py-1"
                    onClick={() => {
                      handleSurveySubmit(
                        getValue(infoData, 'previousPageId', '')
                      );
                    }}
                  >
                    <ArrowLeftSVG /> <span className="pl-2">Previous</span>
                  </Button>
                  {getValue(infoData, 'nextPageId', '') ? (
                    <Button
                      color="border"
                      className="pl-1 py-1"
                      disabled={activeTab === infoData.length ? true : false}
                      onClick={() => {
                        handleSurveySubmit(
                          getValue(infoData, 'nextPageId', '')
                        );
                      }}
                    >
                      <ArrowRightSVG /> <span className="pl-2">Next</span>
                    </Button>
                  ) : (
                    ''
                    // <Button
                    //   color="primary"
                    //   className="pl-1 py-1"
                    //   // onClick={()=>{handleSurveySubmit(getValue(questionsInfo, 'nextPageId', ''))}}
                    // >
                    //   <span className="pl-2">Submit</span>
                    // </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedSurveyPreview;
