import * as React from 'react';
import { Label, Input, Row, Col, Button } from 'reactstrap';
import SmileySVG from '../../components/svg/smiley-svg';
import { getValue } from '../../core/lodash';
import PreviewAdvancedOptions from './previewAdvancedOptions';
import SimleySVG1 from '../../components/svg/smiley/smiley1-svg';
import SimleySVG2 from '../../components/svg/smiley/smiley2-svg';
import SimleySVG3 from '../../components/svg/smiley/smiley3-svg';
import SimleySVG4 from '../../components/svg/smiley/smiley4-svg';
import SimleySVG5 from '../../components/svg/smiley/smiley5-svg';
import SimleySVG6 from '../../components/svg/smiley/smiley6-svg';
import SimleySVG7 from '../../components/svg/smiley/smiley7-svg';
import SimleySVG8 from '../../components/svg/smiley/smiley8-svg';
import SimleySVG9 from '../../components/svg/smiley/smiley9-svg';
import SimleySVG10 from '../../components/svg/smiley/smiley10-svg';
import data from './int.json';

interface IRatingScaleProps {
  questions: any;
  answer: any[];
  handleChangeTakeSurveyNumber: (e: any, questionId: string) => void;
  // validation
  required: boolean;
  handleChangeTakeSurveyAdvancedOptionInput: (
    e: any,
    questionId: string,
    param: string
  ) => void;
  requiredErrors: any;
}

const RatingScale: React.FunctionComponent<IRatingScaleProps> = (props) => {
  // number
  const [inputs] = React.useState(
    Array(
      parseFloat(getValue(props.questions, 'validations.scaleTo', ''))
    ).fill({ value: 'number' })
  );

  // smiley
  const [simley] = React.useState(
    Array(
      parseFloat(getValue(props.questions, 'validations.scaleTo', ''))
    ).fill({ value: 'number' })
  );

  let currentValue = getValue(props, 'answer', []).filter(
    (item) => item.questionId === props.questions.id
  );

  const [smileyLength, setSmileyLength] = React.useState([]);

  const handleEmoji = (id) => {
    switch (id) {
      case 1:
        return <SimleySVG1 />;
      case 2:
        return <SimleySVG2 />;
      case 3:
        return <SimleySVG3 />;
      case 4:
        return <SimleySVG4 />;
      case 5:
        return <SimleySVG5 />;
      case 6:
        return <SimleySVG6 />;
      case 7:
        return <SimleySVG7 />;
      case 8:
        return <SimleySVG8 />;
      case 9:
        return <SimleySVG10 />;
      case 10:
        return <SimleySVG9 />;
      default:
        return;
    }
  };

  React.useEffect(() => {
    var dynamicId = getValue(props.questions, 'validations.scaleTo', '') - 1;
    // var dynamicId = 5 - 1;
    var finalLength = 10;
    var midValue = dynamicId / 2;
    let options = [];
    for (let i = 0; i < dynamicId; i++) {
      if (i <= midValue) {
        if (options.length <= dynamicId) {
          options.push(data[i]);
        }
      }
    }
    let iteration = finalLength - options.length;
    for (let j = iteration; j <= finalLength; j++) {
      if (options.length <= dynamicId) {
        options.push(data[j]);
      }
      iteration = iteration - 1;
    }
    setSmileyLength(options);
  }, [getValue(props.questions, 'validations.scaleTo', '')]);

  return (
    <>
      {getValue(props.questions, 'validations.type', '') === 'number' ? (
        <>
          {inputs &&
            inputs.map((_item, i) => {
              return (
                <Button
                  color="nothing"
                  className={`circle-rating ${
                    i + 1 <= getValue(currentValue[0], 'response', 0)
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    props.handleChangeTakeSurveyNumber(
                      i + 1,
                      props.questions.id
                    );
                  }}
                >
                  <p>
                    {i === 0 ? (
                      <>
                        {getValue(
                          props.questions,
                          'validations.startLabel',
                          ''
                        )}
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}
                    {i ===
                      parseFloat(
                        getValue(props.questions, 'validations.scaleTo', '')
                      ) -
                        1 && i !== 0 ? (
                      <>
                        {getValue(props.questions, 'validations.endLabel', '')}
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  <span>{i + 1}</span>
                </Button>
              );
            })}
          <div className="form-error" style={{ color: 'red' }}>
            {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
              ? props.questions.mandatoryMsg
              : ''}
          </div>
          {/* <Button color="nothing" className="circle-rating">
            <p>Not Applicable</p>
            <span>&nbsp;</span>
          </Button> */}
        </>
      ) : (
        <>
          {simley &&
            simley.map((_item, i) => {
              return (
                <Button
                  color="nothing"
                  className={`smile-rating ${
                    i + 1 <= getValue(currentValue[0], 'response', 0)
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    props.handleChangeTakeSurveyNumber(
                      i + 1,
                      props.questions.id
                    );
                  }}
                >
                  <p>
                    {i === 0 ? (
                      <>
                        {getValue(
                          props.questions,
                          'validations.startLabel',
                          ''
                        )}
                      </>
                    ) : (
                      <>&nbsp;</>
                    )}
                    {i ===
                      parseFloat(
                        getValue(props.questions, 'validations.scaleTo', '')
                      ) -
                        1 && i !== 0 ? (
                      <>
                        {getValue(props.questions, 'validations.endLabel', '')}
                      </>
                    ) : (
                      ''
                    )}
                  </p>
                  {handleEmoji(smileyLength[i])}
                </Button>
              );
            })}
          <PreviewAdvancedOptions
            questions={props.questions}
            answer={props.answer}
            handleChangeTakeSurveyAdvancedOptionInput={
              props.handleChangeTakeSurveyAdvancedOptionInput
            }
          />
          <div className="form-error" style={{ color: 'red' }}>
            {props.requiredErrors.includes(getValue(props, 'questions.id', ''))
              ? props.questions.mandatoryMsg
              : ''}
          </div>
        </>
      )}
    </>
  );
};

export default RatingScale;
